import React, { useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Paper, Button } from '@material-ui/core'
import { Field } from '@/components/molecules/field'
import { SectionTitle } from '@/components/atoms/section-title'
import { BasicTable, Column } from '@/components/organisms/table'
import { PageContent } from '@/components/organisms/page-content'
import useSWR from 'swr'
import { useHistory } from 'react-router-dom'

interface Row {
  id: number
  name: string
  scoutCount: string
  approvedScoutCount: string
  entryInScoutCount: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {},
    list: {
      padding: 0,
    },
    filter: {
      padding: theme.spacing(4),
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    listItem: {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    listItemIcon: {
      width: 48,
    },
    iconButton: {
      width: 48,
      height: 48,
    },
    detailsColumn: {
      flex: '0 1 350px',
    },
    detailsForm: {
      display: 'grid',
      gap: theme.spacing(2) + 'px',
    },
    formControl: {
      width: 200,
    },
  }),
)

const columns: Column<Row>[] = [
  { id: 'name', label: 'クライアント名', numeric: false, maxWidth: 250 },
  {
    id: 'scoutCount',
    label: (
      <>
        スカウト送信数
        <br />/ 上限数
      </>
    ),
    numeric: true,
  },
  {
    id: 'approvedScoutCount',
    label: (
      <>
        スカウト承認数
        <br />/ 送信数
      </>
    ),
    numeric: true,
  },
  {
    id: 'entryInScoutCount',
    label: (
      <>
        エントリー数 /<br />
        スカウト送信数
      </>
    ),
    numeric: true,
  },
]

export const ClientListContainer: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  const [nameParam, setNameParam] = useState('')
  const [filter, setFilter] = useState({
    name: {
      name: 'name',
      label: 'クライアント名',
      value: '',
    },
  })

  let { data, isValidating } = useSWR(`/admins/companies/search?name=${nameParam}`)
  const companies =
    data?.map((company: any) => {
      return {
        id: company.id,
        name: company.name,
        scoutCount: `${company.scoutCount}/${company.scoutLimitCount}`,
        approvedScoutCount: `${company.approvedScoutCount}/${company.scoutCount}`,
        entryInScoutCount: `${company.entryInScoutCount}/${company.scoutCount}`,
      }
    }) ?? []

  const handleChange = (name: string) => (value: string) => {
    const newFilter = {
      ...filter,
      [name]: {
        ...filter[name],
        value,
      },
    }
    setFilter(newFilter)
  }

  const updateFilter = (e: React.FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    setNameParam(filter.name.value)
  }

  const redirectToClient = (client) => {
    history.push(`/clients/${client.id}`)
  }

  return (
    <PageContent>
      <SectionTitle>フィルター</SectionTitle>

      <Paper variant="outlined">
        <form className={classes.filter} onSubmit={updateFilter}>
          <Field size="small" variant="outlined" {...filter.name} onChange={handleChange('name')} />
          <Button variant="contained" color="primary" disableElevation size="large" onClick={updateFilter}>
            検索する
          </Button>
        </form>
      </Paper>

      <SectionTitle>クライアント一覧</SectionTitle>

      <BasicTable<Row> columns={columns} rows={companies} onRowClick={redirectToClient} isValidating={isValidating} />
    </PageContent>
  )
}
