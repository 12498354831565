import React from 'react'
import { useLocation } from 'react-router'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

import { Grid, CssBaseline } from '@material-ui/core'

import { AppHeader } from '@/components/organisms/app-header'
import { AppSidebar } from '@/components/organisms/app-sidebar'
import { AppMain } from '@/components/organisms/app-main'
import { AppChat } from '@/components/organisms/app-chat'
import { useSession } from '@/hooks/use-session'
import { SnackbarProvider } from '@/providers/snackbar-provider'
import { CaxiosWrapper } from '@/providers/caxios-wrapper'

const modalPagePathnames = ['company', 'admin', 'forgot-password', 'reset-password']

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
  }),
)

export const BasicLayout: React.FC = (props) => {
  const classes = useStyles()
  const location = useLocation()
  const { isAuthenticated } = useSession()

  const firstComponent = location.pathname.split('/')[1]
  if (!isAuthenticated || modalPagePathnames.some((p) => p === firstComponent)) {
    return <Grid container spacing={0} direction="column" alignItems="center" justify="center" style={{ minHeight: '100vh' }} {...props} />
  }

  return (
    <div className={classes.root}>
      <SnackbarProvider>
        <CaxiosWrapper>
          <CssBaseline />
          <AppHeader />
          <AppSidebar />
          <AppMain>{props.children}</AppMain>
          <AppChat />
        </CaxiosWrapper>
      </SnackbarProvider>
    </div>
  )
}
