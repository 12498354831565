import React, { useState, useContext } from 'react'
import { Paper, Typography, Button, CircularProgress, makeStyles, createStyles, Theme, Avatar } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { caxios } from '@/submodules/custom-axios'
import { Field } from '@/components/molecules/field'
import { SnackbarContext } from '@/providers/snackbar-provider'
import SendIcon from '@material-ui/icons/Send'
import { useForm } from 'react-hook-form'
import { mutate } from 'swr'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(6),
    },
    text: {
      marginBottom: theme.spacing(3),
    },
    heading: {
      marginBottom: theme.spacing(1),
    },
    columns: {
      display: 'flex',
    },
    avatarColumn: {},
    avatarContainer: {
      position: 'relative',
      display: 'inline-block',
      borderRadius: '100%',
    },
    avatar: {
      width: theme.spacing(12),
      height: theme.spacing(12),
      background: theme.palette.grey[200],
      color: 'rgba(0, 0, 0, 0.54)',
      padding: theme.spacing(4),
      transition: theme.transitions.create(['filter'], {
        duration: theme.transitions.duration.short,
      }),
    },
    detailsColumn: {
      marginLeft: theme.spacing(4),
    },
    detailsForm: {
      display: 'grid',
      gap: theme.spacing(2) + 'px',
    },
    controls: {
      display: 'flex',
      marginTop: theme.spacing(3),
      justifyContent: 'flex-end',
      '& > *:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
    resendButton: {
      width: 132,
    },
    spinner: {
      color: 'inherit',
    },
  }),
)

const emailValid = /^[A-Za-z0-9]{1}[A-Za-z0-9_.+-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/

type Props = {
  personnel: any
}

enum View {
  Info,
  Resend,
}

export const PersonnelInfo: React.FC<Props> = (props) => {
  const { register, handleSubmit, errors } = useForm()
  const { personnel } = props
  const classes = useStyles()
  const { id } = useParams()
  const [emailFieldValue, setEmailFieldValue] = useState(personnel.email)
  const [loading, setLoading] = useState(false)
  const { setSnackbarMessage } = useContext(SnackbarContext)
  const [view, setView] = useState(View.Info)

  const fields = personnel && {
    repName: {
      name: 'repName',
      label: '担当者名',
      value: personnel.name || '(未設定)',
      readOnly: true,
    },
    email: {
      name: 'email',
      label: 'メールアドレス',
      value: personnel.email,
      readOnly: true,
    },
    description: {
      name: 'description',
      label: '説明文',
      value: personnel.description || '(未設定)',
      readOnly: true,
      multiline: true,
    },
  }

  const resendPasswordEmail = async () => {
    setLoading(true)
    try {
      const res = await caxios.patch(`/admins/companies/${id}/personnels/${personnel.id}/resend`, {
        email: emailFieldValue,
      })
      setSnackbarMessage('初期パスワードを再送しました')
      setView(View.Info)
      mutate(`/admins/companies/${id}/personnels`, res.data)
    } catch (e) {
      switch (e.response.data.message) {
        case 'Email already exist.':
          setSnackbarMessage('このメールアドレスは既に使用されています')
          break
        default:
          setSnackbarMessage('未知のエラー')
      }
    }
    setLoading(false)
  }

  const emailField = {
    name: 'email',
    label: '担当者のメールアドレス',
    defaultValue: emailFieldValue,
    onChange: setEmailFieldValue,
    validation: {
      required: '入力したメールアドレスが正しくありません',
      pattern: { value: emailValid, message: 'メールアドレスの形式が違います' },
    },
  }

  return (
    <Paper className={classes.paper} variant="outlined">
      {view === View.Info && (
        <>
          <div className={classes.columns}>
            <div className={classes.avatarColumn}>
              <div className={classes.avatarContainer}>
                <Avatar className={classes.avatar} src={personnel.iconUrl} />
              </div>
            </div>
            <div className={classes.detailsColumn}>
              <form className={classes.detailsForm}>
                {Object.keys(fields).map((key) => (
                  <Field key={key} variant="outlined" {...fields[key]} />
                ))}
              </form>
            </div>
          </div>
          <div className={classes.controls}>
            <Button variant="contained" disableElevation size="large" onClick={() => setView(View.Resend)}>
              初期パスワードを再送する
            </Button>
          </div>
        </>
      )}
      {view === View.Resend && (
        <>
          <div className={classes.text}>
            <Typography variant="body1" className={classes.heading}>
              初期パスワードを再送する
            </Typography>
            <Typography variant="body2" color="textSecondary">
              メールが届きませんでしたか？
              <br />
              迷惑メールボックスに入っていないか確認後、
              <br />
              再送の必要があれば、以下に再送先を入力してください。
            </Typography>
          </div>

          <form onSubmit={handleSubmit(resendPasswordEmail)}>
            <Field {...emailField} register={register} errors={errors} variant="outlined" />

            <div className={classes.controls}>
              <Button variant="contained" disableElevation size="large" onClick={() => setView(View.Info)} disabled={loading}>
                キャンセル
              </Button>
              <Button
                className={classes.resendButton}
                variant="contained"
                disableElevation
                size="large"
                color="primary"
                startIcon={!loading && <SendIcon />}
                type="submit"
              >
                {loading ? <CircularProgress size={26} className={classes.spinner} /> : '再送する'}
              </Button>
            </div>
          </form>
        </>
      )}
    </Paper>
  )
}
