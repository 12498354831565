import React, { useState, useContext } from 'react'
import { PageContent } from '@/components/organisms/page-content'
import { BasicTable, Column } from '@/components/organisms/table'
import { SectionTitle, SectionTitleControls } from '@/components/atoms/section-title'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@material-ui/core'
import { Link, useHistory } from 'react-router-dom'
import DeleteIcon from '@material-ui/icons/Delete'
import CreateIcon from '@material-ui/icons/Create'
import useSWR, { mutate } from 'swr'
import { caxios, CaxiosRequestConfig } from '@/submodules/custom-axios'
import { SnackbarContext } from '@/providers/snackbar-provider'

interface Row {
  id: number
  name: string
  isDefault: boolean
}

const columns: Column<Row>[] = [{ id: 'name', label: 'フェーズ名' }]

export const SelectionPhasesContainer: React.FC = () => {
  const [checked, setChecked] = useState<Array<number>>([])
  const history = useHistory()
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [deleteId, setDeleteId] = useState<number>()
  const [showDeletePhaseError, setShowDeletePhaseError] = useState(false)
  const { setSnackbarMessage } = useContext(SnackbarContext)

  const showDeleteDialog = (rowId?: number) => {
    if (!checked && !rowId) return
    if (rowId) setDeleteId(rowId)
    setDeleteDialogOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteId(0)
    setDeleteDialogOpen(false)
  }

  let { data, isValidating } = useSWR('/companies/selection_phases')

  const phases = data ?? []

  const createSelectionPhase = async () => {
    history.push('/settings/selection-phases/add')
  }

  const deleteSelectionPhases = async (ids: number[]) => {
    if (!ids.length) {
      return
    }
    const res = await Promise.all(
      ids.map(async (id: number) => {
        try {
          return await caxios.delete(`companies/selection_phases/${id}`, { disableSnackbar: true } as CaxiosRequestConfig)
        } catch (err) {
          return err.response
        }
      }),
    )
    if (res.some((r) => r.data.message === 'this SelectionPhase has jobseekers')) {
      setShowDeletePhaseError(true)
    } else {
      setChecked(checked.filter((id) => !ids.includes(id)))
      handleCloseDeleteDialog()
      setSnackbarMessage('選考フェーズを削除しました')
    }
    mutate('/companies/selection_phases')
  }

  const deleteSelected = () => {
    if (deleteId) {
      deleteSelectionPhases([deleteId])
      return
    }
    deleteSelectionPhases(checked)
  }

  return (
    <PageContent>
      <SectionTitle>
        選考フェーズ一覧
        <SectionTitleControls>
          <Button variant="contained" disableElevation onClick={() => showDeleteDialog()} disabled={checked.length === 0}>
            一括削除
          </Button>
          <Link to="/settings/selection-phases/add">
            <Button variant="contained" disableElevation color="primary" onClick={createSelectionPhase}>
              追加
            </Button>
          </Link>
        </SectionTitleControls>
      </SectionTitle>

      <BasicTable<Row>
        columns={columns}
        rows={phases}
        checked={checked}
        setChecked={setChecked}
        disableCheckbox={(row) => row.isDefault}
        isValidating={isValidating}
        rowControls={(row) =>
          !row.isDefault && (
            <>
              <IconButton onClick={() => showDeleteDialog(row.id)}>
                <DeleteIcon />
              </IconButton>
              <IconButton onClick={() => history.push(`/settings/selection-phases/${row.id}`)}>
                <CreateIcon />
              </IconButton>
            </>
          )
        }
      />

      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>選考フェーズの削除</DialogTitle>

        {showDeletePhaseError ? (
          <>
            <DialogContent>
              <DialogContentText>
                学生のいる選考フェーズは、削除されませんでした。
                <br />
                当該学生の選考フェーズを変更した上で、再度実行してください。
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button
                onClick={() => {
                  handleCloseDeleteDialog()
                  setShowDeletePhaseError(false)
                }}
                color="primary"
              >
                OK
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <DialogContent>
              <DialogContentText>
                選考フェーズを削除しますか？
                <br />
                この操作は取り消せません。
                <br />
                <br />
                学生のいる選考フェーズは、削除されません。
                <br />
                当該学生の選考フェーズを変更した上で、再度実行してください。
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleCloseDeleteDialog} color="secondary">
                戻る
              </Button>
              <Button onClick={deleteSelected} color="primary">
                削除する
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </PageContent>
  )
}
