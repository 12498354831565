import React from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Grid, Paper, Typography } from '@material-ui/core'
import { Link } from '@/components/atoms/link'

type DashCardProps = {
  label: string
  value: string
  link?: string
  small?: boolean
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    card: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: 90,
      padding: theme.spacing(1.5),
      userSelect: 'none',
    },
    cardLabel: {
      marginBottom: '-0.5em',
      fontSize: '100%',
    },
    cardValue: {
      color: theme.palette.primary.main,
      fontSize: (props) => ((props as DashCardProps)?.small ? theme.typography.h5.fontSize : theme.typography.h4.fontSize),
      textAlign: 'right',
    },
  }),
)

type DashCardContainerProps = {
  className?: string
}

export const DashCardContainer: React.FC<DashCardContainerProps> = (props) => {
  const classes = useStyles()
  return (
    <Grid container className={props.className} spacing={2}>
      {props.children}
    </Grid>
  )
}

export const DashCard: React.FC<DashCardProps> = (props) => {
  const classes = useStyles(props)
  return (
    <Grid item xs={4}>
      {props.link ? (
        <Link url={props.link}>
          <Paper className={classes.card} variant="outlined">
            <Typography className={classes.cardLabel}>{props.label}</Typography>
            <Typography className={classes.cardValue}>{props.value}</Typography>
          </Paper>
        </Link>
      ) : (
        <Paper className={classes.card} variant="outlined">
          <Typography className={classes.cardLabel}>{props.label}</Typography>
          <Typography className={classes.cardValue}>{props.value}</Typography>
        </Paper>
      )}
    </Grid>
  )
}
