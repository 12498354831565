import React, { useState } from 'react'
import { Container, Button } from '@material-ui/core'
import { LoginCard } from '@/components/organisms/login-card'
import { Image } from '@/components/atoms/image'
import { Link } from '@/components/atoms/link'
import { LoginFooter } from '@/components/molecules/login-footer/login-footer'
import { caxios } from '@/submodules/custom-axios'
import { RESET_PASSWORD_EMAIL_KEY } from '../reset-password'
import { useStyles } from '../login'

const emailValid = /^[A-Za-z0-9]{1}[A-Za-z0-9_.-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/

export function ForgotPasswordContainer() {
  const classes = useStyles()
  const [success, setSuccess] = useState(false)
  const [email, setEmail] = useState<string>('')

  const handleSubmit = async () => {
    await caxios.post(
      '/companies/personnels/reset_password',
      {
        email,
        redirectUrl: `${process.env.REACT_APP_BASE_URL}/reset-password`,
      },
      {
        withCredentials: false,
        headers: {
          'Content-Type': 'application/json',
        },
      },
    )
    localStorage.setItem(RESET_PASSWORD_EMAIL_KEY, email)
    setSuccess(true)
  }

  const fields = {
    email: {
      name: 'email',
      type: 'email',
      onChange: setEmail,
      label: 'アカウントメールアドレス',
      validation: {
        required: 'メールアドレスを入力してください',
        pattern: { value: emailValid, message: 'メールアドレスの形式が違います' },
      },
    },
  }

  const reload = () => {
    setEmail('')
    setSuccess(false)
  }

  return (
    <Container className={classes.root}>
      <div className={classes.centered}>
        <Image src="/logo.svg" />
        <p>企業様用管理画面</p>

        <LoginCard
          caption="アカウントのメールアドレス宛に、パスワード再設定ページのURLが書かれたメールをお送りします。"
          fields={fields}
          onSubmit={handleSubmit}
          success={
            success && (
              <>
                <p>{email} に、パスワード再設定ページのURLをお送りしました。受信トレイを確認してください。</p>

                <p>
                  メールが届かない場合は、メールが迷惑メールボックスに入っていないか確認してください。
                  <br />
                  見つからない場合は、再度、再設定の手続きを行ってください。
                </p>

                <Button variant="contained" color="primary" disableElevation type="submit" size="large" onClick={reload}>
                  パスワード再設定ページへ
                </Button>
              </>
            )
          }
          submitLabel="メール送信"
        />

        <Link url="/company">
          <Button variant="contained" disableElevation className={classes.button}>
            ログインページに戻る
          </Button>
        </Link>
      </div>

      <LoginFooter />
    </Container>
  )
}
