import { useEffect } from 'react'
import { useAppContext } from '@/store'

export const useTitle = (text?: string, icon?: JSX.Element) => {
  const app = useAppContext()
  useEffect(() => {
    if (text) {
      app.dispatch({ type: 'SET_APP_HEADER_TITLE', payload: { icon, text } })
    }
  }, [text])
  return text ?? app.state.appHeaderTitle
}
