import React from 'react'
import { useHistory } from 'react-router-dom'
import { Button, createStyles, makeStyles, Theme } from '@material-ui/core'
import { useTitle } from '@/hooks/use-title'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
    },
  }),
)

export const NotFoundErrorPage: React.FC = () => {
  const classes = useStyles()
  const history = useHistory()
  useTitle('お探しのページが見つかりませんでした')

  return (
    <div className={classes.root}>
      <h1>お探しのページが見つかりませんでした</h1>
      <Button variant="contained" color="primary" disableElevation onClick={() => history.push('/')}>
        戻る
      </Button>
    </div>
  )
}
