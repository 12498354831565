import React, { useContext, useEffect } from 'react'
import { SnackbarContext } from './snackbar-provider'
import { caxios } from '@/submodules/custom-axios'

type Props = {
  children: any
}

export const CaxiosWrapper: React.FC<Props> = (props) => {
  const { setSnackbarMessage } = useContext(SnackbarContext)
  const errorTypes = [400, 404]

  useEffect(() => {
    caxios.interceptors.response.use(
      (res) => res,
      (err) => {
        if (errorTypes.includes(err.response?.status) && !err.response.config.disableSnackbar)
          setSnackbarMessage(`エラーが発生しました： ${err.response.data.message || '通信エラー'}`)
        return Promise.reject(err)
      },
    )
  }, [])

  return props.children
}
