import React, { useState, useMemo, useEffect } from 'react'
import qs from 'qs'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Paper, Button } from '@material-ui/core'
import { Field } from '@/components/molecules/field'
import { SectionTitle } from '@/components/atoms/section-title'
import { BasicTable, Column } from '@/components/organisms/table'
import { PageContent } from '@/components/organisms/page-content'
import { useTitle } from '@/hooks/use-title'
import BusinessIcon from '@material-ui/icons/Business'
import useSWR from 'swr'
import { NotFoundErrorPage } from '@/components/pages/not-found-error'
import { useParams, useHistory } from 'react-router-dom'
import { format } from 'date-fns'
import { useAppContext, useDispatch } from '@/store'
import { useDebounce } from 'use-debounce/lib'

enum View {
  Entries = 'entry',
  InfoSession = 'info-session',
  Interns = 'interns',
  Browsed = 'browsed',
  Keep = 'keep',
}

interface Row {
  id: number
  name: string
  infoSessionApplicationDate: string
  status: string
  lastLogin: string
  jobseekerId: number
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {},
    list: {
      padding: 0,
    },
    filter: {
      padding: theme.spacing(4),
      display: 'grid',
      gap: `${theme.spacing(2)}px`,
      '& > *': {
        width: 220,
      },
    },
    listItem: {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    listItemIcon: {
      width: 48,
    },
    iconButton: {
      width: 48,
      height: 48,
    },
    detailsColumn: {
      flex: '0 1 350px',
    },
    detailsForm: {
      display: 'grid',
      gap: theme.spacing(2) + 'px',
    },
    formControl: {
      width: 200,
    },
    submitButton: {
      top: 8,
      height: 39,
    },
    sectionTitle: {
      alignItems: 'flex-end',
    },
    sectionTitleText: {
      marginBottom: 5,
    },
    dialogContent: {
      minWidth: 500,
      whiteSpace: 'pre-wrap',
    },
    dialogContentText: {
      margin: 0,
    },
  }),
)

const titles: { [key in View]: string } = {
  entry: 'エントリー',
  'info-session': '説明会申込',
  interns: 'インターン申込',
  browsed: '閲覧履歴',
  keep: 'お気に入り',
}

const endpoints: { [key in View]: string } = {
  entry: 'jobs',
  'info-session': 'company_sessions',
  interns: 'internships',
  browsed: 'browse_histories',
  keep: 'keeps',
}

const distinctIds = (value: any, index: number, self: any[]) => {
  return self.map((row) => row.jobseeker.id).indexOf(value.jobseeker.id) === index
}

export const ClientStudentListContainer: React.FC = () => {
  const app = useAppContext()
  const classes = useStyles()
  const { id, view } = useParams()
  const { data: companyData, isValidating } = useSWR(`/admins/companies/${id}`)
  const history = useHistory()
  const [filter, setFilter] = useState({
    name: {
      name: 'name',
      label: '学生氏名',
      value: '',
    },
    selectionPhase: {
      name: 'selectionPhase',
      type: 'select',
      label: '選考フェーズ',
      value: '',
      options: [{ label: '未選択', value: '' }],
    },
  })
  const [checked, setChecked] = useState<Array<number>>([])
  const dispatch = useDispatch()

  const [entriesUrl] = useDebounce(
    useMemo<string>(
      () =>
        `/admins/companies/${id}/jobseekers/${endpoints[view]}?` +
        Object.entries({
          jobseeker_name: filter.name.value,
          selection_phase_id: filter.selectionPhase.value,
          academic_year: app.state.selectedYear,
          school_division_id: app.state.selectedDivision,
        })
          .filter(([_key, value]) => String(value).length)
          .map(([key, value]) => `${key}=${value}`)
          .join('&'),
      [filter, view, app.state.selectedYear, app.state.selectedDivision],
    ),
    500,
  )

  const { data } = useSWR(entriesUrl)

  const client = companyData?.company

  const results = useMemo(() => data?.filter(distinctIds), [data, distinctIds])
  const params = useMemo(() => qs.stringify({ jobseeker_ids: results?.map((res) => res.jobseeker.id) }, { arrayFormat: 'brackets' }), [
    results,
  ])
  let { data: selectionPhases } = useSWR(`/admins/companies/${id}/selection_phases/available?${params}`)

  let title = `クライアント一覧`
  if (client) {
    title += ` / ${client.name} / ${titles[view]}学生一覧`
  }
  useTitle(title, <BusinessIcon />)

  const columns: Column<Row>[] = [
    { id: 'name', label: '氏名' },
    { id: 'infoSessionApplicationDate', label: `${titles[view]}申込日`, numeric: true },
    { id: 'status', label: '選考フェーズ', align: 'right' },
    { id: 'lastLogin', label: '最終ログイン', numeric: true },
  ]

  const rows = useMemo(
    () =>
      results?.map((result) => ({
        id: result.id,
        name: result.jobseeker.name,
        infoSessionApplicationDate: format(new Date(result.updatedAt), 'yyyy/M/d'),
        status: result.jobseeker.selectionPhase.name,
        lastLogin: format(new Date(result.jobseeker.lastSignInAt), 'yyyy/M/d'),
        jobseekerId: result.jobseeker.id,
      })) ?? [],
    [results],
  )

  useEffect(() => {
    setFilter({
      ...filter,
      selectionPhase: {
        ...filter.selectionPhase,
        options: [
          { label: '未選択', value: '' },
          ...(selectionPhases?.map((p: any) => ({
            label: p.name,
            value: p.id,
          })) ?? []),
        ],
      },
    })
  }, [selectionPhases])

  const handleChange = (name: string) => (value: string) => {
    const newFilter = {
      ...filter,
      [name]: {
        ...filter[name],
        value,
      },
    }
    setFilter(newFilter)
  }

  if (!Object.values(View).includes(view)) {
    return <NotFoundErrorPage />
  }

  if (!companyData) {
    return null
  }

  const redirectToStudentInformation = (row: Row) => {
    history.push(`/clients/${id}/student-detail/${row.jobseekerId}`)
  }

  const addSelectedToBulkChat = () => {
    dispatch({
      type: 'ADD_TO_BULK_CHAT',
      payload: {
        students: checked.map((id) => results.find((r) => r.id === id)),
      },
    })
  }

  const showBulkChat = view === View.Browsed || view === View.Keep

  return (
    <PageContent>
      <SectionTitle>フィルター</SectionTitle>

      <Paper className={classes.filter} variant="outlined">
        <Field size="small" variant="outlined" {...filter.name} onChange={handleChange('name')} />
        <Field size="small" variant="outlined" {...filter.selectionPhase} onChange={handleChange('selectionPhase')} />
      </Paper>

      <SectionTitle>
        {titles[view]}
        {showBulkChat && (
          <Button
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            onClick={addSelectedToBulkChat}
            disabled={checked.length === 0}
          >
            一括スカウトチャット作成
          </Button>
        )}
      </SectionTitle>

      <BasicTable<Row>
        columns={columns}
        rows={rows}
        checked={showBulkChat ? checked : undefined}
        setChecked={setChecked}
        onRowClick={redirectToStudentInformation}
        isValidating={isValidating}
      />
    </PageContent>
  )
}
