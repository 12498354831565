import React from 'react'
import { SectionTitle } from '@/components/atoms/section-title'
import { BasicTable, Column } from '@/components/organisms/table'
import { DashCardContainer, DashCard } from '@/components/molecules/dash-card'
import { PageContent } from '@/components/organisms/page-content'
import useSWR from 'swr'

interface Row {
  id: number
  name: string
  currentCount: number
  passingCount: number
  passingRate: number
}

const columns: Column<Row>[] = [
  { id: 'name', label: 'フェーズ', numeric: false },
  { id: 'currentCount', label: '対象人数', numeric: true, suffix: '人' },
  { id: 'passingCount', label: '通過人数', numeric: true, suffix: '人' },
  { id: 'passingRate', label: '通過率', numeric: true, suffix: '%' },
]

export const DashboardContainer: React.FC = () => {
  const { data: status } = useSWR(`/companies/status`)
  const { data: plan } = useSWR(`/companies/plans`)

  if (!status || !plan) {
    return null
  }

  const rows = status.selectionPhaseStatuses ?? []

  return (
    <PageContent>
      {/* ↓　TODO: 2月中を目途に再表示する予定です　↓ */}
      {/* <SectionTitle>選考状況</SectionTitle>
      <BasicTable<Row> columns={columns} rows={rows} />
      */}

      <SectionTitle>申込状況</SectionTitle>
      <DashCardContainer>
        <DashCard label="エントリー" value={`${status.jobEntryCount}件`} link="/students/entry" />
        <DashCard label="説明会申込" value={`${status.companySessionCount}件`} link="/students/info-session" />
        <DashCard label="インターン申込" value={`${status.internshipCount}件`} link="/students/interns" />
      </DashCardContainer>

      <SectionTitle>サービスご利用状況</SectionTitle>
      <DashCardContainer>
        <DashCard label="ご契約プラン" value={plan.companyPlan.plan.name} small />
        <DashCard
          label="スカウトチャット送信数"
          value={`${plan.companyPlan.scoutCount + plan.planOption.scoutCount}/${
            plan.companyPlan.plan.scoutChatLimit + plan.planOption?.scoutChatLimit
          }件`}
          small
        />
      </DashCardContainer>
    </PageContent>
  )
}
