import React, { useState, useMemo } from 'react'
import { ScoutFilter } from '@/components/organisms/scout-filter'
import { ScoutResults } from '@/components/organisms/scout-results'
import { PageContent } from '@/components/organisms/page-content'
import { PageSidebar } from '@/components/organisms/page-sidebar'
import { OptionsConfig, Value, DynamicOptionsConfig } from '@/components/molecules/field'

interface Field {
  name: string
  type: string
  label: string
  value?: Value
  options?: OptionsConfig
  placeholder?: string
  dynamicOptions?: DynamicOptionsConfig
}

const resolve = (res: any) =>
  res[Object.keys(res)[0]].map((option: any) => {
    return {
      value: option.id || option.code,
      label: option.name,
    }
  })

const initialFilter: Field[] = [
  {
    name: 'jobHuntingStatusCodes',
    type: 'checkboxList',
    label: '就職状況',
    value: [],
    dynamicOptions: {
      endpoint: '/job_hunting_statuses',
      resolve,
    },
  },
  {
    name: 'joinAgreementStatusCodes',
    type: 'checkboxList',
    label: '内定クライアント',
    value: [],
    dynamicOptions: {
      endpoint: '/join_agreement_statuses',
      resolve,
    },
  },
  {
    name: 'includeAlreadyScouted',
    label: 'スカウト済の学生',
    type: 'checkbox',
    value: false,
  },
  {
    name: 'importantPointIds',
    type: 'multiple',
    label: '就活の軸',
    value: [],
    dynamicOptions: {
      endpoint: '/important_points',
      resolve,
    },
  },

  {
    name: 'selfAnalysisTagIds',
    type: 'multiple',
    label: '自分の強み',
    value: [],
    dynamicOptions: {
      endpoint: '/self_analysis_tags',
      resolve,
    },
  },
  {
    name: 'preferredCityIds',
    type: 'multiple',
    label: '希望勤務地',
    value: [],
    dynamicOptions: {
      endpoint: '/cities',
      resolve,
    },
  },
  {
    name: 'preferredCategoryIds',
    type: 'multiple',
    label: '希望職種',
    value: [],
    dynamicOptions: {
      endpoint: '/categories',
      resolve,
    },
  },
  {
    name: 'genderCodes',
    type: 'checkboxList',
    label: '性別',
    value: [],
    dynamicOptions: {
      endpoint: '/genders',
      resolve,
    },
  },
  {
    name: 'prefectureCodes',
    type: 'multiple',
    label: '居住地',
    value: [],
    dynamicOptions: {
      endpoint: '/prefectures',
      resolve,
    },
  },
  {
    name: 'originPrefectureCodes',
    type: 'multiple',
    label: '出身地',
    value: [],
    dynamicOptions: {
      endpoint: '/prefectures',
      resolve,
    },
  },
  {
    name: 'schoolName',
    type: 'text',
    label: '大学',
    placeholder: '大学名を入力',
    value: '',
  },
  {
    name: 'facultyDivisionCodes',
    type: 'checkboxList',
    label: '文理',
    value: [],
    dynamicOptions: {
      endpoint: '/faculty_divisions',
      resolve,
    },
  },
]

interface Field {
  name: string
  value?: Value
}

const useStoredFilter: (key: string, initialValue: Field[]) => [Field[], (v: Field[]) => void] = (key, initialValue) => {
  const initial = useMemo(() => {
    return initialValue.map((v) => ({ ...v }))
  }, [])
  const storedValue = useMemo(() => {
    const storedJson = sessionStorage.getItem(key)
    if (!storedJson) {
      return
    }
    const storedFieldValues = JSON.parse(storedJson)
    const restoredFilter = initial
    for (const [key, value] of Object.entries(storedFieldValues)) {
      const field = restoredFilter.find((field) => field.name === key)
      if (!field || value === 'undefined') {
        continue
      }
      if (typeof value === 'string' && !value.length) {
        continue
      }
      if (Array.isArray(value) && !value.length) {
        continue
      }
      field.value = value as Value
    }
    return restoredFilter
  }, [])
  const [value, setValue] = useState(storedValue || initial)
  const setAndStore = (value: Field[]) => {
    const fieldValues = {}
    value.forEach((field) => {
      fieldValues[field.name] = field.value
    })
    sessionStorage.setItem(key, JSON.stringify(fieldValues))
    setValue(value)
  }
  return [value, setAndStore]
}

export const ScoutContainer: React.FC = () => {
  const [filter, setFilter] = useStoredFilter('FILTER', initialFilter)
  const [searchFilter, setSearchFilter] = useStoredFilter('SEARCH_FILTER', initialFilter)

  const handleSearch = () => {
    setSearchFilter(filter)
  }

  const [startBulkChatCallback, setStartBulkChatCallback] = useState<(() => void) | null>(null)

  const registerStartBulkChatCallback = (callback: () => void) => {
    setStartBulkChatCallback(() => callback)
  }

  const startBulkChat = () => {
    if (startBulkChatCallback) {
      startBulkChatCallback()
    }
  }
  return (
    <>
      <PageSidebar>
        <ScoutFilter filter={filter} setFilter={setFilter} onSearch={handleSearch} onStartBulkChat={startBulkChat} />
      </PageSidebar>
      <PageContent>
        <ScoutResults filter={searchFilter} onStartBulkChat={registerStartBulkChatCallback} />
      </PageContent>
    </>
  )
}
