import { caxios, CaxiosRequestConfig } from '@/submodules/custom-axios'

export const fetcher = async (url: string, params: object) => {
  return await caxios
    .get(url, {
      params,
    })
    .then((res) => res.data)
}

export const fetcherWithConfig = (config: CaxiosRequestConfig) => async (url: string, params: object) => {
  return await caxios
    .get(url, {
      ...config,
      params,
    })
    .then((res) => res.data)
}
