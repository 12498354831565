import React from 'react'
import PeopleIcon from '@material-ui/icons/People'
import { StudentListContainer, sectionTitles } from '@/components/containers/student-list'
import { useTitle } from '@/hooks/use-title'

export type Props = {
  view: 'entry' | 'infoSession' | 'intern' | 'all'
}

export const StudentListPage: React.FC<Props> = (props: Props) => {
  const title = props.view === 'all' ? 'ホーム / 選考フェーズ別学生一覧' : `応募者一覧 / ${sectionTitles[props.view]}`
  useTitle(title, <PeopleIcon />)

  return <StudentListContainer view={props.view} />
}
