import React from 'react'
import { DialogTitle, DialogContent, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { BasicTable, Column } from '@/components/organisms/table'
import useSWR from 'swr'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { useSession } from '@/hooks/use-session'
import { EmptyPlaceholder } from '@/components/organisms/empty-placeholder'
import { useHistory } from 'react-router-dom'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    templateTitle: {
      textAlign: 'center',
      font: theme.typography.h6.font,
    },
    content: {
      width: 734,
      minHeight: 424,
      padding: 0,
    },
    emptyContent: {
      width: 734,
      maxHeight: 325,
      padding: 0,
    },
    table: {
      width: '100%',
    },
    dialogCloseButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      margin: theme.spacing(1),
    },
    placeholderWrapper: {
      padding: '50px',
    },
    ruler: {
      paddingBottom: '1px',
      boxShadow: '0px 1px 1px #E0E0E0',
    },
  }),
)

interface Row {
  id: number
  companyId: number
  title: string
  message: string
}

const columns: Column<Row>[] = [
  { id: 'id', label: 'No.', align: 'left' },
  { id: 'title', label: 'タイトル' },
  { id: 'message', label: '内容' },
]

type Props = {
  insertChatTemplate: (m: string) => void
  closeDialog?: () => void
}

export const TemplateList: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { isAdmin } = useSession()
  const { data: templates, isValidating } = useSWR(`/${isAdmin ? 'admins' : 'companies'}/chat_templates`)
  const placeholderText = 'データがありません\nこちらからテンプレートを登録できます'
  const history = useHistory()

  const insertChatTemplate = (template: Row) => {
    props.insertChatTemplate(template.message)
  }

  const noContent = !templates?.length && !isValidating
  const contentClass = noContent ? classes.emptyContent : classes.content

  const toTemplateSettings = () => {
    if (props.closeDialog) props.closeDialog()
    history.push('/settings/chat-templates')
  }

  return (
    <>
      <IconButton aria-label="Close" className={classes.dialogCloseButton} onClick={props.closeDialog}>
        <CloseIcon />
      </IconButton>

      <DialogTitle className={classes.templateTitle}>チャットテンプレートの選択</DialogTitle>
      <DialogContent className={contentClass}>
        {templates?.length && (
          <BasicTable<Row>
            columns={columns}
            rows={templates}
            className={classes.table}
            onRowClick={insertChatTemplate}
            square
            isValidating={isValidating}
          />
        )}
        {noContent && (
          <>
            <div className={classes.ruler} />
            <div className={classes.placeholderWrapper}>
              <EmptyPlaceholder
                bodyText={placeholderText}
                buttonLabel="チャットテンプレート設定へ"
                onButtonClick={toTemplateSettings}
              ></EmptyPlaceholder>
            </div>
          </>
        )}
      </DialogContent>
    </>
  )
}
