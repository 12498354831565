import React, { useState } from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Container, Button } from '@material-ui/core'
import { LoginCard } from '@/components/organisms/login-card'
import { Image } from '@/components/atoms/image'
import { Link } from '@/components/atoms/link'
import { Text } from '@/components/atoms/text'
import { useDispatch } from '@/store'
import { LoginFooter } from '@/components/molecules/login-footer/login-footer'
import { caxios } from '@/submodules/custom-axios'

const emailValid = /^[A-Za-z0-9]{1}[A-Za-z0-9_.+-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/

function getErrorMessage(message: string) {
  switch (message) {
    case 'Incorrect email or password.':
    case 'Find record `Staff` error: record not found':
      return 'メールアドレスまたはパスワードが間違っています'
    case 'Find record `CompanyPlan` error: record not found':
      return (
        <>
          エラーが発生しました
          <br />
          Be winまでご連絡ください
        </>
      )
  }
  return '未知のエラー'
}

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      minHeight: '100vh',
      width: '100%',
      maxWidth: 'none',
      padding: '0 80px',
    },
    centered: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      textAlign: 'center',
      padding: theme.spacing(4),
    },
    button: {
      margin: theme.spacing(2),
      backgroundColor: theme.palette.grey[200],
    },
    h6: {
      marginBottom: theme.spacing(1),
    },
    body2: {
      marginBottom: theme.spacing(2),
    },
    link: {
      textDecoration: 'underline',
    },
  }),
)

export function LoginContainer() {
  const [error, setError] = useState<React.ReactNode>()
  const location = useLocation()
  const history = useHistory()
  const dispatch = useDispatch()
  const classes = useStyles()
  const view = location.pathname === '/admin' ? 'admin' : 'company'
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  const login = async () => {
    try {
      const endpoint = `/${view === 'admin' ? 'admins' : 'companies'}/login`
      const res = await caxios.post(
        endpoint,
        {
          email,
          password,
        },
        {
          withCredentials: false,
        },
      )

      dispatch({
        type: 'LOGIN',
        payload: {
          isAdmin: view === 'admin',
          isAuthenticated: true,
          token: res.headers.authorization,
          ...res.data,
        },
      })
      history.push('/')
    } catch (err) {
      if (err.response?.data.message) {
        setError(getErrorMessage(err.response.data.message))
        return
      }
    }
  }

  const fields = {
    email: {
      name: 'email',
      type: 'email',
      onChange: setEmail,
      label: 'メールアドレス',
      validation: {
        required: 'メールアドレスを入力してください',
        pattern: { value: emailValid, message: 'メールアドレスの形式が違います' },
      },
    },

    password: {
      name: 'password',
      label: 'パスワード',
      type: 'password',
      onChange: setPassword,
      spellCheck: false,
      validation: {
        required: 'パスワードを入力してください',
      },
    },
  }

  return (
    <Container className={classes.root}>
      <div className={classes.centered}>
        <Image src="/logo.svg" />
        <p>{view === 'admin' ? '運営者用管理画面' : '企業様用管理画面'}</p>

        <LoginCard fields={fields} onSubmit={login} submitLabel="ログイン" error={error} />

        {view === 'company' && (
          <>
            <Link url="/forgot-password">
              <Button variant="contained" disableElevation className={classes.button}>
                パスワードを忘れた方はこちら
              </Button>
            </Link>

            <Text>
              メールアドレスを忘れた場合は、Be winまでご連絡ください
              <br />
              Mail:{' '}
              <a className={classes.link} href={`mailto:${process.env.REACT_APP_BEWIN_CONTACT_EMAIL}`}>
                {process.env.REACT_APP_BEWIN_CONTACT_EMAIL}
              </a>
            </Text>
          </>
        )}
      </div>
      {view === 'company' && <LoginFooter />}
    </Container>
  )
}
