import React from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { APP_HEADER_HEIGHT } from '@/components/organisms/app-header'
import { useAppContext } from '@/store'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      marginTop: APP_HEADER_HEIGHT,
      marginBottom: 58,
      flexGrow: 1,
      backgroundColor: theme.palette.background.default,
      padding: theme.spacing(3),
      justifyContent: 'center',
      transition: theme.transitions.create(['padding-right'], {
        duration: theme.transitions.duration.standard,
      }),
    },
  }),
)

export const AppMain: React.FC = (props) => {
  const classes = useStyles()
  const app = useAppContext()
  const { open } = app.state.chat
  return (
    <main className={classes.root} style={{ paddingRight: open ? 380 : 0 }}>
      {props.children}
    </main>
  )
}
