import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import {
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  DialogTitle,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'
import { Field } from '@/components/molecules/field'
import { SectionTitle, SectionTitleControls } from '@/components/atoms/section-title'
import { BasicTable, Column } from '@/components/organisms/table'
import { Props } from '@/components/pages/student-list'
import { PageContent } from '@/components/organisms/page-content'
import useSWR from 'swr'
import { format } from 'date-fns'
import { useHistory } from 'react-router-dom'
import { caxios } from '@/submodules/custom-axios'
import { useDebounce } from 'use-debounce'
import { useAppContext } from '@/store'
import { isConstructorDeclaration } from 'typescript'

interface Row {
  id: number
  jobseekerId: number
  fullName: string
  list: string
  status: string
  lastLogin: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {},
    list: {
      padding: 0,
    },
    filter: {
      padding: theme.spacing(3),
      display: 'grid',
      gap: theme.spacing(1) + 'px',
      '& > *': {
        width: 220,
      },
      '& .MuiFormControl-marginDense': {
        marginTop: 0,
      },
    },
    listItem: {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
    },
    listItemIcon: {
      width: 48,
    },
    iconButton: {
      width: 48,
      height: 48,
    },
    detailsColumn: {
      flex: '0 1 350px',
    },
    detailsForm: {
      display: 'grid',
      gap: theme.spacing(2) + 'px',
    },
    formControl: {
      width: 220,
    },
    submitButton: {
      top: 8,
      height: 39,
    },
    sectionTitle: {
      alignItems: 'flex-end',
    },
    sectionTitleText: {
      marginBottom: 5,
    },
    dialogContent: {
      minWidth: 500,
      whiteSpace: 'pre-wrap',
    },
    dialogContentText: {
      margin: 0,
    },
  }),
)

export const sectionTitles = {
  entry: 'エントリー',
  infoSession: '説明会',
  intern: 'インターン',
  all: 'ホーム / 選考フェーズ別学生一覧',
}

const columnHeadings = {
  entry: 'エントリー日',
  infoSession: '説明会申込日',
  intern: 'インターン申込日',
}

const entryCategories = {
  entry: 'JobEntry',
  infoSession: 'CompanySessionDetailEntry',
  intern: 'InternshipDetailEntry',
}

const distinctIds = (value: any, index: number, self: any[]) => {
  return self.map((row) => row.jobseekerId).indexOf(value.jobseekerId) === index
}

export const StudentListContainer: React.FC<Props> = (props: Props) => {
  const app = useAppContext()
  const classes = useStyles()
  const [checked, setChecked] = useState<Array<number>>([])
  const [selectionPhase, setSelectionPhase] = useState('')
  const sectionTitle = sectionTitles[props.view]
  const columnHeading = columnHeadings[props.view]
  const history = useHistory()
  const [editDialogOpen, setEditDialogOpen] = useState(false)

  const handleOpenEditDialog = () => {
    setEditDialogOpen(true)
  }

  const handleCloseEditDialog = () => {
    setEditDialogOpen(false)
  }

  const [filter, setFilter] = useState({
    name: {
      name: 'name',
      label: '学生氏名',
      value: '',
    },
    selectionPhase: {
      name: 'selectionPhase',
      type: 'select',
      label: '選考フェーズ',
      value: '',
      placeholder: '未選択',
      options: [{ label: '未選択', value: '' }],
    },
  })

  const commonParams = {
    jobseeker_name: filter.name.value,
    academic_year: app.state.selectedYear,
    school_division_id: app.state.selectedDivision,
  }

  const [entriesUrl] = useDebounce(
    useMemo<string>(
      () =>
        props.view === 'all'
          ? '/companies/jobseekers/search/entried?' +
            Object.entries({
              ...commonParams,
              selection_phase_id: Number(filter.selectionPhase.value),
            })
              .filter(([_key, value]) => value)
              .map(([key, value]) => `${key}=${value}`)
              .join('&')
          : '/companies/jobseekers/entries?' +
            Object.entries({
              ...commonParams,
              entry_category: entryCategories[props.view],
              selection_phase_id: Number(filter.selectionPhase.value),
            })
              .filter(([_key, value]) => value)
              .map(([key, value]) => `${key}=${value}`)
              .join('&'),
      [filter, props.view, app.state.selectedYear, app.state.schoolDivision],
    ),
    500,
  )

  let { data, isValidating } = useSWR(entriesUrl)
  let { data: selectionPhases } = useSWR('/companies/selection_phases')

  const entries = useMemo(() => {
    return data && selectionPhases?.length
      ? (props.view === 'all'
          ? data.jobseekers.map((e: any) => ({
              id: e.id,
              fullName: e.name,
              status: e.selectionPhase.name,
              lastLogin: format(new Date(e.lastSignInAt), 'yyyy/M/d'),
              jobseekerId: e.id,
            }))
          : data.map((e: any) => ({
              id: e.id,
              fullName: e.jobseeker.name,
              list: format(new Date(e.createdAt), 'yyyy/M/d'),
              status: e.jobseeker.selectionPhase.name,
              lastLogin: format(new Date(e.jobseeker.lastSignInAt), 'yyyy/M/d'),
              jobseekerId: e.jobseekerId,
            }))
        ).filter(distinctIds)
      : []
  }, [data, selectionPhases])

  const changeSelectionPhase = useCallback(
    async (event: React.ChangeEvent<{ value: unknown }>) => {
      setSelectionPhase(event.target.value as string)
    },
    [checked],
  )

  const saveSelectionPhase = async () => {
    if (!checked || !selectionPhase) return
    await caxios.post(`/companies/jobseekers/selection_phases/${selectionPhase}`, {
      ids: checked.map((id) => Number(id)),
    })
    setEditDialogOpen(false)
  }

  const columns: Column<Row>[] = useMemo(() => {
    if (props.view === 'all') {
      return [
        { id: 'fullName', label: '氏名' },
        { id: 'status', label: '選考フェーズ', align: 'right' },
        { id: 'lastLogin', label: '最終ログイン', numeric: true },
      ]
    }
    return [
      { id: 'fullName', label: '氏名' },
      { id: 'list', label: columnHeading, numeric: true },
      { id: 'status', label: '選考フェーズ', align: 'right' },
      { id: 'lastLogin', label: '最終ログイン', numeric: true },
    ]
  }, [props.view])

  useEffect(() => {
    if (selectionPhases) {
      setFilter({
        ...filter,
        selectionPhase: {
          ...filter.selectionPhase,
          options: [
            { label: '未選択', value: '' },
            ...selectionPhases.map((p: any) => ({
              label: p.name,
              value: p.id,
            })),
          ],
        },
      })
    }
  }, [selectionPhases])

  const updateFilter = useCallback(
    (name: string) => (value: string) => {
      const newFilter = {
        ...filter,
        [name]: {
          ...filter[name],
          value,
        },
      }
      setFilter(newFilter)
    },
    [filter],
  )

  const redirectToStudentInformation = (row: Row) => {
    history.push(`/students/${row.jobseekerId}`)
  }

  return (
    <PageContent>
      <SectionTitle>フィルター</SectionTitle>

      <Paper className={classes.filter} variant="outlined">
        <Field variant="outlined" margin="dense" {...filter.name} onChange={updateFilter('name')} />
        <Field variant="outlined" margin="dense" {...filter.selectionPhase} onChange={updateFilter('selectionPhase')} />
      </Paper>

      <SectionTitle className={classes.sectionTitle}>
        <div className={classes.sectionTitleText}>{sectionTitle}</div>
        <SectionTitleControls>
          <FormControl variant="outlined" className={classes.formControl} margin="dense">
            <InputLabel>一括フェーズ変更</InputLabel>
            <Select value={selectionPhase} label="一括フェーズ変更" onChange={changeSelectionPhase}>
              {filter.selectionPhase.options.map((p) => (
                <MenuItem key={p.value} value={p.value}>
                  {p.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            className={classes.submitButton}
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            onClick={handleOpenEditDialog}
            disabled={!checked || !selectionPhase}
          >
            変更する
          </Button>
        </SectionTitleControls>
      </SectionTitle>

      <BasicTable<Row>
        columns={columns}
        rows={entries}
        checked={checked}
        setChecked={setChecked}
        onRowClick={redirectToStudentInformation}
        isValidating={isValidating}
      />

      <Dialog open={editDialogOpen} onClose={handleCloseEditDialog}>
        <DialogTitle>フェーズの変更</DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.dialogContentText}>
            {'選択した学生のフェーズを変更します。\nよろしいですか？'}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseEditDialog} color="secondary">
            戻る
          </Button>
          <Button onClick={saveSelectionPhase} color="primary">
            変更する
          </Button>
        </DialogActions>
      </Dialog>
    </PageContent>
  )
}
