import React, { useState, useEffect, useRef, useCallback, useContext } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import {
  Paper,
  Button,
  Avatar,
  Typography,
  Collapse,
  IconButton,
  InputBase,
  DialogContentText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButtonProps,
} from '@material-ui/core'
import { APP_HEADER_HEIGHT } from '@/components/organisms/app-header'
import { SectionTitle } from '@/components/atoms/section-title'
import { ExpandButton } from '@/components/atoms/expand-button'
import { useAppContext, useDispatch } from '@/store'
import MenuBookIcon from '@material-ui/icons/MenuBook'
import CancelIcon from '@material-ui/icons/Cancel'
import SendIcon from '@material-ui/icons/Send'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import CloseIcon from '@material-ui/icons/Close'
import { useSession } from '@/hooks/use-session'
import useSWR, { mutate } from 'swr'
import { format, parseISO, isToday, isYesterday, differenceInMilliseconds, startOfTomorrow, getYear } from 'date-fns'
import { ja } from 'date-fns/locale'
import { caxios } from '@/submodules/custom-axios'
import { useSubscription } from '@/hooks/api/use-subscription'
import { TemplateList } from './template-list'
import { SnackbarContext } from '@/providers/snackbar-provider'
import { Division } from '@/components/containers/student-detail'
import { Link } from 'react-router-dom'

export const appChatWidth = 300
const ADMIN_HEADER_HEIGHT = 60

let tempId = 0

enum ChatView {
  Normal,
  Company,
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      bottom: 0,
      right: 54,
      maxHeight: `calc(100vh - ${APP_HEADER_HEIGHT + theme.spacing(4)}px)`,
    },
    paper: {
      width: appChatWidth,
      maxHeight: (props: any) =>
        props.showAdminHeader
          ? `calc(100vh - ${APP_HEADER_HEIGHT + theme.spacing(4) + ADMIN_HEADER_HEIGHT}px)`
          : `calc(100vh - ${APP_HEADER_HEIGHT + theme.spacing(4)}px)`,
      display: 'flex',
      flexDirection: 'column',
      borderBottomLeftRadius: 0,
      borderBottomRightRadius: 0,
      overflow: 'hidden',
    },
    chatMainContent: {
      height: (props: any) =>
        props.showAdminHeader
          ? `calc(100vh - ${APP_HEADER_HEIGHT + theme.spacing(4) + 58 + ADMIN_HEADER_HEIGHT}px)`
          : `calc(100vh - ${APP_HEADER_HEIGHT + theme.spacing(4) + 58}px)`,
    },
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1, 2),
      height: 58,
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      borderRadius: 0,
      color: theme.palette.grey[600],
      minWidth: 0,
      cursor: 'pointer',
      transition: theme.transitions.create(['background-color'], {
        duration: theme.transitions.duration.short,
      }),
      '&:hover': {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
    headerButton: {
      minWidth: 0,
      margin: theme.spacing(-2, 0, -2, -2),
      padding: theme.spacing(2, 0, 2, 1.25),
      transition: 'transform 0.4s',
      '&:hover': {
        background: 'none',
        transform: 'scale(1.15)',
      },
    },
    headerInner: {
      display: 'flex',
      alignItems: 'center',
      minWidth: 0,
    },
    headerTitle: {
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
      textTransform: 'none',
      margin: 0,
      color: theme.palette.text.primary,
      cursor: 'pointer',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: 'block',
    },
    headerLink: {
      color: 'blue',
      textDecoration: 'underline',
    },
    unreadCount: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: 100,
      padding: '0 8px 0 7px',
      margin: theme.spacing(0, 1),
      minWidth: 24,
      height: 24,
      textAlign: 'center',
      fontSize: theme.typography.body2.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    chatList: {
      overflowY: 'scroll',
      height: '100%',
    },
    chatListItem: {
      display: 'flex',
      width: '100%',
      borderRadius: 0,
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
      padding: theme.spacing(1, 2),
      textTransform: 'none',
    },
    chatAvatar: {
      marginRight: theme.spacing(1.5),
      background: '#64B6F7',
    },
    chatDetails: {
      display: 'flex',
      flexDirection: 'column',
      flexGrow: 1,
      minWidth: 0,
    },
    chatDetailsRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    chatRecipientName: {
      ...theme.typography.body2,
      display: 'block',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
    },
    chatDate: {
      ...theme.typography.caption,
      color: theme.palette.grey[500],
      whiteSpace: 'nowrap',
    },
    chatMessagePreview: {
      display: 'block',
      whiteSpace: 'nowrap',
      flexShrink: 1,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      ...theme.typography.body2,
      color: theme.palette.grey[600],
    },
    chatUnreadCount: {
      display: 'inline-block',
      borderRadius: 100,
      padding: '2px 7px 1px 6px',
      marginLeft: theme.spacing(0.5),
      minWidth: 24,
      flexShrink: 0,
      textAlign: 'center',
      fontSize: theme.typography.caption.fontSize,
      fontWeight: theme.typography.fontWeightMedium,
      background: theme.palette.primary.main,
      color: theme.palette.common.white,
    },
    chat: {
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
    messageList: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column-reverse',
      overflowY: 'scroll',
      padding: theme.spacing(0.75, 0),
    },
    chatMessage: {
      display: 'flex',
      padding: theme.spacing(0.75, 1.5),
      alignItems: 'flex-end',
      '&:first-child': {
        marginBottom: 'auto',
      },
    },
    chatNotificationBody: {
      width: '100%',
      backgroundColor: theme.palette.grey[100],
      display: 'block',
      borderRadius: 5,
      padding: theme.spacing(0.75, 1),
      color: 'rgba(0, 0, 0, .54)',
      textAlign: 'center',
      fontSize: 12,
    },
    chatMessageAvatar: {
      width: 30,
      height: 30,
      marginRight: theme.spacing(1),
      background: '#64B6F7',
    },
    chatMessageLeft: {
      flexDirection: 'row',
    },
    chatMessageRight: {
      flexDirection: 'row-reverse',
      '& > $chatMessageBody': {
        backgroundColor: theme.palette.primary.light,
      },
      overflowWrap: 'break-word',
    },
    chatMessageTimestamp: {
      ...theme.typography.caption,
      lineHeight: 1.2,
      textAlign: 'right',
      color: theme.palette.grey[400],
      margin: theme.spacing(0, 0.75),
      userSelect: 'none',
      whiteSpace: 'pre-line',
    },
    chatMessageBody: {
      position: 'relative',
      overflow: 'hidden',
      display: 'block',
      borderRadius: 5,
      padding: theme.spacing(1),
      backgroundColor: theme.palette.grey[100],
    },
    chatMessageHover: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-start',
      padding: 6,
      background: 'rgba(0,0,0, 0.87)',
      color: theme.palette.common.white,
      opacity: 0,
      cursor: 'pointer',
      fontSize: theme.typography.body2.fontSize,
      transition: theme.transitions.create(['opacity'], {
        duration: theme.transitions.duration.short,
      }),
      '& svg': {
        width: 24,
        height: 24,
      },
      '& div': {
        display: 'flex',
        height: 24,
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap',
        lineHeight: '24px',
      },
      '&:hover': {
        opacity: 1,
      },
    },
    chatMessageComposer: {},
    chatMessageComposerHeader: {
      backgroundColor: theme.palette.grey[100],
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      height: 40,
      padding: '0 8px',
    },
    chatMessageComposerSend: {
      height: 32,
      marginRight: -4,
      '& .MuiSvgIcon-root': {
        marginLeft: 4,
      },
    },
    chatMessageComposerInput: {
      ...theme.typography.body2,
      height: 160,
      padding: 0,
      display: 'block',
      '& textarea': {
        padding: theme.spacing(1),
        boxSizing: 'border-box',
      },
    },
    adminHeader: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: 10,
      width: appChatWidth,
    },
    adminHeaderIcon: {
      width: 40,
      height: 40,
      borderRadius: 40,
      marginRight: theme.spacing(1),
    },
    adminHeaderTitle: {
      fontSize: theme.typography.body1.fontSize,
      fontWeight: theme.typography.fontWeightRegular,
      margin: 0,
      color: theme.palette.text.primary,
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: 'block',
    },
    adminHeaderIconButton: {
      width: 40,
      height: 40,
      borderRadius: 40,
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      cursor: 'pointer',
      background: '#64B6F7',
    },
    adminHeaderSelected: {
      display: 'flex',
      alignItems: 'center',
      borderRadius: 6,
      backgroundColor: '#eeeeee',
      flexGrow: 1,
      padding: theme.spacing(1),
      overflow: 'hidden',
    },
    adminHeaderCloseButton: {
      cursor: 'pointer',
      color: 'rgba(0,0,0,0.54)',
      marginLeft: 'auto',
      marginRight: 5,
    },
    studentList: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'scroll',
      padding: theme.spacing(0, 2),
    },
    bulkChatStudent: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      margin: theme.spacing(0.5, 0),
    },
    bulkChatStudentName: {
      flexGrow: 1,
    },
    bulkChatStudentDeleteButton: {
      backgroundColor: theme.palette.grey[100],
    },
    bulkChatNotice: {
      margin: theme.spacing(2),
      display: 'flex',
      flexDirection: 'row',
      userSelect: 'none',
    },
    bulkChatNoticeText: {
      display: 'block',
      fontSize: theme.typography.caption.fontSize,
      color: theme.palette.grey[700],
    },
    bulkChatNoticeButton: {
      whiteSpace: 'nowrap',
      backgroundColor: theme.palette.grey[100],
      flexShrink: 0,
      marginLeft: theme.spacing(1),
    },
    dateDivider: {
      margin: theme.spacing(1, 2),
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'stretch',
    },
    dividerRule: {
      backgroundColor: theme.palette.grey[200],
      height: 1,
      flexGrow: 1,
    },
    dividerDate: {
      fontSize: 12,
      color: 'rgba(0, 0, 0, .54)',
      margin: theme.spacing(0, 1),
      userSelect: 'none',
    },
  }),
)

enum Sender {
  Jobseeker = 'jobseeker',
  Company = 'company',
}

type Message = {
  id: number
  scoutId: number
  createdAt: string
  isRead: boolean
  message: string
  kind: string
  sender: Sender
}

type ChatRoom = {
  unreadCount: number
  lastMessage: string
  lastMessageAt: string
}

type Chat = {
  id: number
  isApproved: boolean
  chatRoom: ChatRoom
  companyId: string
  jobseeker: any
  schoolName: string
  departmentGroupId: number
}

type ChatListProps = {
  chats: Chat[]
  setChat: React.Dispatch<Chat['id']>
}

const chatsDescending = (a: Chat, b: Chat) => (a.chatRoom.lastMessageAt < b.chatRoom.lastMessageAt ? 1 : -1)

const ChatList: React.FC<ChatListProps> = (props) => {
  const classes = useStyles(props)
  const chats = [
    ...props.chats.filter((chat) => chat.chatRoom.unreadCount !== 0).sort(chatsDescending),
    ...props.chats.filter((chat) => chat.chatRoom.unreadCount === 0).sort(chatsDescending),
  ]
  return (
    <div className={classes.chatList}>
      {chats.map((chat) => (
        <ChatListItem key={chat.id} chat={chat} onClick={() => props.setChat(chat.id)} />
      ))}
    </div>
  )
}

type ChatListItemProps = {
  chat: Chat
  onClick: () => void
}

const ChatListItem: React.FC<ChatListItemProps> = (props) => {
  const classes = useStyles()
  const { data: departmentGroups } = useSWR(`/department_groups`)
  let { unreadCount } = props.chat.chatRoom
  const unread = unreadCount > 99 ? '99+' : unreadCount
  const { lastMessage } = props.chat.chatRoom
  const { isAdmin } = useSession()

  const getDepartmentGroupName = (departmentGroupId: number) => {
    const departmentGroup = departmentGroups?.departmentGroups.find((division: Division) => division.id === departmentGroupId)
    return departmentGroup?.name
  }

  const name =
    props.chat.isApproved || isAdmin
      ? props.chat.jobseeker.name
      : `${props.chat.jobseeker.schoolName} ${getDepartmentGroupName(props.chat.jobseeker.departmentGroupId)}`

  return (
    <Button className={classes.chatListItem} onClick={props.onClick}>
      <Avatar className={classes.chatAvatar} src={props.chat.jobseeker.iconUrl} />
      <div className={classes.chatDetails}>
        <div className={classes.chatDetailsRow}>
          <Typography className={classes.chatRecipientName} title={name}>
            {name}
          </Typography>
          {props.chat.chatRoom.lastMessageAt && (
            <Typography className={classes.chatDate}>{formattedChatListDate(props.chat.chatRoom.lastMessageAt)}</Typography>
          )}
        </div>
        <div className={classes.chatDetailsRow}>
          <Typography className={classes.chatMessagePreview}>{lastMessage}</Typography>
          {unread !== 0 && <div className={classes.chatUnreadCount}>{unread}</div>}
        </div>
      </div>
    </Button>
  )
}

type ChatMessageProps = {
  chat?: Chat
  message: Message
  deleteMessage?: () => void
}

const ChatMessage: React.FC<ChatMessageProps> = (props) => {
  const classes = useStyles()
  const received = props.message.sender !== Sender.Company
  const approvedByName = props.chat?.jobseeker.name

  return (
    <div className={[classes.chatMessage, received ? classes.chatMessageLeft : classes.chatMessageRight].join(' ')}>
      {props.message.kind === 'approve' ? (
        <div className={classes.chatNotificationBody}>{approvedByName}さんがスカウトを承認しました</div>
      ) : (
        <>
          {received && <Avatar className={classes.chatMessageAvatar} src={props.chat?.jobseeker.iconUrl} />}
          <div className={classes.chatMessageBody}>
            {props.message.message.split('\n').reduce<any>((a, v) => a.concat(v, <br key={a.length} />), [])}
            {!received && props.deleteMessage && (
              <div className={classes.chatMessageHover} onClick={props.deleteMessage}>
                <div>
                  <CancelIcon />
                  &nbsp;送信取り消し
                </div>
              </div>
            )}
          </div>
          <Typography className={classes.chatMessageTimestamp}>
            {`${props.message.isRead && props.message.sender === 'company' && props.chat?.isApproved ? '既読' : ''}
            ${props.message.createdAt && format(parseISO(props.message.createdAt), 'HH:mm')}`}
          </Typography>
        </>
      )}
    </div>
  )
}

type ComposerProps = {
  message: string
  setMessage: React.Dispatch<React.SetStateAction<string>>
  sendMessage: () => void
}

const useCommandKey = () => {
  const [pressed, setPressed] = useState(false)

  useEffect(() => {
    const codes = [224, 17, 91, 93] // Opera, Firefox, Webkit (left/right)

    const handler = (state: boolean) => (e: KeyboardEvent) => {
      if (codes.includes(e.keyCode)) {
        setPressed(state)
      }
    }

    const keydown = handler(true)
    const keyup = handler(false)

    document.addEventListener('keydown', keydown)
    document.addEventListener('keyup', keyup)

    return () => {
      document.removeEventListener('keydown', keydown)
      document.removeEventListener('keyup', keyup)
    }
  })

  return () => pressed
}

const Composer: React.FC<ComposerProps> = (props) => {
  const classes = useStyles()
  const { message, setMessage, sendMessage } = props
  const [templateDialogOpen, setTemplateDialogOpen] = useState(false)
  const [showTemplateDialogWarning, setShowTemplateDialogWarning] = useState(false)
  const inputRef = useRef<HTMLInputElement>(null)

  const getCommandPressed = useCommandKey()

  const handleCloseTemplateDialog = () => {
    setTemplateDialogOpen(false)
  }

  const dismissTemplateDialogWarning = () => {
    setShowTemplateDialogWarning(false)
  }

  const showTemplateDialog = () => {
    setShowTemplateDialogWarning(message.length > 0)
    setTemplateDialogOpen(true)
  }

  const insertChatTemplate = (message: string) => {
    setTemplateDialogOpen(false)
    setMessage(message)
    setTimeout(() => {
      if (inputRef?.current) {
        inputRef.current.focus()
      }
    }, 0)
  }

  const handleReturnKeyPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.key === 'Enter' && getCommandPressed()) {
      e.preventDefault()
      sendMessage()
    }
  }

  return (
    <>
      <Dialog open={templateDialogOpen} onClose={handleCloseTemplateDialog} maxWidth={false}>
        {showTemplateDialogWarning ? (
          <>
            <DialogTitle>入力中のメッセージ</DialogTitle>

            <DialogContent>
              <DialogContentText>
                入力中のメッセージがあります。
                <br />
                チャットテンプレートを選択すると、入力中のメッセージは、
                <br />
                上書きされます。続けますか？
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleCloseTemplateDialog} color="secondary">
                戻る
              </Button>
              <Button onClick={dismissTemplateDialogWarning} color="primary">
                テンプレートの選択へ
              </Button>
            </DialogActions>
          </>
        ) : (
          <TemplateList insertChatTemplate={insertChatTemplate} closeDialog={() => setTemplateDialogOpen(false)} />
        )}
      </Dialog>

      <div className={classes.chatMessageComposer}>
        <div className={classes.chatMessageComposerHeader}>
          <IconButton size="small" onClick={showTemplateDialog}>
            <MenuBookIcon />
          </IconButton>
          <Button className={classes.chatMessageComposerSend} color="primary" onClick={sendMessage}>
            送信
            <SendIcon />
          </Button>
        </div>
        <InputBase
          inputRef={inputRef}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={handleReturnKeyPress}
          className={classes.chatMessageComposerInput}
          multiline
          rowsMax={7}
        />
      </div>
    </>
  )
}

const formattedDividerDate: (dateString: string) => string = (dateString) => {
  const date = parseISO(dateString)
  const now = new Date()
  const today = isToday(date) && '今日'
  const yesterday = isYesterday(date) && '昨日'
  if (getYear(date) !== getYear(now)) {
    return format(date, 'yyyy/M/d (EEEEEE)', { locale: ja })
  }
  return today || yesterday || format(date, 'M/d (EEEEEE)', { locale: ja })
}

const formattedChatListDate: (dateString: string) => string = (dateString) => {
  const date = parseISO(dateString)
  const now = new Date()
  const today = isToday(date) && format(date, 'HH:mm')
  const yesterday = isYesterday(date) && '昨日'
  if (getYear(date) !== getYear(now)) {
    return format(date, 'yyyy/M/d', { locale: ja })
  }
  return today || yesterday || format(date, 'M/d', { locale: ja })
}

const useRerenderOnDayChange = () => {
  const [, setState] = useState()
  const setupTimer = useCallback(() => {
    const msUntilTomorrow = differenceInMilliseconds(startOfTomorrow(), new Date())
    const timer = setTimeout(() => {
      setState({})
      setupTimer()
    }, msUntilTomorrow)

    return () => {
      clearInterval(timer)
    }
  }, [])
  useEffect(setupTimer, [])
}

type DateDividerProps = {
  text: string
}

const DateDivider: React.FC<DateDividerProps> = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.dateDivider}>
      <div className={classes.dividerRule} />
      <Typography className={classes.dividerDate}>{props.text}</Typography>
      <div className={classes.dividerRule} />
    </div>
  )
}

type NewChat = {
  studentId: number
  name: string
  setChatId: (id: number) => void
} | null

type ChatProps = {
  chat?: Chat
  newChat: NewChat
  view: ChatView
  clientId: number
  invalidateChats: () => void
}

const Chat: React.FC<ChatProps> = (props) => {
  const [message, setMessage] = useState('')
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [deleteId, setDeleteId] = useState<number>()
  const { setSnackbarMessage } = useContext(SnackbarContext)
  const { isAdmin, id: userId } = useSession()
  const classes = useStyles(props)
  const endpoint = isAdmin ? 'admins' : 'companies'
  const adminViewingClient = isAdmin && props.view === ChatView.Company
  const app = useAppContext()
  const chatEndpoint = props.chat
    ? adminViewingClient
      ? `/admins/companies/${props.clientId}/scouts/${props.chat.id}/chats`
      : `/${endpoint}/scouts/${props.chat.id}/chats`
    : null
  const { data: chat, error } = useSWR(chatEndpoint, { revalidateOnFocus: false })
  const { lastJsonMessage, sendMessage } = useSubscription()
  const messages = error ? [] : chat?.slice().reverse()

  useRerenderOnDayChange()

  // Clear message on chat change
  useEffect(() => {
    setMessage('')
  }, [props.chat, props.newChat])

  // Subscribe to chat message subscription
  useEffect(() => {
    if (props.chat) {
      sendMessage(`company:${userId}`)
    }
  }, [props.chat])

  // Handle subscription messages
  useEffect(() => {
    if (props.chat && chat && lastJsonMessage) {
      if (lastJsonMessage.type === 'jobseeker') {
        let newChat = [
          ...chat,
          {
            createdAt: lastJsonMessage.send_at,
            id: tempId++,
            is_read: true,
            message: lastJsonMessage.message,
            scout_id: lastJsonMessage.room_id,
            sender: 'jobseeker',
          },
        ]
        mutate(chatEndpoint, newChat, false)
        return
      }

      if (lastJsonMessage.type === 'read') {
        mutate(
          `/${endpoint}/scouts/${props.chat.id}/chats`,
          chat.map((message: Message) => ({
            ...message,
            isRead: true,
          })),
          false,
        )
        return
      }
    }
  }, [lastJsonMessage])

  // Mark chat as read
  const markAsRead = useCallback(async () => {
    if (props.chat && !isAdmin) {
      await caxios.patch(`/${endpoint}/scouts/${props.chat.id}/chats/read`)
      // Invalidate chat list
      mutate(`/${endpoint}/scouts`)
    }
  }, [props.chat])

  useEffect(() => {
    markAsRead()
  }, [props.chat])

  const sendChatMessage = async () => {
    if (!message.length || !props.chat) return

    setMessage('')
    // Send the message
    const res = await caxios.post(`/${endpoint}/scouts/chats`, {
      jobseekerId: props.chat.jobseeker.id,
      scoutId: props.chat.id,
      message,
    })
    // Append the new message to the SWR resource
    mutate(`/${endpoint}/scouts/${props.chat.id}/chats`, res.data)
    // Also invalidate the chat list
    mutate(`/${endpoint}/scouts`)
  }

  const sendNewChatMessage = async () => {
    if (!message.length || !props.newChat) {
      return
    }
    setMessage('')
    const res = await caxios.post(`/${endpoint}/scouts/chats`, {
      jobseekerId: props.newChat.studentId,
      scoutId: 0,
      message,
    })

    // Update the new chat endpoint with the response data
    mutate(`/${endpoint}/scouts/${res.data[0].scoutId}/chats`, res.data)

    // We've sent the chat, so invalidate the jobseeker endpoint
    // This will show the selection phase "承認待ち" in StudentDetail
    mutate(`/${endpoint}/jobseekers/${props.newChat.studentId}`)

    // Now, fetch new scouts list before setting the chat ID to prevent
    // flashing to the chat list
    const chatsRes = await caxios.get(`/${endpoint}/scouts`)
    const filterParameter = app.state.selectedYear ? 'academic_year' : 'school_division_id'
    const filterValue = app.state.selectedYear || app.state.selectedDivision
    mutate(`/${endpoint}/scouts?${filterParameter}=${filterValue}`, chatsRes.data)

    // Now it's safe to set the AppChat chatId without any ill effect
    props.newChat.setChatId(res.data[0].scoutId)
  }

  const showDeleteDialog = (messageId: number) => () => {
    setDeleteDialogOpen(true)
    setDeleteId(messageId)
  }

  const deleteMessage = async () => {
    if (!props.chat) {
      return
    }
    await caxios.delete(`/${endpoint}/scouts/${props.chat.id}/chats/${deleteId}`)
    mutate(`/${endpoint}/scouts/${props.chat.id}/chats`)
    mutate(`/${endpoint}/scouts`)
    setDeleteDialogOpen(false)
    setSnackbarMessage('メッセージの送信を取り消しました')
  }

  const handleCloseDeleteDialog = () => {
    setDeleteDialogOpen(false)
  }

  const send = async () => {
    if (props.chat) {
      await sendChatMessage()
    } else if (props.newChat) {
      await sendNewChatMessage()
    }
    props.invalidateChats()
  }

  return (
    <div className={classes.chat}>
      {!adminViewingClient && (
        <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
          <DialogTitle>送信取り消し</DialogTitle>

          <DialogContent>
            <DialogContentText>
              このメッセージの送信を取り消しますか？
              <br />
              学生のチャット画面からは、メッセージが消えます。
              <br />
              この操作は取り消せません。
            </DialogContentText>
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCloseDeleteDialog} color="secondary">
              戻る
            </Button>
            <Button onClick={deleteMessage} color="primary">
              送信取り消し
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <div className={classes.messageList}>
        {messages?.map((message: Message, index: number) => {
          const prevMessage = message
          const nextMessage = messages[index + 1]
          const prevDate = prevMessage && formattedDividerDate(prevMessage.createdAt)
          const nextDate = nextMessage && formattedDividerDate(nextMessage.createdAt)
          return (
            <React.Fragment key={message.id}>
              <ChatMessage
                chat={props.chat}
                message={message}
                deleteMessage={isAdmin && props.view === ChatView.Normal ? showDeleteDialog(message.id) : undefined}
              />
              {prevDate !== nextDate && <DateDivider text={prevDate} />}
            </React.Fragment>
          )
        })}
      </div>

      {!adminViewingClient && <Composer message={message} setMessage={setMessage} sendMessage={send} />}
    </div>
  )
}

type AdminHeaderProps = {
  view: ChatView
  setView: (v: ChatView) => void
  client: any
  closeAdminHeader: () => void
  closeChat: () => void
}

const AdminHeader: React.FC<AdminHeaderProps> = (props) => {
  const classes = useStyles()
  const setView = (v: ChatView) => () => {
    props.setView(v)
    props.closeChat()
  }
  return (
    <div className={classes.adminHeader}>
      {props.view === ChatView.Company ? (
        <>
          <Avatar className={classes.adminHeaderIconButton} src="/joburu.svg" onClick={setView(ChatView.Normal)} />
          <div className={classes.adminHeaderSelected}>
            <Avatar className={classes.adminHeaderIcon} src={props.client.iconUrl} />
            <SectionTitle className={classes.adminHeaderTitle}>{props.client.name}</SectionTitle>
            <CloseIcon className={classes.adminHeaderCloseButton} onClick={props.closeAdminHeader} />
          </div>
        </>
      ) : (
        <>
          <div className={classes.adminHeaderSelected}>
            <Avatar className={classes.adminHeaderIcon} src="/joburu.svg" />
            <SectionTitle className={classes.adminHeaderTitle}>株式会社Be win</SectionTitle>
          </div>
          <Avatar className={classes.adminHeaderIconButton} src={props.client.iconUrl} onClick={setView(ChatView.Company)} />
        </>
      )}
    </div>
  )
}

type BulkChatStudentProps = {
  student: any
  deleteStudent: (id: number) => void
}

const BulkChatStudent: React.FC<BulkChatStudentProps> = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.bulkChatStudent}>
      <Avatar className={classes.chatAvatar} src={props.student.jobseeker.iconUrl} />
      <Typography className={classes.bulkChatStudentName}>{props.student.jobseeker.name}</Typography>
      <IconButton onClick={() => props.deleteStudent(props.student.id)} className={classes.bulkChatStudentDeleteButton} size="small">
        <CloseIcon />
      </IconButton>
    </div>
  )
}

type BulkChatProps = {
  closeBulkChat: () => void
}

const BulkChat: React.FC<BulkChatProps> = (props) => {
  const app = useAppContext()
  const classes = useStyles()
  const { bulkChatStudents } = app.state.chat
  const [message, setMessage] = useState('')

  const deleteStudent = (id: number) => {
    app.dispatch({ type: 'REMOVE_FROM_BULK_CHAT', payload: { id } })
  }

  const sendMessage = async () => {
    props.closeBulkChat()
    await caxios.post(`/admins/scouts/bulk`, {
      jobseekerIds: bulkChatStudents.map((s: any) => s.jobseekerId),
      message,
    })
    mutate(`/admins/scouts/bulk`)
    // Reload the chat list
    mutate(`/admins/scouts`)
  }

  return (
    <div className={classes.chat}>
      <div className={classes.bulkChatNotice}>
        <Typography className={classes.bulkChatNoticeText} variant="body2">
          以下のユーザに、同じメッセージを一括で送信します。
        </Typography>

        <Button className={classes.bulkChatNoticeButton} onClick={props.closeBulkChat} variant="contained" size="small" disableElevation>
          キャンセル
        </Button>
      </div>

      <div className={classes.studentList}>
        {bulkChatStudents?.length > 0 &&
          bulkChatStudents.map((student: any) => <BulkChatStudent key={student.id} student={student} deleteStudent={deleteStudent} />)}
      </div>

      <Composer message={message} setMessage={setMessage} sendMessage={sendMessage} />
    </div>
  )
}

const HeaderButton: React.FC<IconButtonProps> = (props) => {
  const classes = useStyles()
  return (
    <Button className={classes.headerButton} {...props} disableRipple>
      <ChevronLeftIcon />
    </Button>
  )
}

export const AppChat: React.FC = () => {
  const app = useAppContext()
  const [chatId, setChatId] = useState<Chat['id'] | null>()
  const { open, studentId, client, chatId: newChatId, name } = app.state.chat
  const [newChat, setNewChat] = useState<NewChat>(null)
  const [view, setView] = useState<ChatView>(client ? ChatView.Company : ChatView.Normal)
  const { id, isAdmin } = useSession()
  const showAdminHeader = isAdmin && client
  const classes = useStyles({ showAdminHeader })
  const endpoint = isAdmin ? 'admins' : 'companies'
  const filterParameter = app.state.selectedYear ? 'academic_year' : 'school_division_id'
  const filterValue = app.state.selectedYear || app.state.selectedDivision
  const scoutsEndpoint = `/${endpoint}/scouts?${filterParameter}=${filterValue}`
  const { data: chats, mutate: invalidateChats } = useSWR(scoutsEndpoint, { revalidateOnFocus: false })
  const { data: companyChats } = useSWR(
    client && view === ChatView.Company ? `/admins/companies/${client.id}/scouts?${filterParameter}=${filterValue}` : null,
    { revalidateOnFocus: false },
  )
  const { data: departmentGroups } = useSWR(`/department_groups`)
  const dispatch = useDispatch()
  const { lastJsonMessage, sendMessage } = useSubscription()

  useEffect(() => {
    sendMessage(`company:${id}`)

    const pingInterval = setInterval(() => {
      sendMessage('ping')
    }, 1000 * 30)

    return () => {
      clearInterval(pingInterval)
    }
  }, [])

  useEffect(() => {
    if (lastJsonMessage?.type === 'jobseeker') {
      let newChats = chats
      newChats = newChats.map((chat: Chat) => {
        if (Number(chat.id) === Number(lastJsonMessage.room_id)) {
          return {
            ...chat,
            chatRoom: {
              unreadCount: chat.chatRoom.unreadCount + 1,
              lastMessage: lastJsonMessage.message,
              lastMessageAt: lastJsonMessage.send_at,
            },
          }
        }
        return chat
      })
      newChats = newChats.sort(chatsDescending)
      mutate(scoutsEndpoint, newChats, false)
    }
  }, [lastJsonMessage])

  useEffect(() => {
    if (studentId) {
      setView(ChatView.Normal)
      dispatch({
        type: 'OPEN_CHAT',
        payload: {},
      })

      const findChat = chats?.find((chat: Chat) => Number(chat.jobseeker.id) === Number(studentId))
      if (findChat) {
        setChatId(findChat.id)
        return
      }

      if (newChatId) {
        setChatId(newChatId)
        return
      }

      if (studentId) {
        setChatId(null)
        setNewChat({
          studentId: Number(studentId),
          name,
          setChatId: (id: number) => {
            setNewChat(null)
            setChatId(id)
          },
        })
      }
    }
  }, [studentId])

  useEffect(() => {
    if (client) {
      setChatId(null)
      setView(ChatView.Company)
    }
  }, [client])

  const activeChats = view === ChatView.Normal ? chats : companyChats
  const chat = chatId && activeChats?.find((c: Chat) => c.id === chatId)

  const unreadCount = chats?.reduce((numUnread: number, chat: Chat) => {
    return numUnread + chat.chatRoom?.unreadCount
  }, 0)

  const toggleOpen = () => app.dispatch({ type: 'TOGGLE_CHAT_OPEN' })
  const closeChat = (event?: React.MouseEvent) => {
    if (event) {
      event.stopPropagation()
    }
    setChatId(null)
    setNewChat(null)
  }

  const closeBulkChat = (e?: React.MouseEvent) => {
    if (e) {
      e.stopPropagation()
    }
    setChatId(null)
    setNewChat(null)
    dispatch({ type: 'REMOVE_ALL_BULK_CHAT' })
  }

  const bulkChatStudents = app.state.chat.bulkChatStudents
  const showBulkChat = bulkChatStudents?.length

  const closeAdminHeader = () => {
    dispatch({ type: 'HIDE_CLIENT_CHAT', payload: { client } })
    setView(ChatView.Normal)
  }

  const getDepartmentGroupName = (departmentGroupId: number) => {
    const departmentGroup = departmentGroups?.departmentGroups.find((division: Division) => division.id === departmentGroupId)
    return departmentGroup?.name
  }

  const hasInvitationBeenAccepted = chat?.isApproved
  const chatTitle =
    chat &&
    (hasInvitationBeenAccepted || isAdmin
      ? chat.jobseeker.name
      : `${chat.jobseeker.schoolName} ${getDepartmentGroupName(chat.jobseeker.departmentGroupId)}`)

  return (
    <div className={classes.root}>
      {showAdminHeader && (
        <AdminHeader view={view} setView={setView} client={client} closeAdminHeader={closeAdminHeader} closeChat={closeChat} />
      )}
      <Paper className={classes.paper} elevation={8}>
        <Paper className={classes.header} onClick={toggleOpen} elevation={0}>
          <div className={classes.headerInner}>
            {showBulkChat ? (
              <>
                <HeaderButton onClick={closeBulkChat} />
                {unreadCount > 0 && <div className={classes.unreadCount}>{unreadCount}</div>}
                <SectionTitle className={classes.headerTitle}>一括スカウト ({bulkChatStudents.length}名)</SectionTitle>
              </>
            ) : chat ? (
              <>
                <HeaderButton onClick={closeChat} />
                <SectionTitle className={classes.headerTitle}>
                  <Link
                    to={`/students/${chat.jobseeker.id}`}
                    onClick={(e: React.MouseEvent) => e.stopPropagation()}
                    className={classes.headerLink}
                  >
                    {chatTitle}
                  </Link>
                </SectionTitle>
              </>
            ) : newChat ? (
              <>
                <HeaderButton onClick={closeChat} />
                <SectionTitle className={classes.headerTitle}>{newChat.name}</SectionTitle>
              </>
            ) : (
              <>
                <SectionTitle className={classes.headerTitle}>チャット</SectionTitle>
                {unreadCount > 0 && <div className={classes.unreadCount}>{unreadCount}</div>}
              </>
            )}
          </div>
          <ExpandButton expanded={open} />
        </Paper>
        <Collapse in={open}>
          <div className={classes.chatMainContent}>
            {showBulkChat ? (
              <BulkChat closeBulkChat={closeBulkChat} />
            ) : chat || newChat ? (
              <Chat chat={chat} newChat={newChat} view={view} clientId={client?.id} invalidateChats={invalidateChats} />
            ) : (
              activeChats && <ChatList chats={activeChats} setChat={setChatId} />
            )}
          </div>
        </Collapse>
      </Paper>
    </div>
  )
}
