import React, { Dispatch, SetStateAction, useContext, useState } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

import {
  Paper,
  Button,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core'
import { Field, Props as FieldProps } from '@/components/molecules/field'
import SearchIcon from '@material-ui/icons/Search'
import CloseIcon from '@material-ui/icons/Close'
import { ConditionList } from './condition-list'
import { SnackbarContext } from '@/providers/snackbar-provider'
import { useSession } from '@/hooks/use-session'
import { caxios } from '@/submodules/custom-axios'
import { Value } from '@/components/molecules/field'
import { APP_HEADER_HEIGHT } from '../app-header'
import useSWR from 'swr'

type Props = {
  filter: FieldProps[]
  setFilter: Dispatch<SetStateAction<any>>
  onSearch: () => void
  onStartBulkChat: () => void
}

export interface Row {
  id: number
  name: string
  condition: string
  queryParams: string
}

const searchHeight = 120

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'fixed',
      top: APP_HEADER_HEIGHT,
      bottom: 0,
      overflowY: 'scroll',
      '&::-webkit-scrollbar': {
        display: 'none',
      },
    },
    paper: {
      width: 300,
      position: 'relative',
      gridArea: 'sidebar',
      marginTop: theme.spacing(3),
      marginBottom: searchHeight + theme.spacing(2),
      '& $field $button': {
        width: '100%',
        marginTop: '0px',
      },
    },
    field: {
      padding: theme.spacing(2.5),
      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
      },
      '& .MuiFormGroup-root': {
        flexDirection: 'row',
      },
    },
    search: {
      width: 300,
      height: searchHeight,
      padding: theme.spacing(2.5),
      zIndex: 100,
      position: 'fixed',
      bottom: 0,
    },
    searchButtons: {
      display: 'flex',
    },
    searchButton: {
      width: '55%',
      marginRight: theme.spacing(1),
      flexGrow: 1,
    },
    searchLinks: {
      marginTop: theme.spacing(2),
      textAlign: 'center',
      '& > *': {
        margin: theme.spacing(0, 1),
        display: 'inline-block',
      },
    },
    link: {
      cursor: 'pointer',
    },
    dialog: {
      margin: 'auto',
      userSelect: 'none',
      whiteSpace: 'pre-wrap',
    },
    warningDialogContext: {
      width: 500,
    },
    savedDialog: {
      userSelect: 'none',
    },
    dialogTitle: {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      textAlign: 'center',
    },
    dialogCloseButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      margin: theme.spacing(1),
    },
    dialogContent: {
      margin: theme.spacing(2),
      width: 360,
    },
    input: {
      width: '100%',
    },
    button: {
      display: 'block',
      marginLeft: 'auto',
      marginTop: theme.spacing(3),
    },
  }),
)

export const ScoutFilter = (props: Props) => {
  const { isAdmin } = useSession()
  const classes = useStyles()
  const { filter, setFilter } = props
  const { setSnackbarMessage } = useContext(SnackbarContext)
  const [saveDialogOpen, setSaveDialogOpen] = useState(false)
  const [showSaveDialogWarning, setShowSaveDialogWarning] = useState(false)
  const [savedDialogOpen, setSavedDialogOpen] = useState(false)

  const openSaveDialog = async () => {
    let condQueryUrlBase = isAdmin ? `/admins/staffs` : `/companies/personnels`
    caxios.get(`${condQueryUrlBase}/jobseeker_search_conditions`).then((conds) => {
      setShowSaveDialogWarning(false)
      if (conds.data.jobseekerSearchConditions?.length >= 10) setShowSaveDialogWarning(true)
      setSaveDialogOpen(true)
    })
  }
  const closeSaveDialog = () => setSaveDialogOpen(false)
  const openSavedDialog = () => setSavedDialogOpen(true)
  const closeSavedDialog = () => setSavedDialogOpen(false)

  const clearFields = () => {
    const fields = filter.map((f) => {
      let value: string | string[]
      if (Array.isArray(f.value)) {
        value = []
      } else {
        value = ''
      }
      return {
        ...f,
        value,
      }
    })
    setFilter(fields)
  }

  const handleChange = (fieldName: string) => (value: string | string[]) => {
    const fields = filter.map((f) => {
      if (f.name === fieldName) {
        return {
          ...f,
          value,
        }
      }
      return f
    })
    setFilter(fields)
  }

  const loadConditions = (rowData: Row) => {
    closeSavedDialog()
    clearFields()
    const params = JSON.parse(rowData?.queryParams)
    if (!params) {
      return
    }
    let fields = filter
    params.forEach((param: any) => {
      let { value } = param
      fields = fields.map((f) => {
        if (f.name === param.name) {
          return {
            ...f,
            value,
          }
        }
        return f
      })
    })
    setFilter(fields)
  }

  const saveConditions = () => {
    const queryUrlBase = isAdmin ? `/admins/staffs` : `/companies/personnels`
    const serializedConds = JSON.stringify(filter)

    const params = {
      name: condName,
      condition: getSelectedConditionString(),
      query_params: serializedConds,
    }

    caxios.post(`${queryUrlBase}/jobseeker_search_conditions`, params).then(() => {
      closeSaveDialog()
      setSnackbarMessage('検索条件を保存しました')
    })
  }

  const dynamicOptions = {}
  props.filter
    .filter((field) => field.dynamicOptions)
    .forEach((field) => {
      /* eslint-disable react-hooks/rules-of-hooks */
      const dynamicOptionsSWR = useSWR(field.dynamicOptions?.endpoint as string, {
        revalidateOnFocus: false,
      })
      dynamicOptions[field.name] = dynamicOptionsSWR?.data && field.dynamicOptions?.resolve(dynamicOptionsSWR.data)
      /* eslint-enable react-hooks/rules-of-hooks */
    })

  const getSelectedConditionString = () => {
    const selectedConditions = filter.filter((cond) => cond.value && String(cond.value).length)
    return selectedConditions
      .map((cond) => {
        const valueString = getConditonValueString(cond.value, cond.name, cond.label)
        return `${cond.label}：${valueString}`
      })
      .join(' / ')
  }

  const getConditonValueString = (condValue: Value | undefined, condName: string, condLabel: string) => {
    if (typeof condValue === 'object')
      return condValue.map((condVal) => dynamicOptions[condName].find((optVal) => optVal.value === condVal).label).join(', ')
    if (typeof condValue === 'boolean') return condValue ? condLabel ?? 'true' : 'false'
    return condValue
  }

  const [condName, setCondName] = useState<string>('')
  const saveCondField = {
    name: 'name',
    type: 'text',
    onChange: setCondName,
    label: '条件名',
    validation: {
      required: '条件名を入力してください',
    },
  }

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} variant="outlined">
        <div className={classes.field}>
          <Button
            className={classes.button}
            variant="contained"
            disableElevation
            size="medium"
            color="primary"
            onClick={props.onStartBulkChat}
          >
            一斉チャット作成
          </Button>
        </div>
        {filter.map((field) => (
          <div key={field.name} className={classes.field}>
            <Field {...field} onChange={handleChange(field.name)} variant="outlined" labelStyle="above" />
          </div>
        ))}
      </Paper>

      <Paper className={classes.search} variant="outlined">
        <div className={classes.searchButtons}>
          <Button
            className={classes.searchButton}
            variant="contained"
            disableElevation
            size="medium"
            color="primary"
            startIcon={<SearchIcon />}
            onClick={props.onSearch}
          >
            検索
          </Button>

          <Button variant="outlined" size="medium" onClick={clearFields}>
            リセット
          </Button>
        </div>

        <div className={classes.searchLinks}>
          <div className={classes.link} onClick={openSaveDialog}>
            <Typography>条件を保存</Typography>
          </div>
          <div className={classes.link} onClick={openSavedDialog}>
            <Typography color="primary">保存した条件</Typography>
          </div>
        </div>
      </Paper>

      <Dialog onClose={closeSaveDialog} open={saveDialogOpen} className={classes.dialog} maxWidth={false}>
        {showSaveDialogWarning ? (
          <>
            <DialogTitle>保存件数の上限</DialogTitle>

            <DialogContent className={classes.warningDialogContext}>
              <DialogContentText>{'検索条件を保存できるのは、10件までです。\n不要な条件を削除してください。'}</DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={closeSaveDialog} color="secondary">
                OK
              </Button>
            </DialogActions>
          </>
        ) : (
          <>
            <IconButton aria-label="Close" className={classes.dialogCloseButton} onClick={closeSaveDialog}>
              <CloseIcon />
            </IconButton>

            <DialogTitle className={classes.dialogTitle}>検索条件の保存</DialogTitle>

            <DialogContent className={classes.dialogContent}>
              <DialogContentText>条件名を入力してください</DialogContentText>
              <Field {...saveCondField} variant="outlined" fullWidth className={classes.input} />

              <Button className={classes.button} size="large" color="primary" variant="contained" disableElevation onClick={saveConditions}>
                保存する
              </Button>
            </DialogContent>
          </>
        )}
      </Dialog>

      <Dialog onClose={closeSavedDialog} open={savedDialogOpen} className={classes.savedDialog} maxWidth={false}>
        <ConditionList
          closeDialog={() => setSavedDialogOpen(false)}
          onLoadConditions={loadConditions}
          onSetSnackbarMessage={setSnackbarMessage}
        />
      </Dialog>
    </div>
  )
}
