import React from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Link } from '@/components/atoms/link'
import { Text } from '@/components/atoms/text'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      alignSelf: 'stretch',
      justifySelf: 'flex-end',
      marginTop: 'auto',
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      display: 'flex',
      padding: theme.spacing(1.5, 0),
      fontSize: theme.typography.caption.fontSize,
      '& > p': {
        marginRight: 'auto',
        color: theme.palette.grey[500],
      },
      '& > a': {
        marginLeft: theme.spacing(2),
      },
    },
  }),
)

export function LoginFooter() {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Text>© Bewin Co., Ltd. All Rights Reserved.</Text>
      {/* <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_FAQ_URL}>
        よくある質問
      </a> */}
      <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_PRIVACY_POLICY_URL}>
        個人情報保護方針
      </a>
      <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_TOS_URL}>
        利用規約
      </a>
      <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_COMPANY_PROFILE_URL}>
        会社概要
      </a>
    </div>
  )
}
