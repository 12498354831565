import React from 'react'
import { StudentDetailContainer } from '@/components/containers/student-detail'
import { useTitle } from '@/hooks/use-title'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import PeopleIcon from '@material-ui/icons/People'
import { useSession } from '@/hooks/use-session'

export const StudentDetailPage: React.FC = () => {
  const { isAdmin } = useSession()
  const primaryPathName = window.location.pathname.split('/')[1]
  const isScout = primaryPathName === 'scout'
  const getTitleBranch = () => {
    if (isScout) return 'スカウト'
    if (isAdmin) return '学生一覧'
    if (!isAdmin) return '応募者一覧'
  }

  const titleBranch = getTitleBranch()

  useTitle(`${titleBranch} / 学生詳細`, isScout ? <AssignmentIndIcon /> : <PeopleIcon />)
  return <StudentDetailContainer isScout={isScout} />
}
