import React, { useState, useCallback, useEffect } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Paper, Button } from '@material-ui/core'
import { Field } from '@/components/molecules/field'
import { useParams } from 'react-router-dom'
import useSWR, { mutate } from 'swr'
import { caxios } from '@/submodules/custom-axios'
import { useHistory } from 'react-router-dom'
import { NotFoundErrorPage } from '@/components/pages/not-found-error'
import { useSession } from '@/hooks/use-session'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      padding: theme.spacing(4, 12),
      minWidth: 742,
    },
    field: {
      '& textarea': {
        height: 339,
      },
    },
    templateForm: {
      margin: 'auto',
      width: '100%',
      display: 'grid',
      gap: theme.spacing(2) + 'px',
      flexDirection: 'column',
    },
    saveButton: {
      marginTop: theme.spacing(2),
      marginLeft: 'auto',
    },
  }),
)

export const ChatTemplateEditContainer: React.FC = (props) => {
  const classes = useStyles()
  const { id } = useParams()
  const [title, setTitle] = useState('')
  const [message, setMessage] = useState('')
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { isAdmin } = useSession()
  const endpoint = isAdmin ? 'admins' : 'companies'
  const { data } = useSWR(`/${endpoint}/chat_templates`)
  const history = useHistory()
  const isCreatePage = id === 'add'

  useEffect(() => {
    if (data === undefined) {
      return
    }
    setLoading(false)
    const template = data?.find((e) => String(e.id) === String(id))
    if (template) {
      setTitle(template.title)
      setMessage(template.message)
    } else if (!isCreatePage) {
      setError(true)
    }
  }, [data, id])

  if (loading) {
    return null
  }

  if (error) {
    return <NotFoundErrorPage />
  }

  const fields = {
    title: {
      name: 'title',
      label: 'タイトル',
      value: title,
      onChange: (t: string) => setTitle(t.slice(0, 50)),
    },
    message: {
      name: 'message',
      label: 'メッセージ',
      value: message,
      onChange: (m: string) => setMessage(m.slice(0, 2500)),
    },
  }

  const saveAndReturn = async () => {
    let res: { data: object }
    if (isCreatePage) {
      res = await caxios.post(`/${endpoint}/chat_templates`, {
        title,
        message,
      })
    } else {
      res = await caxios.patch(`/${endpoint}/chat_templates/${id}`, {
        title,
        message,
      })
    }
    mutate(`/${endpoint}/chat_templates`, res.data)
    history.push('/settings/chat-templates')
  }

  return (
    <Paper className={classes.paper} variant="outlined">
      <div className={classes.templateForm}>
        <Field variant="outlined" {...fields.title} />
        <Field variant="outlined" multiline {...fields.message} className={classes.field} />

        <Button variant="contained" size="large" disableElevation color="primary" className={classes.saveButton} onClick={saveAndReturn}>
          {isCreatePage ? '追加する' : '変更する'}
        </Button>
      </div>
    </Paper>
  )
}
