import React from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { SectionTitle } from '@/components/atoms/section-title'
import { BasicTable, Column } from '@/components/organisms/table'
import { DashCardContainer, DashCard } from '@/components/molecules/dash-card'
import { PageContent } from '@/components/organisms/page-content'
import { Button, Paper, Avatar } from '@material-ui/core'
import { useTitle } from '@/hooks/use-title'
import BusinessIcon from '@material-ui/icons/Business'
import ChatIcon from '@material-ui/icons/Chat'
import { Field } from '@/components/molecules/field'
import { Link, useParams } from 'react-router-dom'
import useSWR from 'swr'
import { useDispatch } from '@/store'
import { fetcherWithConfig } from '@/submodules/swr-fetch'
import { PersonnelInfo } from './personnel-info'
import { AddRepresentativeForm } from './add-representative-form'

interface Row {
  id: number
  name: string
  currentCount: number
  passingCount: number
  passingRate: number
}

const columns: Column<Row>[] = [
  { id: 'name', label: 'フェーズ', numeric: false },
  { id: 'currentCount', label: '対象人数', numeric: true, suffix: '人' },
  { id: 'passingCount', label: '通過人数', numeric: true, suffix: '人' },
  { id: 'passingRate', label: '通過率', numeric: true, suffix: '%' },
]

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageControls: {
      display: 'flex',
      margin: theme.spacing(4, 0),
      justifyContent: 'flex-end',
      '& > *:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
    dashCardContainer: {
      marginTop: theme.spacing(4),
      marginBottom: 0,
    },
    personnelInfo: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      marginBottom: theme.spacing(4),
      padding: theme.spacing(6),
    },
  }),
)

export const ClientDetailContainer: React.FC = (props) => {
  const classes = useStyles()
  const { id } = useParams()
  const { data } = useSWR(`/admins/companies/${id}`)
  const { data: personnel } = useSWR(`/admins/companies/${id}/personnels`, fetcherWithConfig({ disableSnackbar: true }))
  const { data: status, isValidating } = useSWR(`/admins/companies/${id}/status`)
  const dispatch = useDispatch()

  const client = data?.company

  let title = 'クライアント一覧'
  if (client) {
    title += ` / ${client.name}`
  }

  useTitle(title, <BusinessIcon />)

  if (!client || !status) {
    return null
  }

  const phases = status.selectionPhaseStatuses

  const cards = [
    {
      link: 'entry',
      label: 'エントリー',
      value: status.jobEntryCount,
    },
    {
      link: 'info-session',
      label: '説明会申込',
      value: status.companySessionCount,
    },
    {
      link: 'interns',
      label: 'インターン申込',
      value: status.internshipCount,
    },
    {
      link: 'browsed',
      label: '閲覧履歴',
      value: status.browseCount,
    },
    {
      link: 'keep',
      label: 'お気に入り',
      value: status.keepCount,
    },
  ]

  const showClientChat = () => {
    dispatch({ type: 'SHOW_CLIENT_CHAT', payload: { client } })
  }

  return (
    <PageContent>
      <div className={classes.pageControls}>
        <Button variant="contained" disableElevation startIcon={<ChatIcon />} onClick={showClientChat}>
          チャット確認
        </Button>
        <Link to={`${client.id}/plan`}>
          <Button variant="contained" disableElevation>
            プラン・オプション設定
          </Button>
        </Link>
        <a
          href={`${process.env.REACT_APP_BEWIN_OFFICIAL_DOMAIN}yamaguchi/job_articles/${client.latestJobArticle.id}`}
          target="_blank"
          rel="noreferrer noopener"
        >
          <Button variant="contained" disableElevation>
            求人情報を見る
          </Button>
        </a>
      </div>
      <SectionTitle>選考状況</SectionTitle>

      <BasicTable<Row> columns={columns} rows={phases} isValidating={isValidating} />

      <DashCardContainer className={classes.dashCardContainer}>
        {cards.map((card, index) => (
          <DashCard key={index} link={`/clients/${id}/students/${card.link}`} label={card.label} value={`${card.value}件`} />
        ))}
      </DashCardContainer>

      <SectionTitle>担当者情報</SectionTitle>

      {personnel ? <PersonnelInfo personnel={personnel} /> : <AddRepresentativeForm />}
    </PageContent>
  )
}
