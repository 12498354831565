import React, { useEffect, useState, useMemo } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Paper, Avatar, Typography, Button, Select, InputBase, MenuItem } from '@material-ui/core'
import ChatIcon from '@material-ui/icons/Chat'
import { SectionTitle } from '@/components/atoms/section-title'
import { PageContent } from '@/components/organisms/page-content'
import { useParams } from 'react-router-dom'
import { useDispatch } from '@/store'
import { useSession } from '@/hooks/use-session'
import useSWR from 'swr'
import { format, parseISO } from 'date-fns'
import { caxios } from '@/submodules/custom-axios'

export const roundNumList = (list: string[]) => {
  const baseCharCode = '①'.charCodeAt(0)
  if (!list?.length) {
    return ''
  }
  return list
    .map((item, index) => {
      const roundNum = String.fromCharCode(baseCharCode + index)
      return `${roundNum} ${item}`
    })
    .join('\n')
}

export const kanjiList = (list: string[], format: (k: string, v: string) => string) => {
  const toKanjiString = (n: number) => n.toLocaleString('ja-u-nu-hanidec')
  return list.map((v, i) => format(toKanjiString(i + 1), v)).join('\n')
}

const notNull = (v: any) => !!v

type NameWithPriority = {
  name: string
  priority: number
}

type Prioritized = {
  priority: number
}

function sortByPriority<T extends Prioritized>(arr: T[]): T[] {
  return arr.sort((a, b) => (a.priority > b.priority ? 1 : -1))
}

function sortedNames(arr: NameWithPriority[] | null): string[] {
  return sortByPriority(arr ?? []).map((v) => v.name)
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginTop: theme.spacing(4),
    },
    profileHeader: {
      display: 'grid',
      gridAutoFlow: 'row',
      gridTemplateColumns: 'auto 1fr auto',
      alignItems: 'end',
      gap: theme.spacing(2) + 'px',
    },
    profileHeaderText: {
      alignSelf: 'center',
    },
    name: {
      display: 'inline',
    },
    nameKana: {
      ...theme.typography.body2,
    },
    lastLogin: {
      ...theme.typography.body2,
      color: theme.palette.text.secondary,
      marginTop: theme.spacing(1),
    },
    roundButton: {
      borderRadius: 100,
    },
    avatar: {
      width: 180,
      height: 180,
      background: '#64B6F7',
      transition: theme.transitions.create(['filter'], {
        duration: theme.transitions.duration.short,
      }),
    },
    content: {
      display: 'grid',
      gap: theme.spacing(2) + 'px',
      gridTemplateColumns: '1fr 1fr',
      marginTop: theme.spacing(2),
    },
    column: {},
    wideColumn: {
      gridColumn: 'span 2',
      marginTop: theme.spacing(4),
    },
    stat: {
      position: 'relative',
      display: 'flex',
      justifyContent: 'center',
      padding: theme.spacing(4, 3),
    },
    selectionPhase: {
      width: '100%',
      textAlign: 'center',
      margin: theme.spacing(-1),
      '&.Mui-focused .MuiSelect-root': {
        background: 'none',
      },
      '& .MuiSelect-icon': {
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        border: `1px solid ${theme.palette.grey[500]}`,
        padding: 2,
        color: theme.palette.grey[500],
        borderRadius: 100,
        pointerEvents: 'none',
      },
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
    },
    listItem: {
      padding: theme.spacing(2, 3),
      '&:not(:last-child)': {
        borderBottom: `1px solid ${theme.palette.grey[300]}`,
      },
    },
    itemName: {
      ...theme.typography.body1,
    },
    itemValue: {
      ...theme.typography.body2,
      color: theme.palette.grey[600],
    },
  }),
)

type ListItemProps = {
  item: {
    name: string
    value: string
  }
}

type Props = {
  isScout: boolean
}

const ListItem: React.FC<ListItemProps> = (props) => {
  const classes = useStyles()
  const { item } = props

  return (
    <div className={classes.listItem}>
      <div className={classes.itemName}>{item.name}</div>

      <div className={classes.itemValue}>
        {String(item.value)
          ?.split('\n')
          .map((line) => (
            <div key={line}>{line}</div>
          ))}
      </div>
    </div>
  )
}

export interface Division {
  id?: number
  code?: number
}

const getSelectionPhaseUrl = (isAdmin: boolean) => {
  if (isAdmin) return null
  return `/companies/selection_phases`
}

export const StudentDetailContainer: React.FC<Props> = (props) => {
  const { isScout } = props
  const { isAdmin } = useSession()
  const classes = useStyles()
  const dispatch = useDispatch()
  const { id } = useParams()
  const [selectionPhase, setSelectionPhase] = useState<number>(0)
  const { data: selectionPhasesData } = useSWR(getSelectionPhaseUrl(isAdmin))
  const { data: entry } = useSWR(`/companies/jobseekers/${id}`)
  const { data: schoolDivisions } = useSWR(`/school_divisions`)
  const { data: departmentGroups } = useSWR(`/department_groups`)
  const { data: prefectures } = useSWR(`/prefectures`)

  useEffect(() => {
    if (entry) {
      setSelectionPhase(entry.selectionPhase.id)
    }
  }, [entry])

  const selectionPhases = useMemo(() => {
    if (!selectionPhasesData || !selectionPhase) {
      return []
    }
    const selectionPhases = [...selectionPhasesData]
    let phase = selectionPhases.find((p) => p.id === selectionPhase)
    while (phase) {
      phase.disabled = true
      phase = selectionPhases.find((p) => p.id === phase.previousSelectionPhaseId)
    }
    return selectionPhases
  }, [selectionPhasesData, selectionPhase])

  if (!entry) {
    return null
  }

  const getSchoolDivisionName = (schoolDivisionId: number) => {
    const schoolDivision = schoolDivisions?.schoolDivisions.find((division: Division) => division.id === schoolDivisionId)
    return schoolDivision?.name
  }

  const getDepartmentGroupName = (departmentGroupId: number) => {
    const departmentGroup = departmentGroups?.departmentGroups.find((division: Division) => division.id === departmentGroupId)
    return departmentGroup?.name
  }

  const getPrefecturesName = (prefecturesId: number) => {
    const prefecture = prefectures?.prefectures.find((division: Division) => division.code === prefecturesId)
    return prefecture?.name
  }

  const hasInvitationBeenSent = entry.selectionPhase.name !== ''
  const hasInvitationBeenAccepted = entry.selectionPhase.name !== '' && entry.selectionPhase.name !== '承認待ち'
  const detailType = hasInvitationBeenAccepted || isAdmin ? 'defaultDetails' : 'scoutDetails'

  const openChat = async () => {
    dispatch({
      type: 'OPEN_CHAT',
      payload: {
        studentId: id,
        name: hasInvitationBeenAccepted || isAdmin ? entry.name : `${entry.schoolName} ${getDepartmentGroupName(entry.departmentGroupId)}`,
      },
    })
  }

  const updateSelectionPhase = (e: React.ChangeEvent<{ value: unknown }>) => {
    setSelectionPhase(Number(e.target.value))
    caxios.post(`/companies/jobseekers/selection_phases/${e.target.value}`, {
      ids: [Number(id)],
    })
  }

  const preferredCities = sortedNames(entry.preferredCities)
  const preferredCategories = sortedNames(entry.preferredCategories)
  const selfAnalysisTags = sortedNames(entry.selfAnalysisTags)
  const importantPoints = sortedNames(entry.importantPoints)

  const profile = {
    name: entry.name,
    nameKana: entry.nameKana,
    lastLogin: entry.lastSignInAt,
    selectionPhase: entry.selectionPhase.name,
    iconUrl: entry.iconUrl,
    desiredJob: [
      {
        name: '希望職種',
        value: roundNumList(preferredCategories),
      },
      {
        name: '希望勤務地',
        value: kanjiList(preferredCities, (k, v) => `第${k}希望：${v}`),
      },
    ],
    defaultDetails: [
      {
        name: '自宅電話番号',
        value: entry.homeTel,
      },
      {
        name: '携帯電話番号',
        value: entry.tel,
      },
      {
        name: '生年月日',
        value: format(new Date(entry.birthday), 'yyyy/M/d'),
      },
      {
        name: '性別',
        value: entry.gender,
      },
      {
        name: '現在のお住まい',
        value: `
        〒${entry.zipCode}
        ${entry.prefecture}
        ${entry.city}
        ${entry.address}`,
      },
      {
        name: '休暇中の住所',
        value: `
        〒${entry.vacationZipCode}
        ${getPrefecturesName(entry.vacationPrefecture)}
        ${entry.vacationCity}
        ${entry.vacationAddress}`,
      },
      {
        name: '休暇中の連絡先',
        value: entry.vacationTel,
      },
      {
        name: '出身都道府県',
        value: entry.prefecture,
      },
      {
        name: '保有資格・免許',
        value: entry.qualification,
      },
    ],
    scoutDetails: [
      {
        name: '性別',
        value: entry.gender,
      },
      {
        name: '居住地',
        value: `
        ${entry.prefecture}
        ${entry.city}`,
      },
      {
        name: '出身都道府県',
        value: entry.prefecture,
      },
      {
        name: '保有資格・免許',
        value: entry.qualification,
      },
    ],
    jobHuntingStatus: [
      {
        name: '就活状況',
        value: entry.jobHuntingStatus,
      },
      {
        name: '内定承諾済企業',
        value: entry.joinAgreementStatus ? 'あり' : 'なし',
      },
    ],
    personalInfo: [
      {
        name: '自分の強み',
        value: roundNumList(selfAnalysisTags),
      },
      {
        name: '就活する上で重視していること',
        value: roundNumList(importantPoints),
      },
    ],
    schoolInfo: [
      {
        name: '学校区分',
        value: getSchoolDivisionName(entry.schoolDivisionId),
      },
      {
        name: '学校名',
        value: entry.schoolName,
      },
      {
        name: '学部学科',
        value: entry.facultyDepartment,
      },
      {
        name: '学科系統',
        value: getDepartmentGroupName(entry.departmentGroupId),
      },
      {
        name: '文理区分',
        value: entry.facultyDivision,
      },
      {
        name: '卒業時期',
        value: format(new Date(entry.graduatedAt), 'yyyy/M/d'),
      },
    ].filter(notNull),
    summaries: [
      {
        name: '自己PR',
        value: entry.prSentence,
      },
      {
        name: '自身の将来像',
        value: entry.futureVisionSentence,
      },
    ],
  }

  const jobHuntingInfoSection = (
    <>
      <SectionTitle>就活状況</SectionTitle>
      <Paper className={classes.list} variant="outlined">
        {profile.jobHuntingStatus.map((item) => (
          <ListItem key={item.name} item={item} />
        ))}
      </Paper>
    </>
  )

  return (
    <PageContent className={classes.container}>
      <div className={classes.profileHeader}>
        <Avatar className={classes.avatar} src={profile.iconUrl} />

        <div className={classes.profileHeaderText}>
          {hasInvitationBeenAccepted || isAdmin ? (
            <>
              <SectionTitle className={classes.name}>{profile.name}</SectionTitle>
              <Typography className={classes.nameKana}>{profile.nameKana}</Typography>
              <Typography className={classes.lastLogin}>最終ログイン：{format(parseISO(profile.lastLogin), 'yyyy年M月d日')}</Typography>
            </>
          ) : (
            <>
              <SectionTitle className={classes.name}>{entry.schoolName}</SectionTitle>
              <Typography className={classes.nameKana}>{getDepartmentGroupName(entry.departmentGroupId)}</Typography>
            </>
          )}
        </div>

        <div>
          <Button
            className={classes.roundButton}
            variant="contained"
            color="primary"
            size="large"
            disableElevation
            startIcon={<ChatIcon />}
            onClick={openChat}
          >
            {isAdmin || hasInvitationBeenSent ? 'チャット' : 'スカウトチャット'}
          </Button>
        </div>
      </div>

      <div className={classes.content}>
        <div className={classes.column}>
          <SectionTitle>希望職種, 勤務地</SectionTitle>
          <Paper className={classes.list} variant="outlined">
            {profile.desiredJob.map((item) => (
              <ListItem key={item.name} item={item} />
            ))}
          </Paper>

          <SectionTitle>プロフィール</SectionTitle>
          <Paper className={classes.list} variant="outlined">
            {profile[detailType].map((item) => (
              <ListItem key={item.name} item={item} />
            ))}
          </Paper>

          {isScout && jobHuntingInfoSection}
        </div>

        <div className={classes.column}>
          {!isAdmin && hasInvitationBeenSent && (
            <>
              <SectionTitle>選考フェーズ</SectionTitle>
              {hasInvitationBeenAccepted ? (
                <Paper className={classes.stat} variant="outlined">
                  <Select value={selectionPhase} onChange={updateSelectionPhase} input={<InputBase />} className={classes.selectionPhase}>
                    {selectionPhases?.map((phase) => (
                      <MenuItem key={phase.id} value={phase.id} disabled={phase.disabled}>
                        {phase.name}
                      </MenuItem>
                    ))}
                  </Select>
                </Paper>
              ) : (
                <Paper className={classes.stat} variant="outlined">
                  <Select value={0} input={<InputBase />} className={classes.selectionPhase} disabled>
                    <MenuItem value={0}>承認待ち</MenuItem>
                  </Select>
                </Paper>
              )}
            </>
          )}

          <SectionTitle>パーソナル情報</SectionTitle>
          <Paper className={classes.list} variant="outlined">
            {profile.personalInfo.map((item) => (
              <ListItem key={item.name} item={item} />
            ))}
          </Paper>

          <SectionTitle>学校情報</SectionTitle>
          <Paper className={classes.list} variant="outlined">
            {profile.schoolInfo.map((item) => (
              <ListItem key={item.name} item={item} />
            ))}
          </Paper>

          {!isScout && jobHuntingInfoSection}
        </div>

        <div className={classes.wideColumn}>
          <Paper className={classes.list} variant="outlined">
            {profile.summaries.map((item) => (
              <ListItem key={item.name} item={item} />
            ))}
          </Paper>
        </div>
      </div>
    </PageContent>
  )
}
