import React from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from '@material-ui/core'
import { useSession } from '@/hooks/use-session'
import { useDispatch } from '@/store'
import { useHistory } from 'react-router-dom'

type Props = {
  open: boolean
  closeDialog: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    content: {
      width: 320,
    },
  }),
)

export const ConfirmLogoutDialog: React.FC<Props> = (props) => {
  const { open, closeDialog } = props
  const { isAdmin } = useSession()
  const dispatch = useDispatch()
  const history = useHistory()
  const classes = useStyles()

  const logout = () => {
    dispatch({ type: 'LOGOUT' })
    history.push(isAdmin ? '/admin' : '/company')
  }

  return (
    <Dialog onClose={closeDialog} open={open}>
      <DialogTitle>ログアウト</DialogTitle>

      <DialogContent className={classes.content}>
        <DialogContentText>
          ログアウトします。
          <br />
          よろしいですか？
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeDialog} color="secondary">
          戻る
        </Button>
        <Button onClick={logout} color="primary">
          ログアウトする
        </Button>
      </DialogActions>
    </Dialog>
  )
}
