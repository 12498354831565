import React from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 300,
      marginRight: theme.spacing(4),
    },
  }),
)

export const PageSidebar: React.FC = (props) => {
  const classes = useStyles()
  return <div className={classes.root}>{props.children}</div>
}
