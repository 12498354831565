import React from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Link } from '@/components/atoms/link'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      borderTop: `1px solid ${theme.palette.grey[300]}`,
      marginTop: theme.spacing(5),
      paddingTop: theme.spacing(2),
      color: theme.palette.grey[500],
      fontSize: '12px',
      '& > :not(:last-child)': {
        paddingRight: theme.spacing(3),
      },
    },
    padRight: {
      marginRight: 'auto',
    },
  }),
)

export const AppFooter: React.FC = (props) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.padRight}>© {new Date().getFullYear()} 株式会社 Be win</div>
      <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_COMPANY_PROFILE_URL}>
        会社概要
      </a>
      <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_TOS_URL}>
        利用規約
      </a>
      <a target="_blank" rel="noreferrer" href={process.env.REACT_APP_PRIVACY_POLICY_URL}>
        個人情報保護方針
      </a>
    </div>
  )
}
