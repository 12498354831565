import axios from 'axios'
import snakeCaseKeys from 'snakecase-keys'
import camelCaseKeys from 'camelcase-keys'
import { getPersistedState } from '@/store'
import { AxiosRequestConfig } from 'axios'

export const caxios = axios.create({
  withCredentials: true,
  baseURL: process.env.REACT_APP_ADMIN_SERVER,
})

export interface CaxiosRequestConfig extends AxiosRequestConfig {
  disableSnackbar: boolean
}

caxios.interceptors.response.use((res) => {
  if (!res.data) {
    return res
  }
  const data = JSON.parse(JSON.stringify(res.data))
  const camelCaseData = camelCaseKeys(data, { deep: true })
  return { ...res, data: camelCaseData }
})

caxios.interceptors.request.use((config) => {
  const { token } = getPersistedState().session
  const headers = { ...config.headers }
  if (config.withCredentials && token) {
    headers['Authorization'] = `Bearer ${token}`
  }
  if (!config.data) {
    return { ...config, headers }
  }
  let data = config.data
  if (!(data instanceof FormData)) {
    data = JSON.parse(JSON.stringify(data))
    data = snakeCaseKeys(data, { deep: true })
  }
  return { ...config, data, headers }
})
