import React, { useState, useContext } from 'react'
import { Paper, Typography, Button, CircularProgress, makeStyles, createStyles, Theme } from '@material-ui/core'
import { useParams } from 'react-router-dom'
import { caxios } from '@/submodules/custom-axios'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import { Field } from '@/components/molecules/field'
import { mutate } from 'swr'
import { SnackbarContext } from '@/providers/snackbar-provider'
import { useForm } from 'react-hook-form'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(6),
    },
    text: {
      marginBottom: theme.spacing(3),
    },
    heading: {
      marginBottom: theme.spacing(1),
    },
    controls: {
      marginTop: theme.spacing(4),
      textAlign: 'right',
    },
    addButton: {
      width: 137,
    },
    spinner: {
      color: theme.palette.common.white,
    },
  }),
)

const emailValid = /^[A-Za-z0-9]{1}[A-Za-z0-9_.+-]*@{1}[A-Za-z0-9_.-]{1,}\.[A-Za-z0-9]{1,}$/

export const AddRepresentativeForm: React.FC = (props) => {
  const { register, handleSubmit, errors } = useForm()
  const classes = useStyles()
  const { id } = useParams()
  const [emailFieldValue, setEmailFieldValue] = useState('')
  const [loading, setLoading] = useState(false)
  const { setSnackbarMessage } = useContext(SnackbarContext)

  const addPersonnel = async () => {
    setLoading(true)

    try {
      const res = await caxios.post(`/admins/companies/${id}/personnels`, {
        email: emailFieldValue,
        name: '',
        description: '',
        icon_url: '',
      })
      mutate(`/admins/companies/${id}/personnels`, res.data)
      setSnackbarMessage('担当者を追加しました')
    } catch (e) {
      switch (e.response.data.message) {
        case 'Email already exist.':
          setSnackbarMessage('このメールアドレスは既に使用されています')
          break
        default:
          setSnackbarMessage('未知のエラー')
      }
    }
    setLoading(false)
  }

  const emailField = {
    name: 'email',
    label: '担当者のメールアドレス',
    onChange: setEmailFieldValue,
    validation: {
      required: '入力したメールアドレスが正しくありません',
      pattern: { value: emailValid, message: 'メールアドレスの形式が違います' },
    },
  }

  return (
    <Paper className={classes.paper} variant="outlined">
      <div className={classes.text}>
        <Typography variant="body1" className={classes.heading}>
          担当者を追加する
        </Typography>
        <Typography variant="body2" color="textSecondary">
          入力されたメールアドレス に、アカウントの初期パスワードをお送りします。
        </Typography>
      </div>

      <form onSubmit={handleSubmit(addPersonnel)}>
        <Field {...emailField} register={register} errors={errors} variant="outlined" />

        <div className={classes.controls}>
          <Button
            className={classes.addButton}
            variant="contained"
            color="primary"
            startIcon={!loading && <AddCircleIcon />}
            disableElevation
            size="large"
            type="submit"
          >
            {loading ? <CircularProgress size={26} className={classes.spinner} /> : '追加する'}
          </Button>
        </div>
      </form>
    </Paper>
  )
}
