import React, { useState } from 'react'
import { Container } from '@material-ui/core'
import { LoginCard, Fields } from '@/components/organisms/login-card'
import { Image } from '@/components/atoms/image'
import { LoginFooter } from '@/components/molecules/login-footer/login-footer'
import { useLocation, useHistory } from 'react-router-dom'
import { caxios } from '@/submodules/custom-axios'
import { useDispatch } from '@/store'
import { useStyles } from '../login'

export const RESET_PASSWORD_EMAIL_KEY = 'reset-password-email'

function getErrorMessage(message: string) {
  switch (message) {
    case 'Find record `ResetPasswordCode` error: record not found':
      return '再設定コードの有効期限が切れました'
  }
  return '未知のエラー'
}

function useQueryParameters() {
  return new URLSearchParams(useLocation().search)
}

export function ResetPasswordContainer() {
  const classes = useStyles()
  const params = useQueryParameters()
  const history = useHistory()
  const [error, setError] = useState('')
  const [password, setPassword] = useState<string>('')
  const dispatch = useDispatch()

  const handleSubmit = async () => {
    try {
      await caxios.put(
        '/companies/personnels/reset_password',
        {
          code: params.get('code'),
          password,
        },
        {
          withCredentials: false,
        },
      )
      try {
        const email = localStorage.getItem(RESET_PASSWORD_EMAIL_KEY)
        localStorage.removeItem(RESET_PASSWORD_EMAIL_KEY)
        const res = await caxios.post(
          '/companies/login',
          {
            email,
            password,
          },
          {
            withCredentials: false,
          },
        )

        dispatch({
          type: 'LOGIN',
          payload: {
            isAdmin: false,
            isAuthenticated: true,
            token: res.headers.authorization,
            ...res.data,
          },
        })
        history.push('/')
      } catch (e) {
        history.push('/company')
      }
    } catch (err) {
      if (err.response?.data.message) {
        setError(getErrorMessage(err.response.data.message))
        return
      }
    }
  }

  const fields: Fields = {
    newPassword: {
      name: 'password',
      label: '新規パスワード',
      type: 'password',
      placeholder: '',
      onChange: setPassword,
      validation: {
        required: true,
        pattern: { value: /.{8,}/, message: 'パスワードは、８文字以上に設定してください' },
      },
    },
  }

  return (
    <Container className={classes.root}>
      <div className={classes.centered}>
        <Image src="/logo.svg" />
        <p>企業様用管理画面</p>

        <LoginCard
          caption="新しく設定するパスワードを入力してください。"
          fields={fields}
          onSubmit={handleSubmit}
          submitLabel="再設定する"
          error={error}
        />
      </div>

      <LoginFooter />
    </Container>
  )
}
