import React, { useMemo } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Paper, Avatar, Typography, Grid } from '@material-ui/core'
import PersonPinIcon from '@material-ui/icons/PersonPin'
import HomeIcon from '@material-ui/icons/Home'
import WorkIcon from '@material-ui/icons/Work'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import EmojiObjectsIcon from '@material-ui/icons/EmojiObjects'
import FlagIcon from '@material-ui/icons/Flag'
import { useHistory } from 'react-router-dom'
import { useSession } from '@/hooks/use-session'
import useSWR from 'swr'
import { Division } from '@/components/containers/student-detail'

export const roundNumList = (list: string[]): React.ReactNode => {
  const baseCharCode = '①'.charCodeAt(0)
  return list.map((item, index) => {
    const roundNum = String.fromCharCode(baseCharCode + index)
    return (
      <div key={index}>
        {roundNum} {item}
      </div>
    )
  })
}

export const useCardStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {},
    header: {
      margin: '0 20px 20px 20px',
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
    },
    avatar: {
      margin: 20,
      width: 180,
      height: 180,
      background: '#64B6F7',
      position: 'static',
    },
    headerTitle: {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      padding: '16px 14px',
    },
    title: {
      fontSize: 16,
      marginBottom: theme.spacing(0.5),
    },
    major: {
      border: `1px solid ${theme.palette.grey[300]}`,
      display: 'inline-block',
      padding: theme.spacing(0.25, 1.75),
      borderRadius: 3,
      marginRight: 'auto',
      fontSize: 12,
    },
    selectionPhase: {
      background: theme.palette.background.default,
      border: '1px solid transparent',
      display: 'inline-block',
      padding: theme.spacing(0.25, 1.75),
      borderRadius: 3,
      marginLeft: theme.spacing(1),
      fontSize: 12,
    },
    selectionPhaseAccepted: {
      background: theme.palette.primary.main,
      border: '1px solid transparent',
      color: theme.palette.common.white,
      display: 'inline-block',
      padding: theme.spacing(0.25, 1.75),
      borderRadius: 3,
      marginLeft: theme.spacing(1),
      fontSize: 12,
    },
    headerIcon: {
      color: theme.palette.grey[500],
    },
    content: {
      padding: '0 40px 20px 40px',
    },
    prSentence: {
      display: '-webkit-box',
      overflow: 'hidden',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
      maxHeight: 48,
      marginTop: 4,
      fontSize: 'inherit',
    },
    noPrSentence: {
      color: theme.palette.grey[600],
      marginTop: theme.spacing(0.5),
    },
    stats: {
      padding: '16px 14px',
    },
    heading: {
      color: theme.palette.grey[600],
      '& svg': {
        verticalAlign: 'top',
        width: 20,
        height: 20,
        marginRight: theme.spacing(0.5),
      },
    },
    chipList: {},
    chip: {
      display: 'inline-block',
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 5,
      padding: theme.spacing(0.25, 1.5),
      marginTop: theme.spacing(1),
      marginRight: theme.spacing(1),
    },
  }),
)

const stats = [
  {
    name: 'originPrefecture',
    label: '出身地',
    icon: <PersonPinIcon />,
  },
  {
    name: 'residence',
    label: '居住地',
    icon: <HomeIcon />,
  },
  {
    name: 'preferredCategories',
    label: '希望職種',
    icon: <WorkIcon />,
  },
]

type HeadingProps = {
  icon: JSX.Element
  label: string
  styles: any
}

const Heading: React.FC<HeadingProps> = (props) => {
  const classes = props.styles
  return (
    <div className={classes.heading}>
      {props.icon}
      {props.label}
    </div>
  )
}

type ChipListProps = {
  chips: string[]
  styles: any
}

const ChipList: React.FC<ChipListProps> = (props) => {
  const classes = props.styles
  return (
    <div className={classes.chipList}>
      {props.chips.map((chip) => (
        <div key={chip} className={classes.chip}>
          {chip}
        </div>
      ))}
    </div>
  )
}

export type Jobseeker = {
  city: string
  id: number
  iconUrl: string
  name: string
  facultyDepartment: string
  facultyDivision: string
  accountStatus: number
  isAcceptChatNotice: boolean
  prefecture: string
  preferredCategories: { name: string }[] | null
  prSentence: string
  importantPoints: { name: string }[] | null
  selfAnalysisTags: { name: string }[] | null
  selectionPhase: { name: string }
  schoolName: string
  departmentGroupId: number
  scoutStatus: string
}

type Props = {
  jobseeker: Jobseeker
  styles: any
}

const getNames = (objs: { name: string }[]) => objs.map((o) => o.name)

export const ScoutCard: React.FC<Props> = React.memo(
  function ScoutCard(props: Props) {
    const history = useHistory()
    const { data: departmentGroups } = useSWR(`/department_groups`)
    const { jobseeker } = props
    const { isAdmin } = useSession()
    const classes = props.styles
    const hasBeenScouted = jobseeker.scoutStatus !== '' && jobseeker.scoutStatus !== '未スカウト'

    const profile = useMemo(
      () => ({
        ...jobseeker,
        preferredCategories: roundNumList(getNames(jobseeker.preferredCategories || [])),
        selfAnalysisTags: getNames(jobseeker.selfAnalysisTags || []),
        importantPoints: getNames(jobseeker.importantPoints || []),
        residence: `${jobseeker.prefecture}${jobseeker.city}`,
      }),
      [jobseeker],
    )

    const redirectToScout = () => {
      if (isAdmin) return history.push(`/students/${jobseeker.id}`)
      history.push(`/scout/${jobseeker.id}`)
    }

    const schoolTitle = `${jobseeker.schoolName} ${jobseeker.facultyDepartment}`

    return (
      <Paper variant="outlined">
        <div className={classes.header} onClick={redirectToScout}>
          <Avatar className={classes.avatar} src={profile.iconUrl} />
          <div>
            <div className={classes.headerTitle}>
              <Typography className={classes.title}>{isAdmin ? `${profile.name} (${schoolTitle})` : schoolTitle}</Typography>
              <Typography className={classes.major}>{profile.facultyDivision}</Typography>
              {hasBeenScouted && (
                <Typography className={jobseeker.scoutStatus === '承認済' ? classes.selectionPhaseAccepted : classes.selectionPhase}>
                  {jobseeker.scoutStatus}
                </Typography>
              )}
            </div>
            <Grid container spacing={1} className={classes.stats}>
              {stats.map((stat) => (
                <React.Fragment key={stat.label}>
                  <Grid item xs={3} className={classes.heading}>
                    <Heading icon={stat.icon} label={stat.label} styles={classes} />
                  </Grid>
                  <Grid item xs={9}>
                    {profile[stat.name]}
                  </Grid>
                </React.Fragment>
              ))}
            </Grid>
          </div>
        </div>

        <div className={classes.content}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Heading icon={<AssignmentIndIcon />} label="自己PR" styles={classes} />
              {profile.prSentence ? (
                <Typography className={classes.prSentence}>{profile.prSentence}</Typography>
              ) : (
                <div className={classes.noPrSentence}>(自己PRが設定されていません)</div>
              )}
            </Grid>
            <Grid item xs={12}>
              <Heading icon={<EmojiObjectsIcon />} label="自分の強み" styles={classes} />
              <ChipList chips={profile.selfAnalysisTags} styles={classes} />
            </Grid>
            <Grid item xs={12}>
              <Heading icon={<FlagIcon />} label="就活の軸" styles={classes} />
              <ChipList chips={profile.importantPoints} styles={classes} />
            </Grid>
          </Grid>
        </div>
      </Paper>
    )
  },
  (prev, next) => prev.jobseeker.id === next.jobseeker.id,
)
