/* eslint-disable react/jsx-key */

import React, { useEffect } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import { BasicLayout } from '@/components/layouts/basic'

// Example pages
import { HelloWorldDetailPage } from '@/components/pages/helloworld/detail'
import { HelloWorldPage } from '@/components/pages/helloworld'

// Error pages
import { NotFoundErrorPage } from '@/components/pages/not-found-error'
import { UnkownErrorBoundary } from '@/components/boundary/unkown-error'
import { InternalServerErrorProvider } from '@/components/providers/internal-server-error-provider'
import { InternalServerErrorPage } from '@/components/pages/internal-server-error'

// Login pages
import { LoginPage } from '@/components/pages/login'
import { ForgotPasswordPage } from '@/components/pages/forgot-password'
import { ResetPasswordPage } from '@/components/pages/reset-password'

import { DashboardPage } from '@/components/pages/dashboard'
import { RepresentativePage } from '@/components/pages/representative'
import { ChatTemplateListPage } from '@/components/pages/chat-template-list'
import { ChatTemplateEditPage } from '@/components/pages/chat-template-edit'
import { StudentDetailPage } from '@/components/pages/student-detail'
import { SelectionPhasesPage } from '@/components/pages/selection-phases'
import { SelectionPhasePage } from '@/components/pages/selection-phase'
import { StudentListPage } from '@/components/pages/student-list'
import { PasswordSettingsPage } from '@/components/pages/password-settings'
import { ScoutPage } from '@/components/pages/scout'
import { ClientListPage } from '@/components/pages/client-list'
import { ClientDetailPage } from '@/components/pages/client-detail'
import { ClientPlanSettingsPage } from '@/components/pages/client-plan-settings'
import { ClientStudentListPage } from '@/components/pages/client-student-list'
import { useSession } from '@/hooks/use-session'
import { useHistory } from 'react-router'

export const Router: React.SFC = () => {
  const { isAdmin, isAuthenticated } = useSession()
  const history = useHistory()

  useEffect(() => {
    // Scroll to the top when navigating (ignore back/forward button POP actions)
    history.listen((_, action) => {
      if (action === 'PUSH') {
        window.scrollTo(0, 0)
      }
    })
  }, [])

  return (
    <BasicLayout>
      <UnkownErrorBoundary>
        <InternalServerErrorProvider render={() => <InternalServerErrorPage />}>
          {isAuthenticated ? (
            isAdmin ? (
              <Switch>
                <Route path="/company" redirect render={() => <Redirect to="/" />} />
                <Route path="/admin" render={() => <Redirect to="/" />} />
                <Route path="/forgot-password" render={() => <Redirect to="/" />} />
                <Route path="/reset-password" render={() => <Redirect to="/" />} />
                <Route exact path="/" render={() => <Redirect to="/students" />} />
                <Route exact path="/students" render={() => <ScoutPage view="students" />} />
                <Route exact path="/students/:id" component={StudentDetailPage} />
                <Route exact path="/clients" component={ClientListPage} />
                <Route exact path="/clients/:id" component={ClientDetailPage} />
                <Route exact path="/clients/:id/plan" component={ClientPlanSettingsPage} />
                <Route exact path="/clients/:id/students/:view" component={ClientStudentListPage} />
                <Route exact path="/clients/:companyId/student-detail/:id" component={StudentDetailPage} />
                <Route exact path="/settings/representative" component={RepresentativePage} />
                <Route exact path="/settings/chat-templates" component={ChatTemplateListPage} />
                <Route exact path="/settings/chat-templates/:id" component={ChatTemplateEditPage} />
                <Route component={NotFoundErrorPage} />
              </Switch>
            ) : (
              <Switch>
                <Route path="/company" redirect render={() => <Redirect to="/" />} />
                <Route path="/admin" render={() => <Redirect to="/" />} />
                <Route path="/forgot-password" render={() => <Redirect to="/" />} />
                <Route path="/reset-password" render={() => <Redirect to="/" />} />
                <Route exact path="/" component={DashboardPage} />
                <Route exact path="/students" render={() => <Redirect to="/students/entry" />} />
                <Route exact path="/students/all" render={() => <StudentListPage view="all" />} />
                <Route exact path="/students/entry" render={() => <StudentListPage view="entry" />} />
                <Route exact path="/students/info-session" render={() => <StudentListPage view="infoSession" />} />
                <Route exact path="/students/interns" render={() => <StudentListPage view="intern" />} />
                <Route exact path="/students/:id" component={StudentDetailPage} />
                <Route exact path="/scout" component={ScoutPage} />
                <Route exact path="/scout/:id" component={StudentDetailPage} />
                <Route exact path="/settings/selection-phases" component={SelectionPhasesPage} />
                <Route exact path="/settings/selection-phases/:id" component={SelectionPhasePage} />
                <Route exact path="/settings/chat-templates" component={ChatTemplateListPage} />
                <Route exact path="/settings/chat-templates/:id" component={ChatTemplateEditPage} />
                <Route exact path="/settings/representative" component={RepresentativePage} />
                <Route exact path="/settings/password" component={PasswordSettingsPage} />
                <Route component={NotFoundErrorPage} />
              </Switch>
            )
          ) : (
            <Switch>
              <Route exact path="/" component={LoginPage} />
              <Route exact path="/company" component={LoginPage} />
              <Route exact path="/admin" component={LoginPage} />
              <Route exact path="/forgot-password" component={ForgotPasswordPage} />
              <Route path="/reset-password" component={ResetPasswordPage} />
              <Route component={NotFoundErrorPage} />
            </Switch>
          )}
        </InternalServerErrorProvider>
      </UnkownErrorBoundary>
    </BasicLayout>
  )
}
