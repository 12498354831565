import React, { useState } from 'react'
import {
  Button,
  createStyles,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  makeStyles,
} from '@material-ui/core'
import { SectionTitle, SectionTitleControls } from '@/components/atoms/section-title'
import { BasicTable, Column } from '@/components/organisms/table'
import DeleteIcon from '@material-ui/icons/Delete'
import CreateIcon from '@material-ui/icons/Create'
import { PageContent } from '@/components/organisms/page-content'
import useSWR, { mutate } from 'swr'
import { caxios } from '@/submodules/custom-axios'
import { useHistory } from 'react-router-dom'
import { useSession } from '@/hooks/use-session'

const useStyles = makeStyles(() =>
  createStyles({
    dialogContent: {
      minWidth: 500,
      whiteSpace: 'pre-wrap',
    },
    dialogContentText: {
      margin: 0,
    },
  }),
)

interface Row {
  id: number
  companyId: number
  title: string
  message: string
}

const columns: Column<Row>[] = [
  { id: 'id', label: 'No.', align: 'left' },
  { id: 'title', label: 'タイトル' },
  { id: 'message', label: '内容', numeric: false },
]

export const ChatTemplateListContainer: React.FC = () => {
  const [checked, setChecked] = useState<Array<number>>([])
  const history = useHistory()
  const { isAdmin } = useSession()
  const endpoint = isAdmin ? 'admins' : 'companies'
  const { data, isValidating } = useSWR(`/${endpoint}/chat_templates`)
  const templates = data ?? []
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [deleteId, setDeleteId] = useState<number>()
  const classes = useStyles()

  const showDeleteDialog = (rowId?: number) => {
    if (!checked && !rowId) return
    if (rowId) setDeleteId(rowId)
    setDeleteDialogOpen(true)
  }

  const handleCloseDeleteDialog = () => {
    setDeleteId(0)
    setDeleteDialogOpen(false)
  }

  const createChatTemplate = async () => {
    history.push('/settings/chat-templates/add')
  }

  const deleteChatTemplates = async (ids: number[]) => {
    setChecked(checked.filter((id) => !ids.includes(id)))
    if (!ids.length) {
      // API deletes everything if we pass an empty array, so never let it happen
      return
    }
    const { data } = await caxios.delete(`/${endpoint}/chat_templates`, {
      data: { ids },
    })
    mutate(`/${endpoint}/chat_templates`, data, false)
    handleCloseDeleteDialog()
  }

  const deleteSelected = async () => {
    if (deleteId) {
      deleteChatTemplates([deleteId])
      return
    }
    await deleteChatTemplates(checked)
  }

  const actions = [
    {
      name: 'delete',
      icon: <DeleteIcon />,
      onClick: (row: Row) => () => showDeleteDialog(row.id),
    },
    {
      name: 'view',
      icon: <CreateIcon />,
      onClick: (row: Row) => () => history.push(`/settings/chat-templates/${row.id}`),
    },
  ]

  return (
    <PageContent>
      <SectionTitle>
        テンプレート一覧
        <SectionTitleControls>
          <Button variant="contained" disableElevation onClick={() => showDeleteDialog()} disabled={checked.length === 0}>
            一括削除
          </Button>
          <Button variant="contained" disableElevation color="primary" onClick={createChatTemplate} disabled={templates.length >= 10}>
            追加
          </Button>
        </SectionTitleControls>
      </SectionTitle>
      <BasicTable<Row>
        columns={columns}
        rows={templates}
        checked={checked}
        setChecked={setChecked}
        rowControls={(row) => (
          <>
            {actions.map((action) => (
              <IconButton key={action.name} onClick={action.onClick(row)}>
                {action.icon}
              </IconButton>
            ))}
          </>
        )}
        isValidating={isValidating}
      />

      <Dialog open={deleteDialogOpen} onClose={handleCloseDeleteDialog}>
        <DialogTitle>チャットテンプレートの削除</DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <DialogContentText className={classes.dialogContentText}>
            {'このチャットテンプレートを削除しますか？\nこの操作は取り消せません。'}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={handleCloseDeleteDialog} color="secondary">
            戻る
          </Button>
          <Button onClick={deleteSelected} color="primary">
            削除する
          </Button>
        </DialogActions>
      </Dialog>
    </PageContent>
  )
}
