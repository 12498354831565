import React, { useState } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { AppBar, Toolbar, Typography, Button, FormControl, Select, MenuItem } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { appSidebarWidth } from '@/components/organisms/app-sidebar'
import { useTitle } from '@/hooks/use-title'
import { useAppContext, useDispatch } from '@/store'
import { useSession } from '@/hooks/use-session'
import { ConfirmLogoutDialog } from './confirm-logout-dialog'
import useSWR from 'swr'

export const APP_HEADER_HEIGHT = 100

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: `calc(100% - ${appSidebarWidth}px)`,
      marginLeft: appSidebarWidth,
      justifyContent: 'center',
      height: APP_HEADER_HEIGHT,
      padding: theme.spacing(0, 3),
      userSelect: 'none',
    },
    toolbar: {
      display: 'flex',
    },
    button: {
      textTransform: 'none',
      marginRight: theme.spacing(2),
      color: '#fff !important',
      flexShrink: 0,
    },
    formControl: {
      flexShrink: 0,
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(255, 255, 255, 0.75)',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(255, 255, 255, 1)',
      },
      '& .MuiSelect-select': {
        padding: '10px 30px 10px 10px',
        color: '#ffffff',
        '&:focus': {
          background: 'rgba(255, 255, 255, 0.05)',
        },
      },
      '& svg': {
        fill: '#ffffff',
      },
    },
    title: {
      flexGrow: 1,
      fontWeight: theme.typography.fontWeightRegular,
    },
  }),
)

type ListItem = {
  id: string
  name: string
  type: 'year' | 'division'
}

type SchoolDivision = {
  id: number
  name: string
  createdAt: string
  updatedAt: string
}

const getAcademicYearList = (academicYears: number[]): ListItem[] => {
  if (!academicYears) return []
  return academicYears.map((year) => {
    return { id: `year-${year}`, name: `${year}年`, type: 'year' }
  })
}
const getSchoolDivisionslist = (schoolDivisions: SchoolDivision[]): ListItem[] => {
  if (!schoolDivisions) return []
  return schoolDivisions.map((division) => {
    return { id: `division-${division.id}`, name: division.name, type: 'division' }
  })
}

export const AppHeader: React.FC = () => {
  const classes = useStyles()
  const title = useTitle()
  const { isAdmin, name: username } = useSession()
  const name = isAdmin ? process.env.REACT_APP_BEWIN_DISPLAY_NAME : username
  const app = useAppContext()
  const selectedYear = app.state.selectedYear
  const selectedDivision = app.state.selectedDivision
  const dispatch = useDispatch()
  const [dialogOpen, setDialogOpen] = useState(false)
  const { data: academicYears } = useSWR('/academic_years')
  const { data: schoolDivisions } = useSWR('/school_divisions')

  const academicYearList: ListItem[] = getAcademicYearList(academicYears?.academicYears)
  const schoolDivisionsList: ListItem[] = getSchoolDivisionslist(schoolDivisions?.schoolDivisions)
  const aggregateList: ListItem[] = academicYearList.concat(schoolDivisionsList)

  return (
    <>
      <AppBar position="fixed" className={classes.root} elevation={0}>
        <Toolbar>
          {title && title.icon}
          <Typography className={classes.title} variant="h6" noWrap>
            {title && title.text}
          </Typography>

          <Button className={classes.button} size="large" startIcon={<PersonIcon />} disabled>
            {name}
          </Button>

          <Button className={classes.button} size="large" startIcon={<ExitToAppIcon />} onClick={() => setDialogOpen(true)}>
            ログアウト
          </Button>

          <FormControl variant="outlined" className={classes.formControl}>
            <Select
              value={selectedDivision ? `division-${selectedDivision}` : `year-${selectedYear}`}
              onChange={(event) => {
                dispatch({
                  type: 'UPDATE_YEAR',
                  payload: event.target.value,
                })
              }}
            >
              {aggregateList.map((item) => (
                <MenuItem value={item.id} key={item.id}>{`${item.name}`}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </Toolbar>
      </AppBar>
      <ConfirmLogoutDialog open={dialogOpen} closeDialog={() => setDialogOpen(false)} />
    </>
  )
}
