import { IconButton, Snackbar } from '@material-ui/core'
import React, { useState, createContext } from 'react'
import CloseIcon from '@material-ui/icons/Close'

type Context = {
  setSnackbarMessage: React.Dispatch<React.SetStateAction<string>>
}
export const SnackbarContext = createContext<Context>({ setSnackbarMessage: () => {} })

type Props = {
  children: any
}

export const SnackbarProvider: React.FC<Props> = (props) => {
  const [snackbarMessage, setSnackbarMessage] = useState('')

  return (
    <SnackbarContext.Provider value={{ setSnackbarMessage }}>
      {snackbarMessage ? (
        <>
          {props.children}
          <Snackbar
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
            open={!!snackbarMessage}
            onClose={() => setSnackbarMessage('')}
            autoHideDuration={5000}
            message={snackbarMessage}
            action={
              <IconButton color="inherit" onClick={() => setSnackbarMessage('')}>
                <CloseIcon />
              </IconButton>
            }
          />
        </>
      ) : (
        props.children
      )}
    </SnackbarContext.Provider>
  )
}
