import React, { useState } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

import {
  TextField,
  Typography,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  Checkbox,
  RadioGroup,
  Radio,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  DialogActions,
} from '@material-ui/core'

import CloseIcon from '@material-ui/icons/Close'
import VisibilityIcon from '@material-ui/icons/Visibility'
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff'
import useSWR from 'swr'

export type Option = {
  label: string
  value: string
  disabled?: boolean
}

export type OptionsConfig = Array<Option | string>

export type DynamicOptionsConfig = {
  endpoint: string
  resolve: (any) => Option[]
}

export type Value = string | string[] | boolean

export type Props = {
  className?: string
  children?: React.ReactNode
  name: string
  value?: Value
  defaultValue?: string
  options?: OptionsConfig
  dynamicOptions?: DynamicOptionsConfig
  label: string
  type?: 'radio' | 'multiple' | 'select' | 'checkboxList' | 'text' | 'email' | 'password' | string
  placeholder?: string
  onChange?: (value: any) => void
  autoComplete?: string
  validation?: ValidationProps
  register?: any
  spellCheck?: boolean
  variant?: 'standard' | 'filled' | 'outlined'
  margin?: 'dense' | undefined
  size?: 'small' | 'medium' | undefined
  labelStyle?: 'default' | 'above'
  multiline?: boolean
  readOnly?: boolean
  chooseLabel?: string
  clearLabel?: string
  unselectedLabel?: string
  applyLabel?: string
  errors?: any
  fullWidth?: boolean
}

export type ValidationProps = {
  required?: boolean | string
  pattern?: {
    value: RegExp
    message: string
  }
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      '& > *': {
        width: '100%',
      },
      '& input[type=password]': {
        marginRight: 38,
      },
      '& .MuiFormLabel-root.Mui-error': {
        color: 'rgba(0, 0, 0, 0.54)',
      },
    },
    passwordEye: {
      position: 'absolute',
      top: 28,
      right: theme.spacing(1.5),
      transform: 'translateY(-50%)',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      opacity: 0.53,
      transition: 'opacity 300ms ease',
      '&:hover': {
        opacity: 0.75,
      },
    },
    formLabel: {
      marginBottom: theme.spacing(2),
      userSelect: 'none',
      '&.Mui-focused': {
        color: theme.palette.grey[600],
      },
    },
    unselectedLabel: {
      color: theme.palette.text.disabled,
    },
    radio: {
      marginTop: theme.spacing(-1),
      marginBottom: theme.spacing(-1),
    },
    fieldset: {
      display: 'block',
      userSelect: 'none',
    },
    checkbox: {
      marginTop: theme.spacing(-1),
      marginBottom: theme.spacing(-1),
    },
    dialog: {
      userSelect: 'none',
    },
    dialogTitle: {
      borderBottom: `1px solid ${theme.palette.grey[300]}`,
      textAlign: 'center',
    },
    dialogCloseButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      margin: theme.spacing(1),
    },
    dialogContent: {
      margin: theme.spacing(6, 6, 0),
      overflow: 'scroll',
    },
    dialogActions: {
      margin: theme.spacing(3, 6),
    },
    dialogCheckboxes: {
      display: 'grid',
      gridTemplateColumns: 'repeat(3, 1fr)',
      gap: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
      marginBottom: theme.spacing(2),
    },
    dialogCheckbox: {
      whiteSpace: 'nowrap',
    },
    multipleControls: {
      display: 'flex',
      '& > :last-child': {
        flexBasis: '40%',
      },
      '& > :first-child': {
        flexBasis: '60%',
        marginRight: theme.spacing(1),
      },
    },
    errorText: {
      color: theme.palette.error.main,
    },
    readOnly: {
      wordBreak: 'break-word',
    },
  }),
)

function normalizeOptions(options?: OptionsConfig): Option[] {
  if (options === undefined || options.length === 0) {
    return []
  }

  let normalized: Option[]
  if (options[0] && typeof options[0] === 'string') {
    normalized = options.map((o) => ({
      value: o as string,
      label: o as string,
    }))
  } else {
    normalized = options as Option[]
  }
  return normalized
}

function labelsFromOptions(values: string[], options: OptionsConfig) {
  if (typeof options !== 'undefined' && options[0]) {
    if (typeof options[0] === 'string') {
      return values
    } else {
      return values.map((v) => {
        return ((options as Option[]).find((o: Option) => o.value === v) as Option).label
      })
    }
  }
  return []
}

export const Field: React.FC<Props> = (props) => {
  const [showPassword, setShowPassword] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [unsavedValues, setUnsavedValues] = useState<string[]>([])

  const classes = useStyles()
  const inputProps: any = {}

  const type = showPassword ? 'text' : props.type
  if ((showPassword ? false : props.spellCheck) === false) {
    inputProps.spellCheck = false
  }

  if (props.type === 'email') {
    inputProps.spellCheck = false
  }

  let field: any

  const dynamicOptionsSWR = useSWR(props.dynamicOptions?.endpoint as string, {
    revalidateOnFocus: false,
  })
  const dynamicOptions = dynamicOptionsSWR?.data && props.dynamicOptions?.resolve(dynamicOptionsSWR.data)
  let options = dynamicOptions ?? normalizeOptions(props.options as OptionsConfig)
  const values = Array.isArray(props.value) ? props.value : []

  const toggleCheckbox = (value: string) => () => {
    if (values.includes(value)) {
      values.splice(values.indexOf(value), 1)
    } else {
      values.push(value)
    }
    if (props.onChange) {
      props.onChange(values)
    }
  }

  const toggleCheckboxForUnsaved = (value: string) => () => {
    const newValues = [...unsavedValues]
    if (newValues.includes(value)) {
      newValues.splice(newValues.indexOf(value), 1)
    } else {
      newValues.push(value)
    }
    setUnsavedValues(newValues)
  }

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (props.onChange) {
      props.onChange(event.target.value)
    }
  }

  switch (props.type) {
    case 'checkbox':
      const toggleChecked = () => {
        if (props.onChange) {
          props.onChange(!props.value)
        }
      }
      field = (
        <FormControl component="fieldset" className={classes.fieldset}>
          <FormLabel className={classes.formLabel} component="legend">
            {props.label}
          </FormLabel>

          <FormControlLabel
            className={classes.checkbox}
            control={<Checkbox checked={props.value as boolean} onClick={toggleChecked} color="primary" />}
            label="表示する"
          />
        </FormControl>
      )
      break

    case 'checkboxList':
      field = (
        <FormControl component="fieldset" className={classes.fieldset}>
          <FormLabel className={classes.formLabel} component="legend">
            {props.label}
          </FormLabel>

          {options.map((option) => (
            <FormControlLabel
              key={option.value}
              className={classes.checkbox}
              control={<Checkbox checked={values.includes(option.value)} onClick={toggleCheckbox(option.value)} color="primary" />}
              label={option.label}
            />
          ))}
        </FormControl>
      )
      break

    case 'radio':
      field = (
        <FormControl component="fieldset">
          <FormLabel className={classes.formLabel} component="legend">
            {props.label}
          </FormLabel>

          <RadioGroup value={props.value} onChange={handleChange}>
            {options.map((option) => (
              <FormControlLabel
                key={option.value}
                className={classes.radio}
                control={<Radio />}
                label={option.label}
                value={option.value}
              />
            ))}
          </RadioGroup>
        </FormControl>
      )
      break

    case 'multiple':
      if (Array.isArray(props.value)) {
        const openDialog = () => {
          if (Array.isArray(props.value)) {
            setUnsavedValues(props.value)
          }
          setDialogOpen(true)
        }
        const closeDialog = () => {
          setDialogOpen(false)
        }
        const saveChanges = () => {
          if (props.onChange) {
            props.onChange(unsavedValues)
          }
          setDialogOpen(false)
        }
        const clearSelected = () => {
          if (props.onChange) {
            props.onChange([])
          }
        }

        const selectedLabels = labelsFromOptions(props.value, options)

        field = (
          <>
            <FormControl>
              <FormLabel className={classes.formLabel}>{props.label}</FormLabel>

              {props.value.length ? (
                <Typography color="primary" className={classes.formLabel}>
                  {selectedLabels.join(', ')}
                </Typography>
              ) : (
                <Typography className={[classes.formLabel, classes.unselectedLabel].join(' ')}>
                  {props.unselectedLabel ?? '未選択'}
                </Typography>
              )}

              <div className={classes.multipleControls}>
                <Button variant="contained" disableElevation size="large" onClick={openDialog}>
                  {props.chooseLabel ?? '選択'}
                </Button>

                <Button variant="outlined" disableElevation size="large" onClick={clearSelected}>
                  {props.clearLabel ?? 'クリア'}
                </Button>
              </div>
            </FormControl>

            <Dialog onClose={closeDialog} open={dialogOpen} maxWidth="xl" className={classes.dialog}>
              <IconButton aria-label="Close" className={classes.dialogCloseButton} onClick={closeDialog}>
                <CloseIcon />
              </IconButton>

              <DialogTitle className={classes.dialogTitle}>{props.label}</DialogTitle>

              <DialogContent className={classes.dialogContent}>
                <FormControl component="fieldset">
                  <div className={classes.dialogCheckboxes}>
                    {options.map((option) => (
                      <FormControlLabel
                        key={option.value}
                        className={[classes.checkbox, classes.dialogCheckbox].join(' ')}
                        control={
                          <Checkbox
                            checked={unsavedValues.includes(option.value)}
                            onChange={toggleCheckboxForUnsaved(option.value)}
                            color="primary"
                          />
                        }
                        label={option.label}
                      />
                    ))}
                  </div>
                </FormControl>
              </DialogContent>
              <DialogActions className={classes.dialogActions}>
                <Button size="large" color="primary" variant="contained" disableElevation onClick={saveChanges}>
                  {props.applyLabel ?? '適用する'}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )
      }
      break

    case 'select':
      field = (
        <FormControl variant={props.variant ?? 'standard'} size={props.size}>
          {props.labelStyle === 'above' ? (
            <FormLabel className={classes.formLabel}>{props.label}</FormLabel>
          ) : (
            <InputLabel margin={props.margin}>{props.label}</InputLabel>
          )}

          <Select
            name={props.name}
            value={props.value}
            label={props.labelStyle !== 'above' ? props.label : undefined}
            onChange={handleChange}
            variant={props.variant ?? 'standard'}
            margin={props.margin}
          >
            {(options as Option[]).map((option) => (
              <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                {option.label}
              </MenuItem>
            ))}
          </Select>

          {props.validation?.required && <FormHelperText>Required</FormHelperText>}
        </FormControl>
      )
      break

    case 'text':
    case 'email':
    case 'password':
    default:
      if (props.readOnly) {
        field = (
          <>
            <Typography variant="caption">{props.label}</Typography>
            <Typography className={classes.readOnly}>{props.value}</Typography>
          </>
        )
      } else {
        field = (
          <FormControl error={props.errors?.[props.name]}>
            {props.labelStyle === 'above' && <FormLabel className={classes.formLabel}>{props.label}</FormLabel>}

            <TextField
              name={props.name}
              value={props.value}
              label={props.labelStyle !== 'above' && props.label}
              placeholder={props.placeholder}
              type={type}
              inputRef={props.register?.(props.validation)}
              defaultValue={props.defaultValue}
              inputProps={inputProps}
              className={props.className}
              onChange={handleChange}
              margin={props.margin}
              multiline={props.multiline}
              variant={props.variant ?? 'standard'}
              rows={props.multiline ? 4 : 1}
              size={props.size}
              error={props.errors?.[props.name]}
              fullWidth={props.fullWidth}
            />

            {props.type === 'password' && (
              <div
                className={classes.passwordEye}
                onClick={(e) => {
                  e.preventDefault()
                  setShowPassword(!showPassword)
                }}
              >
                {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </div>
            )}

            {props.errors?.[props.name] && (
              <FormHelperText className={classes.errorText}>{props.errors[props.name].message}</FormHelperText>
            )}
          </FormControl>
        )
      }
  }

  return <div className={[classes.root, props.className].join(' ')}>{field}</div>
}
