import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { ListItem, ListItemIcon, ListItemText, Collapse } from '@material-ui/core'
import ArrowIcon from '@material-ui/icons/KeyboardArrowDown'

export type Props = {
  className?: string
  title: string
  icon?: JSX.Element
  children?: Props[]
  onClick?: void
  path?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    childNavItem: {
      paddingLeft: theme.spacing(4),
    },
    arrowIcon: {
      display: 'flex',
      justifyContent: 'center',
      transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.short,
      }),
      transformOrigin: '50% 50%',
    },
    arrowIconRotate: {
      transform: 'rotate(-180deg)',
    },
  }),
)

export const NavItem: React.FC<Props> = (props) => {
  const [childrenVisible, setChildrenVisible] = useState<boolean>(false)
  const classes = useStyles()

  const handleClick =
    props.onClick ||
    (() => {
      if (props.children) {
        setChildrenVisible(!childrenVisible)
      }
    })

  const listItem = (
    <>
      <ListItem button onClick={handleClick} className={props.className}>
        {props.icon && <ListItemIcon>{props.icon}</ListItemIcon>}

        <ListItemText primary={props.title} />

        {props.children?.length && (
          <div className={[classes.arrowIcon, ...(childrenVisible ? [classes.arrowIconRotate] : [])].join(' ')}>
            <ArrowIcon />
          </div>
        )}
      </ListItem>

      {props.children?.length && (
        <Collapse in={childrenVisible}>
          {props.children.map((item, key) => (
            <NavItem {...item} key={key} className={classes.childNavItem} />
          ))}
        </Collapse>
      )}
    </>
  )

  if (props.path) {
    return <Link to={props.path}>{listItem}</Link>
  }

  return listItem
}
