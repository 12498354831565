import React from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import ArrowIcon from '@material-ui/icons/KeyboardArrowDown'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    arrowIcon: {
      display: 'flex',
      justifyContent: 'center',
      transition: theme.transitions.create(['transform'], {
        duration: theme.transitions.duration.short,
      }),
      transformOrigin: '50% 50%',
    },
    arrowIconRotate: {
      transform: 'rotate(180deg)',
    },
  }),
)

type Props = {
  expanded: boolean
  onClick?: () => void
}

export const ExpandButton: React.FC<Props> = (props) => {
  const classes = useStyles()
  return (
    <div className={[classes.arrowIcon, ...(!props.expanded ? [classes.arrowIconRotate] : [])].join(' ')} onClick={props.onClick}>
      <ArrowIcon />
    </div>
  )
}
