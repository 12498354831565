import React from 'react'
import { ScoutContainer } from '@/components/containers/scout'
import { useTitle } from '@/hooks/use-title'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import PeopleIcon from '@material-ui/icons/People'

type Props = {
  view?: 'students' | 'scout'
}

export const ScoutPage: React.FC<Props> = (props) => {
  useTitle(props.view === 'students' ? '学生一覧' : 'スカウト', props.view === 'students' ? <PeopleIcon /> : <AssignmentIndIcon />)
  return <ScoutContainer {...props} />
}
