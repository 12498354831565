import React from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Paper, Button } from '@material-ui/core'
import { Field, Props as FieldProps } from '@/components/molecules/field'
import { useForm } from 'react-hook-form'

export type Fields = {
  [fieldName: string]: FieldProps
}

type Props = {
  children?: React.ReactNode
  fields: Fields
  onSubmit?: any
  submitLabel: string
  error?: React.ReactNode
  caption?: string
  success?: React.ReactNode
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 600,
      padding: theme.spacing(10),
      margin: theme.spacing(2),
      '&, & > *': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'stretch',
      },
      '& button': {
        alignSelf: 'center',
      },
      '& form > *': {
        margin: theme.spacing(2, 0),
      },
      '& p': {
        ...theme.typography.body2,
        textAlign: 'left',
        color: theme.palette.grey[700],
      },
    },
    error: {
      ...theme.typography.body2,
      color: theme.palette.error.main,
    },
    success: {
      '& .MuiButton-root': {
        marginTop: theme.spacing(4),
      },
    },
  }),
)

export const LoginCard: React.FC<Props> = (props: Props) => {
  const { register, handleSubmit, errors } = useForm()
  const classes = useStyles()

  return (
    <div>
      <Paper className={classes.root} variant="outlined">
        {props.success ? (
          <div className={classes.success}>{props.success}</div>
        ) : (
          <>
            {props.caption && <p>{props.caption}</p>}

            <form onSubmit={handleSubmit(props.onSubmit)}>
              {Object.keys(props.fields).map((key) => (
                <Field key={key} {...props.fields[key]} register={register} errors={errors} />
              ))}

              {props.error && <div className={classes.error}>{props.error}</div>}

              <Button variant="contained" color="primary" disableElevation type="submit" size="large">
                {props.submitLabel}
              </Button>
            </form>
          </>
        )}
      </Paper>
    </div>
  )
}
