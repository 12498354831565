import { useContext } from 'react'
import { AppContext } from './components/apps/app'

export const PERSIST_LOCAL_STORAGE_KEY = 'app'

export const useAppContext = () => {
  return useContext(AppContext)
}

export const useDispatch = () => {
  return useContext(AppContext).dispatch
}

export const getPersistedState = () => {
  try {
    const storedState = localStorage.getItem(PERSIST_LOCAL_STORAGE_KEY)
    if (!storedState) {
      return
    }
    return JSON.parse(storedState)
  } catch (e) {}
}

const whitelistState = (state: object) => {
  const whitelist = ['session', 'chat', 'selectedYear', 'selectedDivision']
  return Object.keys(state)
    .filter((key) => whitelist.includes(key))
    .reduce((obj: object, key: string) => {
      obj[key] = state[key]
      return obj
    }, {})
}

export const persistState = (state) => {
  localStorage.setItem(PERSIST_LOCAL_STORAGE_KEY, JSON.stringify(whitelistState(state)))
}
