import React, { useState, useRef, useEffect, useContext } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Paper, Avatar, CircularProgress, Button } from '@material-ui/core'
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary'
import { Field } from '@/components/molecules/field'
import { PageContent } from '@/components/organisms/page-content'
import useSWR from 'swr'
import { useSession } from '@/hooks/use-session'
import { caxios } from '@/submodules/custom-axios'
import { useDispatch } from '@/store'
import { SnackbarContext } from '@/providers/snackbar-provider'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      padding: theme.spacing(6),
    },
    avatarColumn: {
      flex: '0 0 auto',
    },
    avatarContainer: {
      position: 'relative',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '100%',
      cursor: 'pointer',
      '&:hover $avatarIcon': {
        opacity: 1,
      },
      '&:hover $avatar': {
        filter: 'brightness(50%)',
      },
      pointerEvents: (props: any) => props.loading && 'none',
    },
    avatar: {
      width: theme.spacing(12),
      height: theme.spacing(12),
      background: '#64B6F7',
      transition: theme.transitions.create(['filter'], {
        duration: theme.transitions.duration.short,
      }),
      '& .MuiAvatar-fallback': {
        padding: theme.spacing(1),
      },
      filter: (props: any) => props.loading && 'brightness(50%)',
    },
    avatarIcon: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      color: 'white',
      transition: theme.transitions.create(['opacity'], {
        duration: theme.transitions.duration.short,
      }),
      opacity: 0,
      pointerEvents: 'none',
    },
    spinner: {
      position: 'absolute',
      color: 'white',
    },
    detailsColumn: {
      flex: '0 1 350px',
      display: 'flex',
      flexDirection: 'column',
    },
    detailsForm: {
      display: 'grid',
      gap: theme.spacing(2) + 'px',
    },
    saveButton: {
      marginTop: theme.spacing(2),
      marginLeft: 'auto',
    },
    field: {
      '& textarea': {
        height: 339,
      },
    },
  }),
)

export const RepresentativeContainer: React.FC = () => {
  const user = useSession()
  const dispatch = useDispatch()
  const inputRef = useRef<HTMLInputElement>(null)
  const [iconUrl, setIconUrl] = useState('')
  const [loading, setLoading] = useState(false)
  const { isAdmin } = useSession()
  const classes = useStyles({ loading })
  const endpoint = isAdmin ? '/admins/staffs' : '/companies/personnels'
  const { data } = useSWR(endpoint)
  const rep = data && (isAdmin ? data.staff : data)
  const { setSnackbarMessage } = useContext(SnackbarContext)

  const [fields, setFields] = useState({
    name: {
      name: 'name',
      label: '担当者名',
      value: user.name,
    },
    email: {
      name: 'email',
      label: 'メールアドレス',
      value: user.email,
      readOnly: true,
    },
    description: {
      name: 'description',
      label: '説明文',
      value: user.description,
      multiline: true,
      className: classes.field,
    },
  })

  useEffect(() => {
    if (rep) {
      setFields({
        ...fields,
        name: {
          ...fields.name,
          value: rep.name,
        },
        description: {
          ...fields.description,
          value: rep.description,
        },
      })
    }
  }, [rep])

  useEffect(() => {
    setIconUrl(user.iconUrl)
  }, [user])

  const handleChange = (fieldName: string) => (value: string | string[]) => {
    const newFields = {
      ...fields,
      [fieldName]: {
        ...fields[fieldName],
        value,
      },
    }
    setFields(newFields)
  }

  const handleUpload = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0]
    if (!file) {
      return
    }
    setLoading(true)
    const reader = new FileReader()
    reader.onload = () => {
      setIconUrl(reader.result as string)
    }
    reader.readAsDataURL(file)
    const formData = new FormData()
    formData.append('image', file)
    const res = await caxios.post('/medias/images', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    await caxios.put(endpoint, {
      iconUrl: res.data.url,
    })
    setLoading(false)
    setIconUrl(res.data.url)
    dispatch({ type: 'SET_ICON_URL', payload: { iconUrl: res.data.url } })
  }

  const saveAndReturn = async () => {
    caxios.put(endpoint, {
      name: fields.name.value,
      description: fields.description.value,
    })
    setSnackbarMessage('保存しました')
    dispatch({
      type: 'UPDATE_SESSION',
      payload: {
        name: fields.name.value,
        description: fields.description.value,
      },
    })
  }

  return (
    <PageContent>
      <input hidden name="files" type="file" accept="image/*" multiple onChange={handleUpload} ref={inputRef} />
      <Paper className={classes.paper} variant="outlined">
        <div className={classes.avatarColumn}>
          <div className={classes.avatarContainer} onClick={() => inputRef.current?.click()}>
            <Avatar className={classes.avatar} src={iconUrl} />
            <PhotoLibraryIcon className={classes.avatarIcon} />
            {loading && <CircularProgress className={classes.spinner} />}
          </div>
        </div>
        <div className={classes.detailsColumn}>
          <div className={classes.detailsForm}>
            {Object.keys(fields).map((key) => (
              <Field key={key} variant="outlined" {...fields[key]} onChange={handleChange(key)} />
            ))}
          </div>
          <Button variant="contained" size="large" disableElevation color="primary" className={classes.saveButton} onClick={saveAndReturn}>
            変更する
          </Button>
        </div>
      </Paper>
    </PageContent>
  )
}
