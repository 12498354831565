import React, { useState, useMemo } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import {
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableSortLabel,
  TableBody,
  TableRow,
  TableCell,
  Checkbox,
  CircularProgress,
} from '@material-ui/core'
import { parse } from 'date-fns'
import { EmptyPlaceholder } from '@/components/organisms/empty-placeholder'

export interface Column<Row> {
  id: keyof Row
  label: React.ReactNode
  numeric?: boolean
  align?: 'left' | 'right'
  sort?: boolean
  suffix?: string
  className?: string
  maxWidth?: number
}

type Props<Row> = {
  className?: string
  columns: Column<Row>[]
  rows: Row[]
  checked?: number[]
  headerDisabled?: boolean
  setChecked?: (c: number[]) => void
  rowControls?: (r: Row) => React.ReactNode
  onRowClick?: (r: Row) => void
  disableCheckbox?: (r: Row) => boolean
  square?: boolean
  isValidating: boolean
  placeholderText?: string
}

type Order = 'desc' | 'asc' | undefined

function sortedRows<Row>(rows: Row[], orderBy: string, order: Order) {
  if (!rows?.length) {
    return []
  }
  const newRows = rows.map((r, i) => ({
    ...r,
    index: i,
  }))

  if (typeof rows[0][orderBy] === 'string') {
    if (rows[0][orderBy].match(/^\d{4}\/(0?[1-9]|1[012])\/(0?[1-9]|[12][0-9]|3[01])$/)) {
      // If it's a date formatted as: yyyy/M/d
      newRows.sort((a, b) => {
        const aDate = parse(a[orderBy], 'yyyy/M/d', new Date())
        const bDate = parse(b[orderBy], 'yyyy/M/d', new Date())
        return aDate.getTime() - bDate.getTime()
      })
    } else if (rows[0][orderBy].match(/^[0-9]+(\.[0-9]+)?\/[0-9]+(\.[0-9]+)?/)) {
      // If it's a string formatted as a fraction, eg: 12/34
      // Sort fractions
      newRows.sort((a, b) => {
        const [a1, a2] = a[orderBy].split('/').map((s: string) => parseFloat(s))
        const aFr = a1 / a2 || 0
        const [b1, b2] = b[orderBy].split('/').map((s: string) => parseFloat(s))
        const bFr = b1 / b2 || 0
        return aFr - bFr
      })
    } else {
      // Just sort strings alphabetically
      newRows.sort((a, b) => a[orderBy].localeCompare(b[orderBy]))
    }

    if (order === 'desc') {
      newRows.reverse()
    }

    return newRows
  }

  newRows.sort((a, b) => {
    return order === 'desc' ? b[orderBy] - a[orderBy] : a[orderBy] - b[orderBy]
  })
  return newRows
}

function columnForId<Row>(id: keyof Row, columns: Column<Row>[]) {
  return columns.find((c) => c.id === id)
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      overflow: 'hidden',
    },
    cell: {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      maxWidth: 300,
      userSelect: 'none',
    },
    row: {
      transition: theme.transitions.create(['background-color'], {
        duration: theme.transitions.duration.short,
      }),
      '&:hover': {
        backgroundColor: theme.palette.grey[100],
      },
    },
    pointerCursor: {
      cursor: 'pointer',
    },
    headingCell: {
      fontWeight: theme.typography.fontWeightMedium,
      userSelect: 'none',
      transition: theme.transitions.create(['background-color'], {
        duration: theme.transitions.duration.short,
      }),
      '&:active': {
        backgroundColor: theme.palette.grey[300],
      },
    },
    headingCellActive: {
      backgroundColor: theme.palette.grey[200],
    },
    headingCellContainer: {
      display: 'flex',
      justifyContent: 'space-between',
    },
    checkboxCell: {
      width: 1,
      padding: theme.spacing(0, 0, 0, 1),
      '&:hover': {
        backgroundColor: 'transparent',
      },
      cursor: 'default',
    },
    checkbox: {
      margin: 0,
    },
    rowControls: {
      padding: theme.spacing(0, 1),
      width: 1,
      whiteSpace: 'nowrap',
    },
    table: {
      '& tr:last-child td': {
        borderBottom: 'none',
      },
    },
    placeholderWrapper: {
      padding: '50px',
    },
    loadingWrapper: {
      display: 'flex',
      justifyContent: 'center',
      whiteSpace: 'pre-wrap',
      textAlign: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
  }),
)

interface BaseRow {
  id: number
}

export const BasicTable = <Row extends BaseRow>(props: Props<Row>) => {
  const classes = useStyles()
  const [orderBy, setOrderBy] = useState<string>('')
  const [order, setOrder] = useState<Order>('desc')

  const fetchingRows = props.isValidating && !props.rows?.length
  const noRows = !props.isValidating && !props.rows?.length

  const handleSetOrder = (property: keyof Row) => (_event: React.MouseEvent<unknown>) => {
    if (!props.columns) return
    const column = columnForId(property, props.columns)
    if (!column) {
      return
    }

    if (orderBy === property) {
      setOrder(order !== 'desc' ? 'desc' : 'asc')
    } else {
      setOrder('desc')
      setOrderBy(property as string)
    }
  }

  const rows = useMemo(() => sortedRows<Row>(props.rows, orderBy, order), [props.rows, orderBy, order])

  const cellClasses = [classes.cell]
  if (props.onRowClick) {
    cellClasses.push(classes.pointerCursor)
  }

  const filteredRows = rows.filter((r) => !props.disableCheckbox?.(r) ?? true)

  return (
    <Paper variant="outlined" square={props.square} className={classes.paper}>
      <TableContainer>
        <Table className={classes.table}>
          {!props.headerDisabled && (
            <TableHead>
              <TableRow>
                {typeof props.checked !== 'undefined' && (
                  <TableCell className={[classes.cell, classes.checkboxCell, props.className].join(' ')}>
                    <Checkbox
                      className={classes.checkbox}
                      color="primary"
                      edge="start"
                      checked={filteredRows.length > 0 && props.checked.length === filteredRows.length}
                      tabIndex={-1}
                      onClick={() => {
                        if (Array.isArray(props.checked) && props.setChecked) {
                          props.setChecked(props.checked.length !== filteredRows.length ? filteredRows.map((r) => r.id) : [])
                        }
                      }}
                    />
                  </TableCell>
                )}

                {props.columns.map((column) => (
                  <TableCell
                    key={column.id as string}
                    className={[classes.headingCell, ...(orderBy === column.id ? [classes.headingCellActive] : [])].join(' ')}
                    onClick={handleSetOrder(column.id)}
                    style={{ maxWidth: column.maxWidth }}
                  >
                    <div className={classes.headingCellContainer}>
                      {column.label}
                      <TableSortLabel
                        active={orderBy === column.id}
                        direction={orderBy === column.id ? order : 'asc'}
                        onClick={handleSetOrder(column.id)}
                      />
                    </div>
                  </TableCell>
                ))}

                {props.rowControls && <TableCell />}
              </TableRow>
            </TableHead>
          )}

          <TableBody>
            {rows.map((row, index) => (
              <TableRow className={classes.row} key={index} onClick={() => props.onRowClick?.(row)}>
                {typeof props.checked !== 'undefined' && (
                  <TableCell className={[classes.cell, classes.checkboxCell].join(' ')} onClick={(e) => e.stopPropagation()}>
                    <Checkbox
                      className={classes.checkbox}
                      color="primary"
                      edge="start"
                      checked={props.checked.includes(row.id)}
                      tabIndex={-1}
                      disabled={props.disableCheckbox?.(row) ?? false}
                      onClick={() => {
                        if (Array.isArray(props.checked) && props.setChecked) {
                          const newChecked = [...props.checked]
                          if (props.checked.indexOf(row.id) === -1) {
                            newChecked.push(row.id)
                          } else {
                            newChecked.splice(newChecked.indexOf(row.id), 1)
                          }
                          props.setChecked(newChecked)
                        }
                      }}
                    />
                  </TableCell>
                )}
                {props.columns.map((column) => {
                  const classes = [...cellClasses]
                  if (column.className) {
                    classes.push(column.className)
                  }
                  return (
                    <TableCell
                      key={column.id as string}
                      className={classes.join(' ')}
                      align={column.numeric ? 'right' : column.align ?? 'left'}
                      style={{ maxWidth: column.maxWidth }}
                    >
                      {row[column.id]}
                      {column.suffix}
                    </TableCell>
                  )
                })}
                {props.rowControls && (
                  <TableCell onClick={(e) => e.stopPropagation()} className={classes.rowControls}>
                    {props.rowControls(row)}
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {fetchingRows && (
        <div className={classes.loadingWrapper + ' ' + classes.placeholderWrapper}>
          <CircularProgress />
        </div>
      )}
      {noRows && (
        <div className={classes.placeholderWrapper}>
          <EmptyPlaceholder bodyText={props.placeholderText}></EmptyPlaceholder>
        </div>
      )}
    </Paper>
  )
}
