import React from 'react'
import { createMuiTheme, ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles'
import { CssBaseline } from '@material-ui/core'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#f03177',
      light: '#feebf1',
    },
    secondary: {
      main: 'rgba(0, 0, 0, 0.54)',
    },
    background: {
      default: '#f5f5f5',
    },
  },
  overrides: {
    MuiButton: {
      contained: {
        backgroundColor: 'rgba(0, 0, 0, 0.04)',
      },
    },
  },
})

export const ThemeProvider: React.FC = (props) => {
  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </MuiThemeProvider>
  )
}
