import React from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Drawer, Typography } from '@material-ui/core'
import { Image } from '@/components/atoms/image'
import { NavList } from '@/components/molecules/nav-list'
import HomeIcon from '@material-ui/icons/Home'
import PeopleIcon from '@material-ui/icons/People'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import SettingsIcon from '@material-ui/icons/Settings'
import BusinessIcon from '@material-ui/icons/Business'
import { useSession } from '@/hooks/use-session'

export const appSidebarWidth = 260

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: appSidebarWidth,
      flexShrink: 0,
      userSelect: 'none',
    },
    paper: {
      width: appSidebarWidth,
    },
    logo: {
      padding: theme.spacing(2.5, 4, 0),
      marginTop: theme.spacing(1),
    },
    subtitle: {
      ...theme.typography.body2,
      textAlign: 'center',
      marginTop: theme.spacing(0.75),
      marginBottom: theme.spacing(3),
    },
  }),
)

export const AppSidebar: React.FC = () => {
  const classes = useStyles()
  const { isAdmin } = useSession()

  const navItems = isAdmin
    ? [
        {
          title: '学生一覧',
          icon: <PeopleIcon />,
          path: '/students',
        },
        {
          title: 'クライアント一覧',
          icon: <BusinessIcon />,
          path: '/clients',
        },
        {
          title: '設定',
          icon: <SettingsIcon />,
          children: [
            {
              title: '担当者情報',
              path: '/settings/representative',
            },
            {
              title: 'チャットテンプレート',
              path: '/settings/chat-templates',
            },
          ],
        },
      ]
    : [
        {
          title: 'ホーム',
          icon: <HomeIcon />,
          path: '/',
        },
        {
          title: '応募者一覧',
          icon: <PeopleIcon />,
          children: [
            {
              title: 'エントリー',
              path: '/students/entry',
            },
            {
              title: '説明会',
              path: '/students/info-session',
            },
            {
              title: 'インターン',
              path: '/students/interns',
            },
          ],
        },
        {
          title: 'スカウト',
          icon: <AssignmentIndIcon />,
          path: '/scout',
        },
        {
          title: '設定',
          icon: <SettingsIcon />,
          children: [
            {
              title: '選考フェーズ',
              path: '/settings/selection-phases',
            },
            {
              title: 'チャットテンプレート',
              path: '/settings/chat-templates',
            },
            {
              title: '担当者情報',
              path: '/settings/representative',
            },
            {
              title: 'パスワード変更',
              path: '/settings/password',
            },
          ],
        },
      ]

  return (
    <Drawer className={classes.root} variant="permanent" anchor="left" PaperProps={{ elevation: 4, className: classes.paper }}>
      <Image className={classes.logo} src="/logo.svg" />
      <Typography className={classes.subtitle}>{isAdmin ? '運営者用管理画面' : '企業様用管理画面'}</Typography>
      <NavList items={navItems} />
    </Drawer>
  )
}
