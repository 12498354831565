import React, { useContext, useState } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'
import { Paper, Button, FormHelperText } from '@material-ui/core'
import { PageContent } from '@/components/organisms/page-content'
import { Field } from '@/components/molecules/field'
import { caxios } from '@/submodules/custom-axios'
import { useSession } from '@/hooks/use-session'
import { SnackbarContext } from '@/providers/snackbar-provider'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      padding: theme.spacing(4, 12, 3, 12),
    },
    form: {
      margin: 'auto',
      width: '100%',
      display: 'grid',
      gap: theme.spacing(2) + 'px',
      flexDirection: 'column',
    },
    saveButton: {
      marginTop: theme.spacing(1),
      marginLeft: 'auto',
    },
    errorText: {
      color: theme.palette.error.main,
      textAlign: 'center',
    },
  }),
)

export const PasswordSettingsContainer: React.FC = (props) => {
  const classes = useStyles()
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const { isAdmin } = useSession()
  const endpoint = isAdmin ? 'admins' : 'companies'
  const [error, setError] = useState('')
  const { setSnackbarMessage } = useContext(SnackbarContext)

  const fields = {
    currentPassword: {
      name: 'currentPassword',
      type: 'password',
      label: '現在のパスワード',
      value: currentPassword,
      onChange: setCurrentPassword,
    },
    newPassword: {
      name: 'newPassword',
      type: 'password',
      label: '新しいパスワード',
      value: newPassword,
      onChange: setNewPassword,
    },
  }

  const submit = async () => {
    if (!currentPassword && !newPassword) return
    const data = { currentPassword: currentPassword, password: newPassword }
    try {
      await caxios.put(`/${endpoint}/personnels/password`, data)
      setError('')
      setCurrentPassword('')
      setNewPassword('')
      setSnackbarMessage('パスワードを保存しました')
    } catch (err) {
      if (err.response?.data.message) {
        setError('現在のパスワードが間違っています')
        return
      }
    }
  }

  return (
    <PageContent>
      <Paper className={classes.paper} variant="outlined">
        <form className={classes.form}>
          <Field variant="outlined" {...fields.currentPassword} />
          <Field variant="outlined" {...fields.newPassword} />

          {error && <FormHelperText className={classes.errorText}>{error}</FormHelperText>}

          <Button
            variant="contained"
            size="large"
            disableElevation
            color="primary"
            className={classes.saveButton}
            onClick={submit}
            disabled={!currentPassword && !newPassword}
          >
            変更する
          </Button>
        </form>
      </Paper>
    </PageContent>
  )
}
