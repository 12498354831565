import React from 'react'
import styled from 'styled-components'

type Props = {
  children?: React.ReactNode
  color?: string
  fontSize?: string
  width?: string
  fontWeight?: string
  textAlign?: string
  lineHeight?: string
}

const StyledText = styled.p`
  color: ${(props: Props) => props.color};
  width: ${(props: Props) => props.width};
  font-weight: ${(props: Props) => props.fontWeight};
  font-size: ${(props: Props) => props.fontSize};
  text-align: ${(props: Props) => props.textAlign};
  margin: 0;
  white-space: pre-wrap;
  line-height: ${(props: Props) => props.lineHeight};
`

export const Text: React.FC<Props> = (props: Props) => {
  return <StyledText {...props}>{props.children}</StyledText>
}
