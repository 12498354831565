import React from 'react'
import styled from 'styled-components'
import { font } from 'styled-theme'
import { Link as RouterLink, LinkProps } from 'react-router-dom'

type Props = {
  children?: React.ReactNode
  fontWeight?: string
  url: string
}

const StyledLink = styled(RouterLink)<LinkProps>`
  font-family: ${font('primary')};
  color: rgb(134, 134, 134);
`

export const Link: React.FC<Props> = (props: Props) => {
  return <StyledLink to={props.url}>{props.children}</StyledLink>
}
