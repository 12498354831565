import React, { useState, useEffect, useContext } from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { SectionTitle } from '@/components/atoms/section-title'
import { DashCardContainer, DashCard } from '@/components/molecules/dash-card'
import { PageContent } from '@/components/organisms/page-content'
import { Button, Paper, Typography, Dialog, DialogTitle, DialogContent, DialogActions, DialogContentText } from '@material-ui/core'
import { useTitle } from '@/hooks/use-title'
import BusinessIcon from '@material-ui/icons/Business'
import { Field } from '@/components/molecules/field'
import useSWR, { mutate } from 'swr'
import { useParams } from 'react-router-dom'
import { caxios } from '@/submodules/custom-axios'
import { SnackbarContext } from '@/providers/snackbar-provider'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    pageControls: {
      display: 'flex',
      margin: theme.spacing(4, 0),
      justifyContent: 'flex-end',
      '& > *:not(:last-child)': {
        marginRight: theme.spacing(1),
      },
    },
    dashCardContainer: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
    },
    paper: {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-end',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(4),
      padding: theme.spacing(5, 12),
      overflow: 'hidden',
    },
    paperOverlay: {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.87)',
      color: theme.palette.common.white,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      userSelect: 'none',
    },
    form: {
      display: 'grid',
      gap: theme.spacing(2) + 'px',
      width: '100%',
    },
    submitButton: {
      margin: `${theme.spacing(2)}px 0 0 auto`,
    },
  }),
)

interface Plan {
  id: number
  name: string
}

export const ClientPlanSettingsContainer: React.FC = () => {
  const classes = useStyles()
  const { id } = useParams()
  const { data } = useSWR(`/admins/companies/${id}`)
  const client = data?.company
  const { data: clientPlan } = useSWR(client ? `/admins/companies/${client.id}/plans` : null)
  const { data: planData } = useSWR('/admins/plans')
  const { data: extraCounts } = useSWR('/admins/plan_options/extra_counts')
  const { data: deadlines } = useSWR('/admins/plan_options/deadlines')
  const { setSnackbarMessage } = useContext(SnackbarContext)
  const plans = planData?.plans ?? []

  let title = 'クライアント一覧'
  if (client) {
    title += ` / ${client.name} / プラン`
  }
  useTitle(title, <BusinessIcon />)

  const [planId, setPlanId] = useState<number | string>('')
  const [numAdditional, setNumAdditional] = useState<string>('')
  const [deadline, setDeadline] = useState<string>('')

  const [showUpdatePlanConfirmation, setShowUpdatePlanConfirmation] = useState(false)
  const [showUpdatePlanOptionsConfirmation, setShowUpdatePlanOptionsConfirmation] = useState(false)

  useEffect(() => {
    if (clientPlan) {
      setPlanId(clientPlan.companyPlan.plan.id)
      // Check for scoutChatLimit to check if the planOptions have been set, because the API returns "unlimited" deadline if it's falsy
      if (clientPlan.planOption.scoutChatLimit) {
        setNumAdditional(clientPlan.planOption.scoutChatLimit)
        setDeadline(clientPlan.planOption.deadline)
      }
    }
  }, [clientPlan])

  const planOptions = plans.map((plan: Plan) => ({
    label: plan.name,
    value: plan.id,
  }))

  const planFields = {
    plan: {
      name: 'plan',
      label: 'プラン',
      value: planId,
      type: 'select',
      options: planOptions,
      onChange: setPlanId,
    },
  }

  const createOptionList = (options: string[], type: 'numAdditional' | 'deadline'): { label: string; value: string }[] => {
    if (!options) return [{ label: '', value: '0' }]
    return options.map((option) => {
      let optionLabel: string
      if (type === 'deadline' && String(parseInt(option, 10)) !== option) {
        optionLabel = option
      } else {
        optionLabel = type === 'numAdditional' ? `+ ${option}通` : `${option}日`
      }
      return { label: optionLabel, value: option }
    })
  }

  const optionFields = {
    numAdditional: {
      name: 'numAdditional',
      type: 'select',
      label: '追加件数',
      value: numAdditional,
      options: createOptionList(extraCounts?.extraCounts, 'numAdditional'),
      onChange: setNumAdditional,
    },
    expiration: {
      name: 'expiration',
      type: 'select',
      label: '使用期限',
      value: deadline,
      options: createOptionList(deadlines?.deadlines, 'deadline'),
      onChange: setDeadline,
    },
  }

  const optionFieldsValid = !!(numAdditional && deadline)

  const updatePlan = async () => {
    setShowUpdatePlanConfirmation(false)
    const { data } = await caxios.put(`/admins/companies/${id}/plans`, {
      planId,
    })

    mutate(`/admins/companies/${client.id}/plans`, data, false)
    setSnackbarMessage('プランを変更しました')
  }

  const updatePlanOptions = async () => {
    setShowUpdatePlanOptionsConfirmation(false)
    const { data } = await caxios.post(`/admins/companies/${id}/plans/options`, {
      scoutChatLimit: Number(numAdditional),
      deadline: String(deadline),
    })

    mutate(`/admins/companies/${client.id}/plans`, data, false)
    setSnackbarMessage('オプションを付与しました')
  }

  if (!clientPlan) {
    return null
  }

  const confirmUpdatePlan = () => {
    setShowUpdatePlanConfirmation(true)
  }

  const confirmUpdatePlanOptions = () => {
    setShowUpdatePlanOptionsConfirmation(true)
  }

  const handleCloseDialog = () => {
    setShowUpdatePlanConfirmation(false)
    setShowUpdatePlanOptionsConfirmation(false)
  }

  const scoutCount = clientPlan.companyPlan.scoutCount + clientPlan.planOption.scoutCount
  const scoutChatLimit = clientPlan.companyPlan.plan.scoutChatLimit + clientPlan.planOption.scoutChatLimit

  const disablePlanOptions = !!clientPlan.planOption.id && clientPlan.planOption.scoutCount < clientPlan.planOption.scoutChatLimit

  return (
    <PageContent>
      <SectionTitle>利用状況</SectionTitle>

      <DashCardContainer className={classes.dashCardContainer}>
        <DashCard label="チャット送信数" value={`${clientPlan.sentChatCount}件`} />
        <DashCard label="スカウトチャット送信数" value={`${scoutCount} / ${scoutChatLimit}件`} />
      </DashCardContainer>

      <SectionTitle>プラン</SectionTitle>

      <Paper className={classes.paper} variant="outlined">
        <form className={classes.form}>
          <Typography variant="body2" color="error">
            プランを変更すると、以下がリセットされます。
            <br />
            また、付与済のオプションは無効になります。
            <br />
            ・プランの有効期限
            <br />
            ・スカウトチャットの残り送信可能数
            <br />
            ・スカウト数などの統計データ
            <br />
          </Typography>

          {Object.keys(planFields).map((key) => (
            <Field key={key} variant="outlined" {...planFields[key]} />
          ))}
        </form>

        <Button
          className={classes.submitButton}
          variant="contained"
          color="primary"
          size="large"
          disableElevation
          onClick={confirmUpdatePlan}
        >
          変更する
        </Button>
      </Paper>

      <SectionTitle>オプション</SectionTitle>

      <Paper className={classes.paper} variant="outlined">
        <form className={classes.form}>
          <Typography variant="body2" color="secondary">
            オプションを付与すると、
            <br />
            追加件数を使い切る、または、使用期限が切れるまで、新規付与はできません。
          </Typography>

          {Object.keys(optionFields).map((key) => (
            <Field key={key} variant="outlined" {...optionFields[key]} />
          ))}
        </form>

        <Button
          className={classes.submitButton}
          variant="contained"
          color="primary"
          size="large"
          disableElevation
          onClick={confirmUpdatePlanOptions}
          disabled={!optionFieldsValid}
        >
          付与する
        </Button>

        {disablePlanOptions && (
          <div className={classes.paperOverlay}>
            <p>
              既にオプションが付与されているため、新規の付与はできません。
              <br />
              オプションの追加件数を使い切るか、使用期限が切れると、再度付与できます。
            </p>
          </div>
        )}
      </Paper>

      <Dialog open={showUpdatePlanConfirmation || showUpdatePlanOptionsConfirmation} onClose={handleCloseDialog}>
        {showUpdatePlanConfirmation && (
          <>
            <DialogTitle>プランの変更</DialogTitle>

            <DialogContent>
              <DialogContentText>
                <p>
                  プランを変更すると、以下がリセットされます。
                  <br />
                  また、付与済のオプションは無効になります。
                  <br />
                  ・プランの有効期限
                  <br />
                  ・スカウトチャットの残り送信可能数
                  <br />
                  ・スカウト数などの統計データ
                  <br />
                </p>
                <p>プランを変更しますか？</p>
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleCloseDialog} color="secondary">
                戻る
              </Button>
              <Button onClick={updatePlan} color="primary">
                変更する
              </Button>
            </DialogActions>
          </>
        )}
        {showUpdatePlanOptionsConfirmation && (
          <>
            <DialogTitle>オプションの付与</DialogTitle>

            <DialogContent>
              <DialogContentText>
                <p>
                  オプションを付与すると、
                  <br />
                  追加件数を使い切る、または、使用期限が切れるまで、
                  <br />
                  新規付与はできません。
                </p>
                <p>オプションを付与しますか？</p>
              </DialogContentText>
            </DialogContent>

            <DialogActions>
              <Button onClick={handleCloseDialog} color="secondary">
                戻る
              </Button>
              <Button onClick={updatePlanOptions} color="primary">
                付与する
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </PageContent>
  )
}
