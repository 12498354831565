import React, { useState } from 'react'
import { DialogTitle, DialogContent, IconButton, DialogContentText, DialogActions, Button } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { BasicTable, Column } from '@/components/organisms/table'
import useSWR from 'swr'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import DeleteIcon from '@material-ui/icons/Delete'
import { Row } from './index'
import { useSession } from '@/hooks/use-session'
import { caxios } from '@/submodules/custom-axios'
import { EmptyPlaceholder } from '@/components/organisms/empty-placeholder'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    templateTitle: {
      textAlign: 'center',
      font: theme.typography.h6.font,
    },
    content: {
      width: 750,
      padding: 0,
    },
    table: {
      width: '100%',
    },
    dialogCloseButton: {
      position: 'absolute',
      top: 0,
      right: 0,
      margin: theme.spacing(1),
    },
    conditionsCell: {
      fontSize: theme.typography.fontSize * 0.86,
      color: 'rgba(0, 0, 0, 0.54)',
    },
    placeholderWrapper: {
      padding: '50px',
    },
    ruler: {
      paddingBottom: '1px',
      boxShadow: '0px 1px 1px #E0E0E0',
    },
  }),
)

type Props = {
  closeDialog?: () => void
  onLoadConditions: (condId: Row) => void
  onSetSnackbarMessage: (message: string) => void
}

export const ConditionList: React.FC<Props> = (props) => {
  const classes = useStyles()
  const { isAdmin } = useSession()
  let condQueryUrlBase = isAdmin ? `/admins/staffs` : `/companies/personnels`
  const { data: conditions, mutate, isValidating } = useSWR(`${condQueryUrlBase}/jobseeker_search_conditions`)
  const [idToDelete, setIdToDelete] = useState<number>(0)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const openDeleteDialog = () => setDeleteDialogOpen(true)
  const closeDeleteDialog = () => {
    setIdToDelete(0)
    setDeleteDialogOpen(false)
  }

  const columns: Column<Row>[] = [
    { id: 'name', label: '' },
    { id: 'condition', label: '', className: classes.conditionsCell, align: 'left' },
  ]

  const deleteCondition = () => {
    caxios.delete(`${condQueryUrlBase}/jobseeker_search_conditions/${idToDelete}`).then(() => {
      mutate()
      closeDeleteDialog()
      props.onSetSnackbarMessage('検索条件を削除しました')
    })
  }

  const handleOpenDeleteDialog = async (id: number) => {
    if (!id) {
      return
    }
    setIdToDelete(id)
    openDeleteDialog()
  }

  const actions = [
    {
      name: 'delete',
      icon: <DeleteIcon />,
      onClick: (row: Row) => () => handleOpenDeleteDialog(row.id),
    },
  ]

  if (!conditions) {
    return null
  }

  if (deleteDialogOpen) {
    return (
      <>
        <DialogTitle>条件の削除</DialogTitle>

        <DialogContent>
          <DialogContentText>この条件を削除します。 よろしいですか？</DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button onClick={closeDeleteDialog} color="secondary">
            キャンセル
          </Button>
          <Button onClick={deleteCondition} color="primary">
            削除する
          </Button>
        </DialogActions>
      </>
    )
  }

  return (
    <>
      <IconButton aria-label="Close" className={classes.dialogCloseButton} onClick={props.closeDialog}>
        <CloseIcon />
      </IconButton>

      <DialogTitle className={classes.templateTitle}>保存した条件の選択</DialogTitle>
      <DialogContent className={classes.content}>
        <BasicTable<Row>
          columns={columns}
          rows={conditions.jobseekerSearchConditions}
          className={classes.table}
          headerDisabled
          onRowClick={props.onLoadConditions}
          square
          isValidating={isValidating}
          rowControls={(row) => (
            <>
              {actions.map((action) => (
                <IconButton key={action.name} onClick={action.onClick(row)}>
                  {action.icon}
                </IconButton>
              ))}
            </>
          )}
        />
      </DialogContent>
    </>
  )
}
