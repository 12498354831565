import React from 'react'

import { Typography } from '@material-ui/core'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'

type Props = {
  className?: string
  title?: string
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: 20,
      userSelect: 'none',
      cursor: 'default',
    },
    controls: {
      display: 'grid',
      gridAutoFlow: 'column',
      gap: theme.spacing(1) + 'px',
    },
  }),
)

export const SectionTitle: React.FC<Props> = (props) => {
  const classes = useStyles()
  return (
    <Typography variant="h6" className={[classes.root, props.className].join(' ')} title={props.title}>
      {props.children}
    </Typography>
  )
}

export const SectionTitleControls: React.FC = (props) => {
  const classes = useStyles()
  return <div className={classes.controls}>{props.children}</div>
}
