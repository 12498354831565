import React, { useState, useEffect, useMemo } from 'react'
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles'

import { Paper, Typography, Button } from '@material-ui/core'
import { Field } from '@/components/molecules/field'
import { PageContent } from '@/components/organisms/page-content'
import { useParams, useHistory } from 'react-router-dom'
import useSWR, { mutate } from 'swr'
import { caxios } from '@/submodules/custom-axios'
import { NotFoundErrorPage } from '@/components/pages/not-found-error'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-around',
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(4),
      padding: theme.spacing(4, 12),
    },
    phaseForm: {
      margin: 'auto',
      width: '100%',
      display: 'grid',
      gap: theme.spacing(2) + 'px',
      flexDirection: 'column',
    },
    row: {
      display: 'flex',
      alignItems: 'center',
      flex: '1 0 auto',
      '& > :only-child': {
        flex: '1 1 auto',
      },
    },
    baseSelectionPhaseId: {
      flexBasis: '60%',
    },
    insertPosition: {
      flexBasis: '40%',
    },
    toLabel: {
      padding: theme.spacing(2),
      fontSize: theme.typography.h6.fontSize,
    },
    createButton: {
      marginTop: theme.spacing(2),
      marginLeft: 'auto',
    },
  }),
)

const Row: React.FC = (props) => {
  const classes = useStyles()
  return <div className={classes.row} {...props} />
}

enum InsertPosition {
  Before = 'previous',
  After = 'next',
}

interface SelectionPhase {
  id: number
  name: string
  isDefault: boolean
  previousSelectionPhaseId: number
}

type SelectionPhaseResponse = SelectionPhase[]

export const SelectionPhaseContainer: React.FC = () => {
  const classes = useStyles()
  const { id } = useParams()
  const [phaseName, setPhaseName] = useState('')
  const [baseSelectionPhaseId, setBaseSelectionPhaseId] = useState<number>()
  const [insertPosition, setInsertPosition] = useState<InsertPosition>(InsertPosition.After)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { data } = useSWR<SelectionPhaseResponse>(`/companies/selection_phases`)
  const history = useHistory()

  useEffect(() => {
    if (data === undefined) {
      return
    }
    setLoading(false)
    const phase = data?.find((e: any) => String(e.id) === String(id))
    if (phase) {
      setPhaseName(phase.name)
      setBaseSelectionPhaseId(phase.previousSelectionPhaseId)
    } else if (id === 'add') {
      if (!data) return setBaseSelectionPhaseId(1)
      setBaseSelectionPhaseId(data[data.length - 1].id)
    } else if (id !== 'add') {
      setError(true)
    }
  }, [data, id])

  const saveAndReturn = async () => {
    const data = {
      name: phaseName,
      baseSelectionPhaseId: Number(baseSelectionPhaseId),
      orderTypeFromBase: insertPosition,
    }

    let res: { data: object }
    if (id === 'add') {
      res = await caxios.post('/companies/selection_phases', data)
    } else {
      res = await caxios.patch(`/companies/selection_phases/${id}`, data)
    }
    mutate('/companies/selection_phases', res.data)
    history.push('/settings/selection-phases')
  }

  const baseSelectionPhases = useMemo(() => {
    return data
      ?.filter((row) => String(row.id) !== String(id))
      .filter((row) => {
        const next = data.find((r) => r.previousSelectionPhaseId === row.id)
        return +(row.isDefault === false) ^ +(row.isDefault && (next ? next.isDefault === false : true))
      })
  }, [data])

  const baseSelectionPhaseIdOptions = useMemo(() => {
    return baseSelectionPhases?.map((row) => ({
      label: row.name,
      value: String(row.id),
    }))
  }, [data])

  const baseSelectionPhaseIsFirst = useMemo(() => {
    if (baseSelectionPhases) {
      const base = baseSelectionPhases.find((row) => String(row.id) === String(baseSelectionPhaseId))
      return base && baseSelectionPhases.indexOf(base) === 0
    }
  }, [baseSelectionPhases, baseSelectionPhaseId])

  const fields = {
    phaseName: {
      name: 'phaseName',
      label: 'フェーズ名',
      value: phaseName,
      onChange: setPhaseName,
    },
    baseSelectionPhaseId: {
      name: 'baseSelectionPhaseId',
      type: 'select',
      label: '挿入場所 (基準)',
      value: String(baseSelectionPhaseId),
      options: baseSelectionPhaseIdOptions,
      onChange: setBaseSelectionPhaseId,
    },
    insertPosition: {
      name: 'insertPosition',
      type: 'select',
      label: '挿入場所 (前後)',
      value: baseSelectionPhaseIsFirst ? InsertPosition.After : (insertPosition as string),
      options: [
        { label: '後ろ', value: InsertPosition.After },
        { label: '前', value: InsertPosition.Before, disabled: baseSelectionPhaseIsFirst },
      ],
      onChange: setInsertPosition,
    },
  }

  if (loading) {
    return null
  }

  if (error) {
    return <NotFoundErrorPage />
  }

  return (
    <PageContent>
      <Paper className={classes.paper} variant="outlined">
        <div className={classes.phaseForm}>
          <Row>
            <Field variant="outlined" {...fields.phaseName} />
          </Row>
          {data && (
            <>
              <Row>
                <Field className={classes.baseSelectionPhaseId} variant="outlined" {...fields.baseSelectionPhaseId} />
                <Typography className={classes.toLabel}>の</Typography>
                <Field className={classes.insertPosition} variant="outlined" {...fields.insertPosition} />
              </Row>
            </>
          )}

          <Button
            variant="contained"
            size="large"
            disableElevation
            color="primary"
            className={classes.createButton}
            onClick={saveAndReturn}
          >
            {id === 'add' ? '追加する' : '変更する'}
          </Button>
        </div>
      </Paper>
    </PageContent>
  )
}
