import React from 'react'

type Props = {
  src: string
  className?: string
  width?: number
  height?: number
  title?: string
  alt?: string
}

export const Image: React.FC<Props> = (props) => {
  return <img {...props} />
}
