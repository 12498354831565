import React from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { Image } from '@/components/atoms/image'
import { Button, Typography } from '@material-ui/core'

type Props = {
  bodyText?: string
  buttonLabel?: string
  onButtonClick?: () => void
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      justifyContent: 'center',
      whiteSpace: 'pre-wrap',
      textAlign: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
    textWrapper: {
      margin: '15px',
    },
  }),
)

export const EmptyPlaceholder: React.FC<Props> = (props: Props) => {
  const { bodyText, buttonLabel, onButtonClick } = props
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Image src="/icon/placeholder-icon.svg" width={64} />
      <Typography variant="body2" color="secondary" className={classes.textWrapper}>
        {bodyText ?? 'データがありません'}
      </Typography>
      {buttonLabel && (
        <Button variant="contained" color="primary" disableElevation size="large" onClick={onButtonClick}>
          {buttonLabel}
        </Button>
      )}
    </div>
  )
}
