import React from 'react'
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles'
import { AppFooter } from '../app-footer'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: 742,
      padding: 0,
      maxWidth: '80vw',
    },
  }),
)

type Props = {
  className?: string
  noFooter?: boolean
}

export const PageContent: React.FC<Props> = (props) => {
  const classes = useStyles()
  return (
    <div className={[classes.root, ...(props.className ? [props.className] : [])].join(' ')}>
      {props.children}
      {!props.noFooter && <AppFooter />}
    </div>
  )
}
