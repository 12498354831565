import React from 'react'
import { List } from '@material-ui/core'
import { NavItem, Props as NavItemProps } from '@/components/molecules/nav-item'

export type Props = {
  items: NavItemProps[]
}

export const NavList: React.FC<Props> = (props) => {
  return (
    <List>
      {props.items.map((item, key) => (
        <NavItem {...item} key={key} />
      ))}
    </List>
  )
}
